import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import Button from "../../components/Button";
import Input from "../../components/Forms/Input";
import Loading from "../../components/Loadingv2";
import {Redirect, withRouter} from "react-router";
import {connect} from "../../state/useGlobal";
import {Helmet} from "react-helmet";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import {Formik} from "formik";
import * as Yup from "yup";
import history from "../../routes/history";
import Select from "../../components/Forms/Select/Select";
import CartSteps from "../../components/CartSteps";
import {STEP_STUDENT_NAME} from "../../constants/cartSteps";

class StudentFormScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      isSubmitLoading: false,
      schoolCode: id,
    };
  }

  componentDidMount() {
    const { globalActions, ga4 } = this.props;
    globalActions.setCheckout({
      currentStep: STEP_STUDENT_NAME,
    });
    this._loadGroupOrder();

    try {
      ga4.pageview('/calcuso-group-order-student-input-information', '/calcuso-group-order-student-input-information', 'Group Order | Student input information');
    } catch (e) {
      console.log(e);
    }
  }

  _loadGroupOrder = async () => {
    const { globalActions } = this.props;
    const { schoolCode } = this.state;
    // TODO
    // Clean Cache here when change to another school code
    await globalActions.loadGroupOrder(schoolCode);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { t, globalState } = this.props;
    const { isLoading, schoolCode, isSubmitLoading } = this.state;
    const { groupOrder, checkout } = globalState;
    const { status } = groupOrder;
    const { student } = checkout;

    return (
      <AppLayout
        contentBackgroundColor="bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6"
        textColor="text-gunmetal-black"
        border={false}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {status === STATUS_NOT_FOUND ? (
              <Redirect to="/" />
            ) : (
              <>
                <Helmet>
                  <title>
                    {schoolCode} | {groupOrder?.school?.name} |{" "}
                    {t("Group Order")}
                  </title>
                </Helmet>
                <Header
                  title={t("Group Order")}
                  subTitle={groupOrder?.school?.name}
                >
                  <CartSteps />
                </Header>
                <div className="max-w-lg px-4 mx-auto lg:px-8">
                  <div className="pt-4 pb-3 md:pt-6 lg:px-8 lg:pt-12">
                    <div className="flex flex-col items-center space-y-2 md:space-y-8 ">
                      <h3 className="text-base font-medium tracking-wide md:text-xl text-gunmetal-black-500">
                        {t(
                          "To get started, we need some information about you so that your teacher can assign the order."
                        )}
                      </h3>
                      <Formik
                        validateOnMount
                        initialValues={{
                          first_name: student.first_name,
                          last_name: student.last_name,
                          class_name: student.class_name,
                        }}
                        validationSchema={Yup.object().shape({
                          first_name: Yup.string().required(t("This is a mandatory field")),
                          last_name: Yup.string().required(t("This is a mandatory field")),
                          class_name: Yup.string().required(t("This is a mandatory field")),
                        })}
                        onSubmit={async (values) => {
                          this.setState({ isSubmitLoading: true });
                          const { globalActions } = this.props;
                          const { schoolCode } = this.state;
                          const {items} = groupOrder;
                          values.first_name = values.first_name.trim();
                          values.last_name = values.last_name.trim();
                          globalActions.initFakeCart();
                          globalActions.setCheckout({
                            student: values,
                          });

                          // Route to Select Calculator or Detail
                          const countCalculator = items.filter(
                            (item) => item.is_calculator
                          ).length;

                          switch (countCalculator) {
                            case 0:
                              history.push(
                                `/group-orders/${schoolCode.trim()}/products`
                              );
                              break;
                            case 1:
                              const calculator = items.find(
                                (item) => item.is_calculator
                              );             
                              globalActions.setGroupOrder({
                                calculator,
                              });
                              globalActions.initFakeCart()
                              history.push(
                                `/group-orders/${schoolCode.trim()}/products`
                              );
                              break;

                            default:
                              history.push(
                                `/group-orders/${schoolCode.trim()}/select-calculator`
                              );
                              break;
                          }

                          this.setState({ isSubmitLoading: false });
                        }}
                      >
                        {({
                          errors,
                          touched,
                          handleSubmit,
                          values,
                          handleChange,
                          handleBlur,
                          isValid,
                        }) => (
                          <form
                            className="flex flex-col items-start w-full space-y-4"
                            onSubmit={handleSubmit}
                          >
                            <Input
                              name="first_name"
                              label={t("First name student")}
                              placeholder={t("First name")}
                              size="lg"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.first_name}
                              required
                              error={touched.first_name && errors.first_name}
                              errorMessage={errors.first_name}
                            />
                            <Input
                              name="last_name"
                              label={t("Last name student")}
                              placeholder={t("Last name")}
                              size="lg"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.last_name}
                              required
                              error={touched.last_name && errors.last_name}
                              errorMessage={errors.last_name}
                            />
                            <Select
                              label={t("Class")}
                              name="class_name"
                              options={groupOrder.classes.map((className) => {
                                return { key: className, value: className };
                              })}
                              placeholder={t("Class")}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.class_name}
                              required
                              error={touched.class_name && errors.class_name}
                              errorMessage={errors.class_name}
                            />
                            <p className="text-sm italic md:py-4 ">
                              {t(
                                "If you would like to order for another child, please place a separate order for each child."
                              )}
                            </p>
                            <Button
                              id="student-form-submit-button"
                              type="submit"
                              color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                              size="xl"
                              full={true}
                              disabled={!isValid}
                              loading={isSubmitLoading}
                            >
                              {t("next")}
                            </Button>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </AppLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(StudentFormScreen)));
