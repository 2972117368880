import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useGlobal from "../../../../state/useGlobal";
import api from "../../../../services/api";
import { Helmet } from "react-helmet";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loading";
import {HandleAPICartError, HandleError} from "../../../../helpers";

export default function PaypalPlusPayment() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder, checkout } = globalState;
  const { school_code } = groupOrder;
  const { quote_id, email, billing } = checkout;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [enable, setEnable] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    async function loadPPP() {
      setLoading(true);
      try {
        // Load async data.
        const pppData = await api.post(
          `/guest-carts/${quote_id}/set-ppp-payment-information-v2`,
          {},
          {
            cartId: quote_id,
            email: email,
            paymentMethod: {
              method: "iways_paypalplus_payment",
              po_number: null,
              additional_data: null,
            },
            billingAddress: {
              ...billing,
              street: [billing.street],
            },
          }
        );
        const { data } = pppData;
        const { payload } = data;
        setLoading(false);

        window.CALCUSO_PPP = window.PAYPAL.apps.PPP({
          approvalUrl: payload,
          placeholder: "ppplus",
          mode: "live",
          country: billing.country_id,
          buttonLocation: "outside",
          enableContinue: function () {
            setEnable(true);
          },
          disableContinue: function () {
            setEnable(false);
          },
        });
      } catch (error) {
        HandleError(error);
      }
    }

    loadPPP();
  }, [billing, email, globalState, quote_id]);

  const _placeOrder = async () => {
    setLoadingSubmit(true);
    try {
      window.CALCUSO_PPP.doCheckout();
    } catch (error) {
      HandleAPICartError(error, globalState);
      setLoadingSubmit(false);
    }
  };

  return (
    <>
      <Helmet>
        <script
          src="https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js"
          type="text/javascript"
        ></script>
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div id="ppplus"></div>
          <div className="flex items-center justify-end pt-8 space-x-8">
            <Link
              to={`/group-orders/${school_code.trim()}/checkout/payment`}
              className="font-medium cursor-pointer text-gunmetal-black-500"
            >
              {t("back")}
            </Link>
            <Button
              type="button"
              onClick={_placeOrder}
              color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
              size="xl"
              disabled={!enable}
              loading={loadingSubmit}
            >
              <span>{t("Place your order")}</span>
            </Button>
          </div>
        </>
      )}
    </>
  );
}
