import React from "react";
import {
  ATTRIBUTE_CODE_COLOR,
  ATTRIBUTE_CODE_IS_GRAVIUR,
  ATTRIBUTE_CODE_SIZE,
  ATTRIBUTE_SECONDHAND,
  ATTRIBUTE_WARRANTY_DURATION
} from "../../../constants/common";
import SelectOption from "./Options/SelectOption";
import PackageSelectStyleTag from "./Options/PackageSelectStyleTag";
import PackageColor from "./Options/PackageColor";

export default function PackageRecursiveOptionsContainer({ options, formik, item }) {
  return (
    <>
      {options.map((optionConfig) => {
        switch (optionConfig.attribute_input) {
          case "select":
            switch (optionConfig.attribute_code) {
              case ATTRIBUTE_CODE_IS_GRAVIUR:
                  return "";
              case ATTRIBUTE_CODE_SIZE:
              case ATTRIBUTE_WARRANTY_DURATION:
              case ATTRIBUTE_SECONDHAND:
                return (
                  <PackageSelectStyleTag
                    key={optionConfig.id}
                    option={optionConfig}
                    formik={formik}
                    item={item}
                  />
                );
              case ATTRIBUTE_CODE_COLOR:
                return (
                  <PackageColor
                    key={optionConfig.id}
                    option={optionConfig}
                    formik={formik}
                    item={item}
                  />
                );
              default:
                return (
                  <SelectOption
                    key={optionConfig.id}
                    option={optionConfig}
                    formik={formik}
                    item={item}
                  />
                );
            }
          default:
            return <div key={optionConfig.id}>Unsupported</div>;
        }
      })}
    </>
  );
}
