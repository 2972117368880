export const initialState = {
  open: false,
  cart: {},
  schoolCode: '',
};

export const setFakeCartModal = (store, fakeCartModal) => {
  store.setState({ fakeCartModal });
};

export const setFakeCartDataModal = (store, cart) => {
  store.setState({
    fakeCartModal: {
      ...store.state.fakeCartModal,
      cart
    },
  });
};

export const openFakeCartModal = (store) => {
  store.setState({
    fakeCartModal: {
      open: true,
    },
  });
};

export const closeFakeCartModal = (store) => {
  store.setState({
    fakeCartModal: {
      ...store.state.fakeCartModal,
      open: false,
    },
  });
};
