import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../state/useGlobal";
import api from "../../../../services/api";
import history from "../../../../routes/history";
import {HandleAPICartError} from "../../../../helpers";
import AdyenCheckout from "@adyen/adyen-web";
import GlobalLockLoading from "../../../../components/GlobalLockLoading";
import {checkPaymentStatusAdyen, getDefaultConfigAdyen} from "../adyenHelper";

function getOS() {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (/Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

export default function GoogleApplePayment() {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {groupOrder, checkout} = globalState;


    const {school_code} = groupOrder;
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const _placeOrder = async (result) => {

        setLoading(true);

        const {checkout} = globalState;
        const {quote_id, email} = checkout;

        try {
            const payload = {
                cartId: quote_id,
                email: email,
                paymentMethod: {
                    method: "adyen_hpp",
                    additional_data: {
                        'stateData': JSON.stringify(result.data),
                        'guestEmail': email,
                        'isGooglePayAndApplePay': true
                    }
                },
            };

            let orderId = await api.post(
                `/guest-carts/${quote_id}/payment-information`,
                {},
                payload
            );

            let response = await checkPaymentStatusAdyen(orderId);

            if (response.isFinal) {
                globalActions.initCheckout();
                globalActions.initGroupOrder();
                globalActions.initFakeCart();
                history.push(`/group-orders/${school_code.trim()}/checkout/done`);
            } else {
                //Can be 3ds here
                throw Error('Payment is not final from Adyen side');
            }
        } catch (error) {
            HandleAPICartError(error, globalState);
        }
        setLoading(false);
    };


    useEffect(() => {
        const createCheckout = async () => {
            let adyenCheckout = await (new AdyenCheckout(getDefaultConfigAdyen(globalState, {
                onSubmit: _placeOrder
            })));

            try {
                if (getOS() === 'iOS' || getOS() === 'Mac OS') {
                    const applyPayComponent = adyenCheckout.create('applepay', {
                        amount: {
                            currency: checkout.payment_methods_adyen.paymentMethodsExtraDetails['applepay'].configuration.amount.currency, value: checkout.payment_methods_adyen.paymentMethodsExtraDetails['applepay'].configuration.amount.value,
                        }
                    });
                    applyPayComponent
                        .isAvailable()
                        .then(() => {
                            applyPayComponent.mount("#applepay-container");
                        })
                        .catch(e => {
                            HandleAPICartError(e, globalState);
                        });
                } else {
                    const googlePayComponent = adyenCheckout.create('googlepay', {
                        buttonSizeMode: "fill", buttonType: "checkout", amount: {
                            currency: checkout.payment_methods_adyen.paymentMethodsExtraDetails['googlepay'].configuration.amount.currency, value: checkout.payment_methods_adyen.paymentMethodsExtraDetails['googlepay'].configuration.amount.value,
                        }
                    });
                    googlePayComponent
                        .isAvailable()
                        .then(() => {
                            googlePayComponent.mount("#googlepay-container");
                        })
                        .catch(e => {
                            HandleAPICartError(e, globalState);
                        });
                }
            } catch (e) {
                HandleAPICartError(e, globalState);
            }
        }

        createCheckout();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    return (
        loading ? (<GlobalLockLoading/>) : (<>
            <div>
                <div id="googlepay-container"></div>
                <div id="applepay-container"></div>
            </div>
        </>)
    );
}
