import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

import "../../../tailwind.output.css";
import { useTranslation } from "react-i18next";

const Select = ({
  name,
  label,
  placeholder,
  options,
  error,
  errorMessage,
  required,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-full ">
      <div className="flex items-center justify-between">
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gunmetal-black"
        >
          {label}
        </label>
        {required ? <span className="text-xl text-red-500 ">*</span> : ""}
      </div>
      <div
        className={classNames({
          "mt-1": true,
          "relative rounded-md shadow-sm": error,
        })}
      >
        <select
          disabled={props.disabled}
          name={name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          className={classNames({
            "focus:ring-celadon-green-500 focus:border-celadon-green-500 border-gray-300": !error,
            "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": error,
            "shadow-sm block w-full sm:text-base border-2 rounded-md": true,
          })}
        >
          {placeholder ? <option value="">{placeholder}</option> : ""}
          {options &&
            options.map((option) => (
              <option key={option.key} value={option.key}>
                {t(option.value)}
              </option>
            ))}
        </select>
        {error ? (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon
              className="w-5 h-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {error ? (
        <p className="mt-2 text-sm text-fiery-rose-500 ">{errorMessage}</p>
      ) : (
        ""
      )}
    </div>
  );
};

Select.protoTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};
export default Select;
