import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import useGlobal from "../state/useGlobal";
import {ExclamationIcon, XIcon} from "@heroicons/react/outline";
import {Trans, useTranslation} from "react-i18next";

export default function ConfirmModalRemoveItemIfItemInPackage() {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {confirmRemoveItemModal} = globalState;
    const {open, action} = confirmRemoveItemModal;
    const {fakeCart} = globalState;
    const {relatedPackageSelected} = fakeCart;
    const {t} = useTranslation();
    const percentageDiscount = relatedPackageSelected?.percentage_discount;
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 z-10 overflow-y-auto"
                open={open}
                onClose={globalActions.closeConfirmModal}
            >
                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"/>
                    </Transition.Child>

                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                <button
                                    type="button"
                                    className="text-gray-400 bg-white rounded-md"
                                    onClick={globalActions.closeConfirmModalRemoveItemCart}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="w-6 h-6" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon
                                        className="w-6 h-6 text-red-600"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        {t("Do you really want to remove the item?")}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            <Trans i18nKey="You will lose your <1>{{percentageDiscount}}%</1> discount." values={{percentageDiscount}}>
                                                You will lose your <b>{{percentageDiscount}}%</b> discount.
                                            </Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white transition duration-500 ease-in-out transform bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm hover:translate-2 hover:scale-110"
                                    onClick={action ? action : undefined}
                                >
                                    {t("Remove product")}
                                </button>

                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 transition duration-500 ease-in-out transform bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3 sm:mt-0 sm:w-auto sm:text-sm hover:translate-2 hover:scale-110"
                                    onClick={globalActions.closeConfirmModalRemoveItemCart}
                                >
                                    {t("Keep selection")}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
