import useGlobal from "../../../../state/useGlobal";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import api from "../../../../services/api";
import {checkPaymentStatusAdyen, getDefaultConfigAdyen} from "../adyenHelper";
import history from "../../../../routes/history";
import {HandleAPICartError} from "../../../../helpers";
import AdyenCheckout from "@adyen/adyen-web";
import Button from "../../../../components/Button";
import PaymentFeeMessage from "../../PaymentFeeMessage";

export default function KlarnaOvertimePayment() {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {groupOrder, checkout} = globalState;

    const {school_code} = groupOrder;
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [adyenKlarna, setAdyenKlarna] = useState(null);

    const _placeOrder = async () => {

        setLoading(true);

        const {checkout} = globalState;
        const {quote_id, email} = checkout;

        try {
            const payload = {
                email: email,
                paymentMethod: {
                    method: "adyen_hpp",
                    additional_data: {
                        stateData: JSON.stringify(adyenKlarna.data),
                        brand_code: "klarna_account",
                    }
                },
            };

            let response = await api.post(
                `/guest-carts/${quote_id}/payment-information`,
                {},
                payload
            );

            let responseStatus = await checkPaymentStatusAdyen(response.data);

            if (responseStatus.isFinal) {

                globalActions.initCheckout();
                globalActions.initGroupOrder();
                globalActions.initFakeCart();
                history.push(`/group-orders/${school_code.trim()}/checkout/done`);
            } else {
                window.location.assign(responseStatus.action.url);
            }

        } catch (error) {
            HandleAPICartError(error, globalState);
        }
    };


    useEffect(() => {
        const createCheckout = async () => {
            let adyenCheckout = await (new AdyenCheckout(getDefaultConfigAdyen(globalState, {
                showPayButton: false,
            })));

            const klarnaComponent = adyenCheckout.create('klarna_account').mount('#klarna-container');
            setAdyenKlarna(klarnaComponent);
        }
        createCheckout();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="pt-4 space-x-8">
            <div id="klarna-container"></div>
            <div className="flex items-center justify-between space-x-8">
                <div>
                    <PaymentFeeMessage name={"Klarna"}/>
                </div>
                <Button
                    type= "button"
                    onClick={_placeOrder}
                    color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                    size="xl"
                    loading={loading}
                >
                    <span>{t("Place your order")}</span>
                </Button>
            </div>
        </div>
    );
}
