import * as React from "react";

const ReuseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={24}
    viewBox="0 0 26 24"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      style={{
        fill: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      d="M421.692 1989.224h-19.999l14.213-22.234M447.511 1973.22l10.178 16.004H429.69M420.785 1959.22l8.906-13.998 12.992 20.44"
      transform="matrix(.44828 0 0 .46154 -179.62 -897.334)"
    />
    <path
      style={{
        fill: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      d="m435.695 1995.224-6.004-6 6.004-6.001M407.531 1968.327l8.375-1.337 1.341 8.379M444.487 1957.367l-1.804 8.294-8.295-1.811"
      transform="matrix(.44828 0 0 .46154 -179.62 -897.334)"
    />
  </svg>
);

export default ReuseIcon;
