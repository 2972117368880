import mixpanel from 'mixpanel-browser';
export function mixpanelEventProductAddToCart(globalState, item) {
    try {
        let url = window.location.href;
        let screen = url.split("/").pop();
        const {groupOrder} = globalState;
        const {calculator} = groupOrder;

        mixpanel.track('Add to Cart', {
            screen: screen,
            product_id: item.product_id,
            product_name: item.name,
            product_sku: item.product_sku,
            type : calculator.product_id === item.product_id ? "main" : "related",
            groupOrder: groupOrder.code,
            isSchoolPackage : calculator.related_package.length > 1,
        });
    } catch (e) {
        console.log(e);
    }
}
