import React from "react";
import Product from "./Product";

export default function ListProductSelection({ items }) {

  return (
    <ul className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {items
        .filter((product) => product.is_calculator)
        .map((product) => (
          <Product
            key={product.product_id}
            product={product}
          />
        ))}
    </ul>
  );
}
