import * as React from "react";

const SustainableIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" stroke="currentColor" {...props}>
    <path
      style={{
        fill: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      d="M831.901 1389.051s-9.414-7.078-9.414-15.804c0-8.727 9.414-15.797 9.414-15.797s9.414 7.07 9.414 15.797c0 8.726-9.414 15.804-9.414 15.804Zm0 0"
      transform="matrix(.5 0 0 .5 -403.743 -676.322)"
    />
    <path
      style={{
        fill: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      d="M.003 0v20.976"
      transform="matrix(.5 0 0 .5 12.206 11.66)"
    />
  </svg>
);

export default SustainableIcon;
