export const initialState = {
  open: false,
  product: false,
  gravurText: "",
  actionFrom : null,
  showRelatedProducts: true,
};

export const setGravurModal = (store, gravurModal) => {
  store.setState({
    gravurModal: {
      ...gravurModal,
    },
  });
};

export const openGravurModal = (store, product, gravurText = '', actionFrom = 'auto', showRelatedProducts = true) => {
  store.setState({
    gravurModal: {
      ...store.state.gravurModal,
      open: true,
      product,
      gravurText,
      actionFrom,
      showRelatedProducts
    },
  });
};

export const closeGravurModal = (store) => {
  return new Promise((resolve) => {
    store.setState({
      gravurModal: {
        ...store.state.gravurModal,
        open: false,
      },
    }, () => {
      resolve();
    });
  });
};

export const setGravurTextGravurModal = (store, gravurText) => {
  store.setState({
    gravurModal: {
      ...store.state.gravurModal,
      gravurText,
    },
  });
};
