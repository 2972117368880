import React, { Component } from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import { withTranslation } from "react-i18next";
import CheckoutSteps from "../../components/CheckoutSteps";
import { withRouter, Redirect } from "react-router";
import { connect } from "../../state/useGlobal";
import Loading from "../../components/Loadingv2";
import { STATUS_NOT_FOUND } from "../../state/groupOrder";
import { Helmet } from "react-helmet";
import Input from "../../components/Forms/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import history from "../../routes/history";
import { STEP_CHECKOUT_LOGIN } from "../../constants/checkoutSteps";
import Button from "../../components/Button";
import CartModal from "../../components/CartModal";
import Footer from "../../components/Footer";

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      schoolCode: id,
    };
  }

  componentDidMount() {
    const { globalActions } = this.props;
    globalActions.setCheckout({
      currentCheckoutStep: STEP_CHECKOUT_LOGIN,
    });
    this._loadGroupOrder();
  }

  _loadGroupOrder = async () => {
    const { globalActions, globalState } = this.props;
    const { schoolCode } = this.state;

    // Verify School Code
    const { groupOrder } = globalState;
    const { school_code } = groupOrder;
    if (school_code && school_code !== schoolCode) {
      history.push(`/group-orders/${schoolCode.trim()}`);
    }

    await globalActions.loadGroupOrder(schoolCode);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { t, globalState } = this.props;
    const { isLoading, schoolCode } = this.state;
    const { groupOrder } = globalState;
    const { status } = groupOrder;

    return (
      <AppLayout
        contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
        textColor="text-gunmetal-black"
        border={false}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {status === STATUS_NOT_FOUND ? (
              <Redirect to="/" />
            ) : (
              <>
                <Helmet>
                  <title>
                    {t("Login")} | {schoolCode} | {groupOrder?.school?.name} |{" "}
                    {t("Group Order")}
                  </title>
                </Helmet>
                <div className="flex flex-col h-full min-h-screen">
                  <Header title={t("Checkout")} withCart>
                    <CheckoutSteps />
                  </Header>
                  <div className="flex flex-col justify-between flex-1 h-full">
                    <div className="flex items-center justify-center flex-1">
                      <Formik
                        validateOnMount
                        initialValues={{
                          firstname: "",
                          lastname: "",
                          email: "",
                          password: "",
                        }}
                        validationSchema={Yup.object().shape({
                          firstname: Yup.string().required(
                            t("This is a mandatory field")
                          ),
                          lastname: Yup.string().required(
                            t("This is a mandatory field")
                          ),
                          email: Yup.string()
                            .email("The email must be a valid email.")
                            .required(t("This is a mandatory field")),
                          password: Yup.string().required(
                            t("This is a mandatory field")
                          ),
                        })}
                        onSubmit={(values) => {
                          console.log(values);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          handleSubmit,
                          values,
                          handleChange,
                          handleBlur,
                          isValid,
                          setValues,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                              <div className="px-4 pt-6 lg:px-8 lg:pt-12">
                                <div className="flex flex-col space-y-6 ">
                                  <div className="pb-4 space-y-2 ">
                                    <div className="grid grid-cols-2 space-y-8 md:space-y-0 md:divide-x-2 md:divide-cal-primary-celadon-green-80DAD8">
                                      <div className="col-span-2 space-y-8 md:pr-12 sm:col-span-1">
                                        <h3 className="text-xl font-medium tracking-wide text-center text-gunmetal-black-500">
                                          {t("Welcome back.")}
                                        </h3>
                                        <Button
                                          type="button"
                                          color="bg-celadon-green-600 hover:bg-celadon-green-700 focus:ring-celadon-green-500"
                                          size="xl"
                                          full={true}
                                        >
                                          {t("Login")}
                                        </Button>
                                      </div>

                                      <div className="col-span-2 space-y-8 md:pl-12 sm:col-span-1">
                                        <div>
                                          <h3 className="text-xl font-medium tracking-wide text-center text-gunmetal-black-500">
                                            {t("You are new here?")}
                                          </h3>
                                          <h3 className="text-xl font-medium tracking-wide text-center text-gunmetal-black-500">
                                            {t("Please register.")}
                                          </h3>
                                        </div>
                                        <div className="flex flex-col items-start w-full space-y-4">
                                          <Input
                                            name="firstname"
                                            label={t("First Name")}
                                            placeholder={t("First Name")}
                                            size="lg"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstname}
                                            required
                                            error={
                                              touched?.firstname &&
                                              errors?.firstname
                                            }
                                            errorMessage={errors?.firstname}
                                          />
                                          <Input
                                            name="lastname"
                                            label={t("Last Name")}
                                            placeholder={t("Last Name")}
                                            size="lg"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastname}
                                            required
                                            error={
                                              touched?.lastname &&
                                              errors?.lastname
                                            }
                                            errorMessage={errors?.lastname}
                                          />
                                          <Input
                                            name="email"
                                            label={t("Email address")}
                                            placeholder={t("Email address")}
                                            size="lg"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            required
                                            error={
                                              touched?.email && errors?.email
                                            }
                                            errorMessage={errors?.email}
                                          />
                                          <Input
                                            name="password"
                                            label={t("Password")}
                                            placeholder={t("Password")}
                                            size="lg"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            required
                                            error={
                                              touched?.password &&
                                              errors?.password
                                            }
                                            errorMessage={errors?.password}
                                          />
                                          <div className="relative flex items-start">
                                            <div className="flex items-center h-5">
                                              <input
                                                id="comments"
                                                name="comments"
                                                type="checkbox"
                                                className="w-4 h-4 border-gray-300 rounded text-celadon-green-600 focus:ring-celadon-green-500"
                                              />
                                            </div>
                                            <div className="ml-3 ">
                                              <label>
                                                {t(
                                                  "Yes, I wish to receive occasional emails about special offers, new products and exclusive promotions. I can cancel my subscription at any time. (Optional)"
                                                )}
                                              </label>
                                            </div>
                                          </div>
                                          <Button
                                            type="button"
                                            color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                            size="xl"
                                            full={true}
                                          >
                                            {t("Register")}
                                          </Button>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: t(
                                                "By registering for an account, you agree to our <span class='underline'>Terms of Use</span>. Please read our <span class='underline'>Privacy Notice</span>."
                                              ),
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <Footer />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <CartModal />
      </AppLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(LoginScreen)));
