import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../state/useGlobal";
import * as Yup from "yup";
import {IS_GRAVIUR, REGEX_GRAVIUR,} from "../../../../constants/common";
import BigNumber from "bignumber.js";
import * as Sentry from "@sentry/react";
import classNames from "classnames";

export default function PackageGravur({option, formik, item}) {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {fakeCart} = globalState;
    // get From Cart
    const {items} = fakeCart;
    const selectedItem = items[item.product_id];
    const {product_simples} = item;
    const {price} = option;
    const {t} = useTranslation();
    const {
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
    } = formik;
    const getFinalPrice = () => {
        const product = product_simples.find((product_simple) => {
            let find = true;
            for (
                let index = 0;
                index < product_simple.product_custom_attributes.length;
                index++
            ) {
                const element = product_simple.product_custom_attributes[index];
                if (element.value !== values[element.attribute_code]) {
                    return false;
                }
            }

            return find;
        });

        if (!product) {
            Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
            Sentry.setUser({item, globalState});
            Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
            return false;
        }

        if (values.is_graviur === IS_GRAVIUR) {
            // Gravur
            const gravurOption = item.product_custom_options.find(
                (custom_option) => custom_option.type === "field"
            );
            const price = new BigNumber(product.product_price).plus(
                new BigNumber(gravurOption.price)
            );
            return price.toFormat(2).toString();
        }

        const price = new BigNumber(product.product_price);
        return price.toFormat(2).toString();
    };

    useEffect(() => {
        async function processingData() {
            // Set To Cart If Exists
            if (selectedItem) {
                const price = getFinalPrice();
                const isValid = await Yup.string()
                    .max(22)
                    .matches(REGEX_GRAVIUR)
                    .isValid(values.gravur);
                if (isValid) {
                    globalActions.addOptionsFromCart(selectedItem?.id, price, {
                        gravur: values.gravur,
                        is_graviur: values.is_graviur,
                    });
                } else {
                    if (!values.gravur) {
                        globalActions.addOptionsFromCart(selectedItem?.id, price, {
                            gravur: values.gravur,
                            is_graviur: values.is_graviur,
                        });
                    }
                }
            }
        }

        processingData();
    }, [values.gravur, values.is_graviur]); // eslint-disable-line react-hooks/exhaustive-deps

    const gravurValue = values["gravur"] ?? '';

    const handleNoEngraving = () => {
        setFieldValue('gravur', '');
        globalActions.addOptionsFromCart(selectedItem?.id, price, {
            gravur: '',
            is_graviur: values.is_graviur,
        });

        globalActions.setGravurTextGravurModal('');
        setTimeout(() => {
            globalActions.goToNextStepConfigurable(globalActions.getSortCartItemsInPackageOnly(), item);
        }, 200);
    }

    const handleClickForEngraving = () => {
        if (gravurValue === '') {
            globalActions.openGravurModal(item, gravurValue, 'configurable', false);
        } else {
            //globalActions.goToNextStepConfigurable(globalActions.getSortCartItemsInPackageOnly(), item);
        }
    }

    return (
        <div className="flex flex-col justify-center pt-4 space-y-2 text-center lg:text-left lg:pt-0">
            <div onClick={() => handleClickForEngraving() } className={classNames({
                "border-gray-400 w-full items-center justify-center py-4 px-5 text-sm font-semibold sm:flex-1 cursor-pointer border rounded-2xl": true,
                "border-cal-primary-celadon-green-00B5B1": gravurValue
            })}>
                <div className="flex justify-between text-left items-center">
                    <div>{t("⚡ Laser engraving")} <br/>
                        <p className="text-xs font-normal text-gunmetal-black-500">
                            {t("Personalize your product")}
                        </p>
                    </div>
                    <div> +{new BigNumber(price).toFormat(2).toString()} {globalState.groupOrder.currency_symbol}</div>
                </div>
            </div>
            {values["gravur"] ? (
                <div className="text-left bg-gray-100 w-full items-center justify-center py-4 px-5 text-sm font-semibold sm:flex-1 cursor-pointer  rounded-2xl ">
                    <div className="flex justify-between items-center">
                        <div>
                            {t('Your engraving')}: <br/>
                            <span className="font-normal">{gravurValue}</span>
                        </div>
                        <div onClick={() => globalActions.openGravurModal(item, gravurValue, 'configurable', false)}>
                            {t('Change')}
                        </div>
                    </div>
                </div>
            ) : ''}
            <div
                onClick={() => handleNoEngraving()} className={classNames({
                "border-gray-400 w-full items-center justify-center py-4 px-5 text-sm font-semibold sm:flex-1 cursor-pointer border rounded-2xl ": true,
                "border-cal-primary-celadon-green-00B5B1": values["gravur"] === ''
            })}>
                {t('I don\'t want an engraving')}</div>
        </div>
    );
}
