import React, { Component } from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import { withTranslation } from "react-i18next";
import { connect } from "../../state/useGlobal";
import { withRouter, Redirect } from "react-router";
import { Helmet } from "react-helmet";
import { STATUS_NOT_FOUND } from "../../state/groupOrder";
import Loading from "../../components/Loading";
import ProductModal from "../../components/ProductModal";
import FakeCartModal from "../../components/FakeCartModal";
import CartSteps from "../../components/CartSteps";
import ProductTabs from "./ProductTabs";
import { STEP_UP_SELL_PRODUCT } from "../../constants/cartSteps";

class DetailProductionScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    const productId = this.props.match.params.productId;

    this.state = {
      isLoading: true,
      schoolCode: id,
      productId: productId,
    };
  }

  componentDidMount() {
    const { globalActions } = this.props;
    globalActions.setCheckout({
      currentStep: STEP_UP_SELL_PRODUCT,
    });
    this._loadGroupOrder();
  }

  _loadGroupOrder = async () => {
    const { globalActions } = this.props;
    const { schoolCode } = this.state;
    await globalActions.loadGroupOrder(schoolCode);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { t, globalState } = this.props;
    const { schoolCode, isLoading, productId } = this.state;
    const { groupOrder } = globalState;
    const { status, items } = groupOrder;

    const item = items.find((i) => i.product_id === productId);

    return (
      <AppLayout
        contentBackgroundColor="bg-floral-white-500"
        textColor="text-gunmetal-black"
        border={false}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {status === STATUS_NOT_FOUND ? (
              <Redirect to="/" />
            ) : (
              <>
                <Helmet>
                  <title>
                    {schoolCode} | {groupOrder?.school?.name} |{" "}
                    {t("Group Order")}
                  </title>
                </Helmet>
                <Header
                  title={t("Group Order")}
                  subTitle={groupOrder?.school?.name}
                  withFakeCart
                >
                  <CartSteps />
                </Header>

                <ProductTabs item={item} />
              </>
            )}
          </>
        )}
        <ProductModal />
        <FakeCartModal />
      </AppLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(DetailProductionScreen)));
