import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import useGlobal from "../../../../state/useGlobal";
import toast from "react-hot-toast";
import api from "../../../../services/api";
import {HandleAPICartError} from "../../../../helpers";
import PaymentFeeMessage from "../../PaymentFeeMessage";

export default function PaypalExpressPayment() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder } = globalState;
  const { school_code } = groupOrder;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const _placeOrder = async () => {
    setLoading(true);
    const { checkout } = globalState;
    const { quote_id, email } = checkout;
    try {
      const payload = {
        cartId: quote_id,
        email: email,
        paymentMethod: {
          method: "paypal_express",
        },
      };
      // Load async data.
      const responsePaypalExpress = await api.post(
        `/guest-carts/${quote_id}/set-payment-information`,
        {},
        payload
      );
      const { data } = responsePaypalExpress;
      if (data) {
        const responseRedirectTransaction = await api.post(
          `/guest-carts/${quote_id}/paypal-express`,
          {},
          {}
        );

        const { payload } = responseRedirectTransaction.data;

        window.location = payload;
      } else {
        toast.error(
          "We can not create payment information for the paypal express."
        );
      }
    } catch (error) {
      HandleAPICartError(error, globalState);
    }
    setLoading(false);
  };

  return (
    <div className="flex items-center justify-between pt-8 space-x-8">
      <div>
        <PaymentFeeMessage name={"PayPal"} />
      </div>
      {/* <Link
        to={`/group-orders/${school_code.trim()}/checkout/address`}
        className="font-medium cursor-pointer text-gunmetal-black-500"
      >
        {t("back")}
      </Link> */}
      <Button
        type="button"
        onClick={_placeOrder}
        color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
        size="xl"
        loading={loading}
      >
        <span>{t("Place your order")}</span>
      </Button>
    </div>
  );
}
