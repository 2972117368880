import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import useGlobal from "../../../../state/useGlobal";
import api from "../../../../services/api";
import history from "../../../../routes/history";
import {HandleAPICartError} from "../../../../helpers";

export default function CashPayment() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder } = globalState;
  const { school_code } = groupOrder;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const _placeOrder = async () => {
    setLoading(true);
    const { checkout } = globalState;
    const { quote_id, email } = checkout;
    try {
      const payload = {
        cartId: quote_id,
        email: email,
        paymentMethod: {
          method: "calcuso_cash",
        },
      };
      // Load async data.
      await api.post(
        `/guest-carts/${quote_id}/payment-information`,
        {},
        payload
      );

      globalActions.initCheckout();
      globalActions.initGroupOrder();
      globalActions.initFakeCart();
      history.push(`/group-orders/${school_code.trim()}/checkout/done`);
    } catch (error) {
      HandleAPICartError(error, globalState);
    }
    setLoading(false);
  };

  return (
    <div className="pt-6 space-y-4" id="calcuso-cash-payment">
      <h2>
        {t(
          "Überweisen Sie den Rechnungsbetrag. Ihre Ware wird nach Zahlungseingang verschickt."
        )}
      </h2>
      <div className="flex items-center justify-end space-x-8">
        {/* <Link
        to={`/group-orders/${school_code.trim()}/checkout/address`}
        className="font-medium cursor-pointer text-gunmetal-black-500"
      >
        {t("back")}
      </Link> */}
        <Button
          id="calcuso-cash-place-order-button"
          type="button"
          onClick={_placeOrder}
          color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
          size="xl"
          loading={loading}
        >
          <span>{t("Place your order")}</span>
        </Button>
      </div>
    </div>
  );
}
