import React, {useEffect, useState} from "react";
import useGlobal from "../state/useGlobal";
import {MinusIcon, PlusIcon} from "@heroicons/react/outline";
import classNames from "classnames";

export default function CartActionPlusMinus({item}) {
    const [globalState, globalActions] = useGlobal();

    const [handleOnAdd, setHandleOnAdd] = useState(null);
    const [handleOnRemove, setHandleOnRemove] = useState(null);
    const [qty, setQty] = useState(null);
    const [disable, setDisable] = useState(false);
    useEffect(() => {
        const firstItem = globalActions.getSortCartItemsInPackageOnly()[0];
        if (item?.package_type && firstItem?.qty) {
            setQty(firstItem.qty);
            // add all items in package to cart
            const handleOnAdd = () => {
                globalActions.getSortCartItemsInPackageOnly().forEach((item) => {
                    globalActions.addToCart(item);
                    setQty(firstItem.qty + 1);
                });
            };

            setHandleOnAdd(() => handleOnAdd);

            const handleOnRemove = () => {
                if (qty === 1) {
                    globalActions.openConfirmModalRemoveItemCart(() => {
                        globalActions.getSortCartItemsInPackageOnly().forEach((item) => {
                            globalActions.removeFromCart(item);
                            globalActions.closeConfirmModalRemoveItemCart();
                            globalActions.removeRelatedPackageSelected();
                            globalActions.calculateTotal();
                            setQty(0);
                        });
                    });
                } else {
                    globalActions.getSortCartItemsInPackageOnly().forEach((item) => {
                        setTimeout(() => {
                            setDisable(false);
                        }, 500);
                        globalActions.minusFromCart(item);
                        setQty(firstItem.qty - 1);
                    })
                }
            }
            setHandleOnRemove(() => handleOnRemove);
        }
    }, [globalState.fakeCart.items, qty]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="relative z-0 inline-flex rounded-lg shadow-sm">
            <button
                type="button"
                disabled={disable}
                onClick={handleOnRemove}
                className={classNames({
                    "relative disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center px-3 py-2 text-sm font-medium border-2 rounded-l-lg border-celadon-green-500 text-celadon-green-500 hover:text-celadon-green-dark-500 focus:z-10 focus:outline-none ": true,
                })}
            >
                <MinusIcon className="w-3 h-3"/>
            </button>
            <button
                type="button"
                className="relative inline-flex items-center px-3 py-2 -ml-px text-xs font-medium border-t-2 border-b-2 border-1 border-celadon-green-500 text-gunmetal-black-500 hover:text-celadon-green-dark-500 focus:z-10 focus:outline-none "
            >
                {qty}
            </button>
            <button
                type="button"
                disabled={disable}
                onClick={handleOnAdd}
                className={classNames({
                    "relative disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center px-3 py-2 -ml-px text-sm font-medium border-2 rounded-r-lg border-celadon-green-500 text-celadon-green-500  hover:text-celadon-green-dark-500 focus:z-10 focus:outline-none ": true,
                })}
            >
                <PlusIcon className="w-3 h-3"/>
            </button>
        </div>
    );
}
