export const RESULT_CODE = "PresentToShopper";
export const MULTIBANCO_PAYMENT = "multibanco";
export const MULTIBANCO_PAYMENT_NAME = "Multibanco";
export const SOFORT_PAYMENT = "directEbanking";
export const SOFORT_PAYMENT_NAME = "SOFORT Überweisung via Online Banking"
export const KLARNA_PAYMENT = "klarna";
export const KLARNA_PAYMENT_NAME = "Klarna";
export const KLARNA_PAY_OVERTIME_PAYMENT = "klarna_account";
export const KLARNA_PAY_OVERTIME_PAYMENT_NAME = "Klarna Pay Over Time";
export const MBWAY_PAYMENT = "mbway";
export const MBWAY_PAYMENT_NAME = "MB WAY";
export const IDEAL_PAYMENT = "ideal";
export const IDEAL_PAYMENT_NAME = "iDeal";
export const EPS_PAYMENT = "eps";
export const EPS_PAYMENT_NAME = "EPS";
export const HPP_PAYMENTS = [
    // MULTIBANCO_PAYMENT,
    SOFORT_PAYMENT,
    KLARNA_PAYMENT,
    KLARNA_PAY_OVERTIME_PAYMENT,
    MBWAY_PAYMENT,
    IDEAL_PAYMENT,
    EPS_PAYMENT
]

export const HPP_PAYMENTS_NAME = {
    // multibanco: MULTIBANCO_PAYMENT_NAME,
    directEbanking: SOFORT_PAYMENT_NAME,
    klarna: KLARNA_PAYMENT_NAME,
    klarna_account: KLARNA_PAY_OVERTIME_PAYMENT_NAME,
    mbway: MBWAY_PAYMENT_NAME,
    ideal: IDEAL_PAYMENT_NAME,
    eps: EPS_PAYMENT_NAME
};
