import React, { Component } from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter, Redirect } from "react-router";
import { connect } from "../../state/useGlobal";
import Loading from "../../components/Loading";
import { STATUS_NOT_FOUND } from "../../state/groupOrder";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import CheckoutSteps from "../../components/CheckoutSteps";
import { STEP_CHECKOUT_CONFIRM } from "../../constants/checkoutSteps";
import { SHIPPING_METHOD_TO_SCHOOL } from "../../constants/shippingMethod";
// import StripePayment from "./Payments/StripePayment";
import CashPayment from "./Payments/CashPayment";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import useGlobal from "../../state/useGlobal";
// import SoftPayment from "./Payments/SoftPayment/SoftPayment";
import PaypalPlusPayment from "./Payments/PaypalPlusPayment/PaypalPlusPayment";
import PaypalExpressPayment from "./Payments/PaypalExpressPayment";
import BigNumber from "bignumber.js";

function PaymentMethods({ payment }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder } = globalState;
  const { school_code } = groupOrder;
  const { t } = useTranslation();

  switch (payment) {
    case "calcuso_cash":
      return <CashPayment />;

    // case "stripe_payments":
    //   return <StripePayment />;
    //
    // case "stripe_payments_sofort":
    //   return <SoftPayment />;

    case "iways_paypalplus_payment":
      return <PaypalPlusPayment />;

    case "paypal_express":
      return <PaypalExpressPayment />;

    default:
      return (
        <>
          <div className="my-2">{t("Not Support Yet.")}</div>
          <div className="flex items-center justify-end pt-8 space-x-8">
            <Link
              to={`/group-orders/${school_code.trim()}/checkout/payment`}
              className="font-medium cursor-pointer text-gunmetal-black-500"
            >
              {t("back")}
            </Link>
            <Button
              type="button"
              color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
              size="xl"
              disabled={true}
            >
              <span>{t("Place your order")}</span>
            </Button>
          </div>
        </>
      );
  }
}

class ConfirmScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      schoolCode: id,
      isSubmitLoading: false,
    };
  }

  componentDidMount() {
    const { globalActions } = this.props;
    globalActions.setCheckout({
      currentCheckoutStep: STEP_CHECKOUT_CONFIRM,
    });
    this._loadGroupOrder();
  }

  _loadGroupOrder = async () => {
    const { globalActions } = this.props;
    const { schoolCode } = this.state;
    await globalActions.loadGroupOrder(schoolCode);
    await globalActions.loadPaymentMethods();
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { t, globalState } = this.props;
    const { isLoading, schoolCode, isSubmitLoading } = this.state;
    const { groupOrder, checkout } = globalState;
    const { status } = groupOrder;
    const {
      totals,
      shipping,
      billing,
      shippingMethodCode,
      payment,
      paymentLabel,
    } = checkout;

    return (
      <AppLayout
        contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
        textColor="text-gunmetal-black"
        border={false}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {status === STATUS_NOT_FOUND ? (
              <Redirect to="/" />
            ) : (
              <>
                <Helmet>
                  <title>
                    {t("Address")} | {schoolCode} | {groupOrder?.school?.name} |{" "}
                    {t("Group Order")}
                  </title>
                </Helmet>
                <div className="flex flex-col h-full min-h-screen">
                  <Header title={t("Checkout")}>
                    <CheckoutSteps />
                  </Header>

                  <div className="flex flex-col justify-between flex-1 h-full">
                    <div className="flex justify-center flex-1">
                      <div className="w-full px-2 md:px-4 lg:w-2/3 xl:w-1/2 lg:px-8">
                        <div className="px-4 pt-6 md:px-6 lg:px-8 lg:pt-12">
                          <div className="flex flex-col space-y-4 ">
                            <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                              {t("Order summary")}:
                            </h3>
                            <ul className="divide-y-2 divide-celadon-green-light-500">
                              {totals &&
                              totals?.items &&
                              totals?.items.length > 0 ? (
                                totals?.items.map((item) => (
                                  <li
                                    key={item.item_id}
                                    className="py-4 text-sm"
                                  >
                                    <div className="grid grid-cols-1 gap-8 md:grid-cols-4">
                                      <div className="flex items-center justify-between space-x-4 md:col-span-3">
                                        <p className="font-medium ">
                                          {item.name}
                                        </p>
                                        <p className="text-right">
                                          {t("{{qty}} piece", {
                                            qty: item.qty,
                                          })}
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-between space-x-4">
                                        <p>
                                          {new BigNumber(item.price_incl_tax)
                                            .toFormat(2)
                                            .toString()}{" "}
                                          €
                                        </p>
                                        <p className="font-medium ">
                                          {new BigNumber(
                                            item.row_total_incl_tax
                                          )
                                            .toFormat(2)
                                            .toString()}{" "}
                                          €
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <li className="py-4 text-center">
                                  {t("No items")}
                                </li>
                              )}
                              <li className="py-4 text-sm text-center">
                                <div className="flex items-center justify-between space-x-4 ">
                                  <p className="font-medium ">
                                    {t("Shipping")}
                                  </p>
                                  <p className="font-medium text-right">
                                    {new BigNumber(
                                      totals?.base_shipping_incl_tax
                                        ? totals?.base_shipping_incl_tax
                                        : 0
                                    )
                                      .toFormat(2)
                                      .toString()}{" "}
                                    €
                                  </p>
                                </div>
                              </li>
                              <li className="py-4 text-center">
                                <div className="flex items-center justify-between space-x-4 ">
                                  <p className="font-medium ">{t("Total")}</p>
                                  <p className="font-medium text-right">
                                    {new BigNumber(
                                      totals?.base_grand_total
                                        ? totals?.base_grand_total
                                        : 0
                                    )
                                      .toFormat(2)
                                      .toString()}{" "}
                                    €
                                  </p>
                                </div>
                              </li>
                            </ul>
                            <div className="flex justify-between py-4 border-b-2 border-celadon-green-light-500">
                              <div className="space-y-2">
                                <p className="font-medium md:text-lg text-gunmetal-black-500">
                                  {t("Delivery address")}
                                </p>
                                <div className="flex flex-col ">
                                  {shippingMethodCode ===
                                  SHIPPING_METHOD_TO_SCHOOL ? (
                                    <span className="text-sm text-gunmetal-black-500">
                                      {shipping?.company}
                                    </span>
                                  ) : (
                                    <span className="text-sm text-gunmetal-black-500">
                                      {shipping?.firstname} {shipping?.lastname}
                                    </span>
                                  )}
                                  <span className="text-sm text-gunmetal-black-500">
                                    {shipping?.street}
                                  </span>
                                  <span className="text-sm text-gunmetal-black-500">
                                    {shipping?.postcode} {shipping?.city}
                                  </span>
                                  <span className="text-sm text-gunmetal-black-500">
                                    {shipping?.country_id}
                                  </span>
                                </div>
                              </div>
                              <div className="text-right ">
                                <p className="font-medium md:text-lg text-gunmetal-black-500">
                                  {t("Billing address")}
                                </p>
                                <div className="flex flex-col ">
                                  <span className="text-sm text-gunmetal-black-500">
                                    {billing?.firstname} {billing?.lastname}
                                  </span>
                                  <span className="text-sm text-gunmetal-black-500">
                                    {billing?.street}
                                  </span>
                                  <span className="text-sm text-gunmetal-black-500">
                                    {billing?.postcode} {billing?.city}
                                  </span>
                                  <span className="text-sm text-gunmetal-black-500">
                                    {billing?.country_id}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center justify-between py-4 ">
                              <p className="font-medium ">
                                {t("Payment method")}
                              </p>
                              <p className="font-medium text-right">
                                {paymentLabel}
                              </p>
                            </div>
                            <PaymentMethods
                              payment={payment}
                              isSubmitLoading={isSubmitLoading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <Footer />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </AppLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(ConfirmScreen)));
