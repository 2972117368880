import React from "react";
import useGlobal from "../../state/useGlobal";
import {toMoney} from "../../services/money";
import {useTranslation} from "react-i18next";

export default function CartTotalSegment() {
    /* eslint-disable no-unused-vars */
    const [globalState] = useGlobal();
    const {t} = useTranslation();
    const {fakeCart} = globalState;
    return (
        <div className="pt-6 pb-12 border-t-2 border-gunmetal-black-500 flex flex-col gap-4">
            {fakeCart.discount && fakeCart.discount > 0 ? (<div className="flex justify-between text-base text-gunmetal-black-500">
                <p>{t("Subtotal")}:</p>
                <p className="flex items-center gap-2">{toMoney(fakeCart.total)}</p>
            </div>) : null}
            {fakeCart.discount && fakeCart.discount > 0 ? (<div className="flex justify-between text-base text-gunmetal-black-500">
                <p>{t("Discount")}:</p>
                <p className="flex items-center gap-2 ">-{toMoney(fakeCart.discount)}</p>
            </div>) : null}

            <div className="flex justify-between text-base font-medium text-gunmetal-black-500">
                <p>{t("Total")}:</p>
                <p className="flex items-center gap-2">{toMoney(fakeCart.totalWithDiscount)}</p>
            </div>
        </div>
    )
}
