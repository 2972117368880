import React from "react";
import FakeCartItemConf from "../../components/FakeCartModal/FakeCartItemConf";
import FakeCartItem from "../../components/FakeCartModal/FakeCartItem";
import {PRODUCT_TYPE_CONFIGURABLE,} from "../../constants/common";
import useGlobal from "../../state/useGlobal";
import {useTranslation} from "react-i18next";

export default function PreviewNormal() {
    const [globalState, globalActions] = useGlobal();
    const {fakeCart} = globalState;
    const {t} = useTranslation();

    try {
        if (fakeCart?.relatedPackageSelected === null || fakeCart?.relatedPackageSelected?.package_type === 'all') {
            return (
                <div className="min-w-full px-2 m-auto">
                    <div className="flow-root">
                        <ul className="-my-6 divide-y-2 divide-floral-white-500">
                            {globalActions?.getSortCartItems().length > 0 ? (
                                globalActions?.getSortCartItems().map((item) =>
                                    item.type === PRODUCT_TYPE_CONFIGURABLE ? (
                                        <FakeCartItemConf key={item.id} item={item}/>
                                    ) : (
                                        <FakeCartItem key={item.id} item={item}/>
                                    )
                                )
                            ) : (
                                <li className="flex items-center justify-center py-6">
                                    <p>{t("No items")}</p>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            );
        }
    } catch (e) {
    }

    return (<div></div>);
}
