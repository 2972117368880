import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";

import ActiveScreen from "../screens/ActiveScreen";
import StudentFormScreen from "../screens/StudentFormScreen";
import SelectProductionScreen from "../screens/SelectProductionScreen";
import DetailProductionScreen from "../screens/DetailProductionScreen";
import AddressScreen from "../screens/AddressScreen";
import GroupOrderScreen from "../screens/GroupOrderScreen";

import PrivateRoute from "./PrivateRoute";
import PreviewScreen from "../screens/PreviewScreen";
import LoginScreen from "../screens/LoginScreen";
import ConfirmPaymentScreen from "../screens/ConfirmPaymentScreen";
import ConfirmScreen from "../screens/ConfirmScreen";
import DoneScreen from "../screens/DoneScreen";
import ErrorScreen from "../screens/ErrorScreen";
import ScrollToTop from "./ScrollToTop";
import AllInOneProductsScreen from "../screens/AllInOneProductsScreen";
import {GA4R, GA4React} from "ga-4-react";
import config from "../config";
import ConfigProductInPackageScreen from "../screens/ConfigScreen";
import toast from "react-hot-toast";
import {CURRENT_VERSION, KEY_VERSION} from "../version";
import useGlobal from "../state/useGlobal";
import history from "./history";
import {useTranslation} from "react-i18next";

const screens = [
    {
        path: "/group-orders/:id/configurable",
        isPublicRoute: false,
        component: ConfigProductInPackageScreen,
    },
    {
        path: "/group-orders/:id/checkout/login",
        isPublicRoute: false,
        component: LoginScreen,
    },
    {
        path: "/group-orders/:id/checkout/address",
        isPublicRoute: false,
        component: AddressScreen,
    },
    {
        path: "/group-orders/:id/checkout/payment",
        isPublicRoute: false,
        // component: PaymentScreen,
        component: ConfirmPaymentScreen,
    },
    {
        path: "/group-orders/:id/checkout/confirm",
        isPublicRoute: false,
        component: ConfirmScreen,
    },
    {
        path: "/group-orders/:id/checkout/done",
        isPublicRoute: true,
        component: DoneScreen,
    },
    {
        path: "/group-orders/:id/checkout/error",
        isPublicRoute: true,
        component: ErrorScreen,
    },
    {
        path: "/group-orders/:id/product/:productId",
        isPublicRoute: false,
        component: DetailProductionScreen,
    },
    {
        path: "/group-orders/:id/products",
        isPublicRoute: false,
        component: AllInOneProductsScreen,
    },
    {
        path: "/group-orders/:id/preview",
        isPublicRoute: false,
        component: PreviewScreen,
    },
    {
        path: "/group-orders/:id/select-calculator",
        isPublicRoute: false,
        component: SelectProductionScreen,
    },
    {
        path: "/group-orders/:id/student-information",
        isPublicRoute: true,
        component: StudentFormScreen,
    },
    {
        path: "/group-orders/:id",
        isPublicRoute: true,
        component: GroupOrderScreen,
    },

    {path: "/", isPublicRoute: true, component: ActiveScreen},
];

export default function Routes() {
    const [ga, setGa] = useState(false);
    const [globalState] = useGlobal();
    const {t} = useTranslation();

    useEffect(() => {
        try {
            const ga4react = new GA4React(config.gt4);
            ga4react.initialize().then((ga4) => {
                setGa(true);
            }).catch((err) => {
                setGa(false);
            });
        } catch (e) {
            setGa(false);
        }
    }, []);

    useEffect(() => {
        const storedVersion = localStorage.getItem(KEY_VERSION);

        if (storedVersion !== CURRENT_VERSION) {
            if (globalState?.groupOrder?.school_code) {
                // IF screen is checkout/address checkout/payment checkout/done do not redirect
                const url = window.location.href;
                const screen = url.split("/").pop();
                if (screen === "address" || screen === "payment" || screen === "done") {
                    return;
                }
                //If customer in process of ordering, clear all data and redirect to home page
                toast.success(t("New version is available...."));
                history.push(`/group-orders/${globalState.groupOrder.school_code.trim()}`);
                localStorage.clear();
            }
            localStorage.setItem(KEY_VERSION, CURRENT_VERSION);
        }
    }, [window.location.href, globalState?.groupOrder?.school_code]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ScrollToTop>
            <Switch>
                {screens.map((screen) => {
                    if (screen.isPublicRoute) {
                        const PublicPage = screen.component;
                        return (
                            <Route key={screen.path} path={screen.path}>
                                {ga ? <GA4R code={config.gt4} config={{send_page_view: false}}><PublicPage/></GA4R> : <PublicPage/>}
                            </Route>
                        );
                    } else {
                        const ProtectedPage = screen.component;
                        return (
                            <PrivateRoute key={screen.path} path={screen.path}>
                                {ga ? <GA4R code={config.gt4} config={{send_page_view: false}}><ProtectedPage/></GA4R> : <ProtectedPage/>}
                            </PrivateRoute>
                        );
                    }
                })}
            </Switch>
        </ScrollToTop>
    );
}
