export const initialState = {
  open: false,
  product: false,
};

export const setProductModal = (store, productModal) => {
  store.setState({ productModal });
};

export const openProductModal = (store, product, schoolCode) => {
  store.setState({
    productModal: {
      open: true,
      product,
      schoolCode,
    },
  });
};

export const closeProductModal = (store) => {
  store.setState({
    productModal: {
      ...store.state.productModal,
      open: false,
    },
  });
};
