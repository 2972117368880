import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import {SHIPPING_METHOD_TO_PRIVATE, SHIPPING_METHOD_TO_SCHOOL,} from "../../constants/shippingMethod";
import {Redirect, withRouter} from "react-router";
import {connect} from "../../state/useGlobal";
import Loading from "../../components/Loadingv2";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import {Helmet} from "react-helmet";
import * as Yup from "yup";
import CartModal from "../../components/CartModal";
import Footer from "../../components/Footer";
import CheckoutSteps from "../../components/CheckoutSteps";
import {STEP_CHECKOUT_ADDRESS} from "../../constants/checkoutSteps";
import ShippingMethods from "./ShippingMethods";
import ShipToPrivate from "./Options/ShipToPrivate";
import ShipToSchool from "./Options/ShipToSchool";
import {Formik} from "formik";
import {OPTIONAL_ZIP_COUNTRIES, POSTCODE_REGEX, REGEX_NAME, REGEX_STREET,} from "../../constants/common";
import history from "../../routes/history";
import api from "../../services/api";
import Input from "../../components/Forms/Input";
import ScrollToFieldError from "../../components/ScrollToFieldError";
import {HandleAPICartError} from "../../helpers";
import mixpanel from 'mixpanel-browser';
// eslint-disable-next-line
const EMAIL_REGX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$|^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[^@\s]+\.[^@\s]+$/;

class AddressScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      schoolCode: id,
      isSubmitLoading: false,
    };
  }

  componentDidMount() {
    const { globalActions, ga4 } = this.props;
    globalActions.setCheckout({
      currentCheckoutStep: STEP_CHECKOUT_ADDRESS,
    });
    const {globalState} = this.props;
    const {checkout} = globalState;
    const {billing, shipping} = checkout;

    if (billing === undefined || shipping === undefined) {
      return history.push(`/`);
    }

    this._loadGroupOrder();

    try {
      ga4.pageview('/calcuso-group-order-input-address', '/calcuso-group-order-input-address', 'Group Order | Checkout input address');
      const {schoolCode} = this.state;
      mixpanel.track_pageview({
        "page": "Group Order | Checkout input address",
        "school_code": schoolCode,
      })
    } catch (e) {
      console.log(e);
    }
  }

  _loadGroupOrder = async () => {
    const { globalActions } = this.props;
    const { schoolCode } = this.state;
    await globalActions.loadGroupOrder(schoolCode);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { t, globalState, globalActions } = this.props;
    const { isLoading, schoolCode, isSubmitLoading } = this.state;
    const { groupOrder, checkout } = globalState;
    const { status } = groupOrder;

    return (
      <AppLayout
        contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
        textColor="text-gunmetal-black"
        border={false}
      >
        {isLoading && checkout?.shippingMethodCode ? (
          <Loading />
        ) : (
          <>
            {status === STATUS_NOT_FOUND ? (
              <Redirect to="/" />
            ) : (
              <>
                <Helmet>
                  <title>
                    {t("Address")} | {schoolCode} | {groupOrder?.school?.name} |{" "}
                    {t("Group Order")}
                  </title>
                </Helmet>
                <div className="flex flex-col h-full min-h-screen">
                  <Header title={t("Checkout")} withCart>
                    <CheckoutSteps />
                  </Header>

                  <div className="flex flex-col justify-between flex-1 h-full">
                    <div className="flex justify-center flex-1 ">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          email: checkout?.email,
                          is_same_shipping: checkout?.is_same_shipping,
                          shipping: checkout?.shipping,
                          billing: checkout?.billing,
                        }}
                        validationSchema={Yup.lazy((values) => {
                          // Check Ship To School
                          let shippingValidationSchema;
                          if (
                            checkout?.shippingMethodCode ===
                            SHIPPING_METHOD_TO_SCHOOL
                          ) {
                            shippingValidationSchema = Yup.object().shape({
                              firstname: Yup.string().required(
                                t("This is a mandatory field")
                              ),
                              lastname: Yup.string().required(
                                t("This is a mandatory field")
                              ),
                              street: Yup.string().required(
                                t("This is a mandatory field")
                              ),
                              city: Yup.string().required(
                                t("This is a mandatory field")
                              ),
                              postcode: Yup.string().required(
                                t("This is a mandatory field")
                              ),
                              country_id: Yup.string().required(
                                t("This is a mandatory field")
                              ),
                            });
                          } else {
                            // Shipping
                            const postcodeShippingRegex =
                              POSTCODE_REGEX[values.shipping.country_id];
                            let postcodeShippingSchema = Yup.string();
                            if (postcodeShippingRegex) {
                              const combineShippingRegex = Object.keys(
                                postcodeShippingRegex
                              )
                                .map((key) => {
                                  return postcodeShippingRegex[key].pattern;
                                })
                                .join("|");
                              let regexShipping = new RegExp(
                                combineShippingRegex
                              );
                              postcodeShippingSchema = postcodeShippingSchema.matches(
                                regexShipping,
                                t("The postcode entered is incorrect.")
                              );
                            }
                            shippingValidationSchema = Yup.object().shape({
                              firstname: Yup.string()
                                  .required(t("This is a mandatory field"))
                                  .matches(REGEX_NAME, t("The name must not contain any special characters."))
                              ,
                              lastname: Yup.string()
                                  .required(t("This is a mandatory field"))
                                  .matches(REGEX_NAME, t("The name must not contain any special characters."))
                              ,
                              street: Yup.string()
                                .required(t("This is a mandatory field"))
                                .matches(
                                  REGEX_STREET,
                                  t("Please enter street and house number.")
                                ),
                              city: Yup.string().required(
                                t("This is a mandatory field")
                              ),
                              postcode: !OPTIONAL_ZIP_COUNTRIES.includes(
                                values.shipping.country_id
                              )
                                ? postcodeShippingSchema.required(
                                    t("This is a mandatory field")
                                  )
                                : postcodeShippingSchema,
                              country_id: Yup.string().required(
                                t("This is a mandatory field")
                              ),
                            });
                          }

                          // Billing
                          const postcodeBillingRegex =
                            POSTCODE_REGEX[values.billing.country_id];
                          let postcodeBillingSchema = Yup.string();
                          if (postcodeBillingRegex) {
                            const combineBillingRegex = Object.keys(
                              postcodeBillingRegex
                            )
                              .map((key) => {
                                return postcodeBillingRegex[key].pattern;
                              })
                              .join("|");
                            let regexBilling = new RegExp(combineBillingRegex);
                            postcodeBillingSchema = postcodeBillingSchema.matches(
                              regexBilling,
                              t("The postcode entered is incorrect.")
                            );
                          }
                          const billingValidationSchema = Yup.object().shape({
                            firstname: Yup.string()
                                .required(t("This is a mandatory field"))
                                .matches(REGEX_NAME, t("The name must not contain any special characters.")),
                            lastname: Yup.string()
                                .required(t("This is a mandatory field"))
                                .matches(REGEX_NAME, t("The name must not contain any special characters.")),
                            street: Yup.string()
                              .required(t("This is a mandatory field"))
                              .matches(
                                REGEX_STREET,
                                t("Please enter street and house number.")
                              ),
                            city: Yup.string().required(
                              t("This is a mandatory field")
                            ),
                            postcode: !OPTIONAL_ZIP_COUNTRIES.includes(
                              values.billing.country_id
                            )
                              ? postcodeBillingSchema.required(
                                  t("This is a mandatory field")
                                )
                              : postcodeBillingSchema,
                            country_id: Yup.string().required(
                              t("This is a mandatory field")
                            ),
                          });

                          return Yup.object().shape({
                            email: Yup.string()
                              .email(t("The email must be a valid email."))
                              .matches(EMAIL_REGX, t("The email must be a valid email."))
                              .required(t("This is a mandatory field"))
                              ,
                            shipping: shippingValidationSchema,
                            billing: billingValidationSchema,
                          });
                        })}
                        onSubmit={async (values) => {
                          this.setState({ isSubmitLoading: true });
                          try {
                            const { checkout } = globalState;
                            const {
                              quote_id,
                              student,
                              shippingMethodCode,
                              shippingCarrierCode,
                            } = checkout;
                            const { shipping, billing } = values;

                            const payload = {
                              addressInformation: {
                                shipping_address: {
                                  ...shipping,
                                  street: [shipping.street],
                                },
                                billing_address: {
                                  ...billing,
                                  street: [billing.street],
                                },
                                extension_attributes: {
                                  school_name:
                                    student.first_name.trim() +
                                    " " +
                                    student.last_name.trim(),
                                  school_room: student.class_name,
                                  student_first_name: student.first_name.trim(),
                                  student_last_name: student.last_name.trim(),
                                  to_school:shippingMethodCode === SHIPPING_METHOD_TO_SCHOOL? "1" : "0",
                                },
                                shipping_method_code: shippingMethodCode,
                                shipping_carrier_code: shippingCarrierCode,
                              },
                            };
                            // Load async data.
                            let paymentData = await api.post(
                              `/guest-carts/${quote_id}/shipping-information`,
                              {},
                              payload
                            );

                            const { data } = paymentData;
                            const ayden = await globalActions.loadPaymentMethodsAdyen(globalState);
                            globalActions.setCheckout({
                              ...values,
                              payment_methods: data["payment_methods"],
                              payment_methods_adyen: ayden,
                            });
                            history.push(
                              `/group-orders/${schoolCode.trim()}/checkout/payment`
                            );
                          } catch (error) {
                            HandleAPICartError(error, globalState);
                          }
                          this.setState({ isSubmitLoading: false });
                        }}
                        validateOnChange
                      >
                        {({
                          errors,
                          touched,
                          handleSubmit,
                          values,
                          handleChange,
                          handleBlur,
                          isValid,
                          setValues,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            className="w-full px-2 md:px-4 lg:w-2/3 xl:w-1/2 lg:px-8"
                          >
                            <ScrollToFieldError />
                            <div className="px-4 pt-6 md:px-6 lg:px-8 lg:pt-12">
                              <div className="flex flex-col space-y-4 ">
                                <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                                  {t("Your personal information")}
                                </h3>
                                <Input
                                  name="email"
                                  label={t("Email")}
                                  placeholder={t("example@mail.com")}
                                  size="lg"
                                  onChange={e => {
                                      handleChange(e);
                                      setValues(
                                          (prevValues) => ({
                                              ...prevValues,
                                              email: e.target.value.trim().toLowerCase(),
                                          }),
                                          true
                                      )
                                  }}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  required
                                  error={touched.email && errors.email}
                                  errorMessage={errors.email}
                                />
                                <ShippingMethods
                                  {...{
                                    values,
                                  }}
                                />
                                {{
                                  [SHIPPING_METHOD_TO_PRIVATE]: (
                                    <ShipToPrivate
                                      {...{
                                        errors,
                                        touched,
                                        values,
                                        handleChange,
                                        handleBlur,
                                        isValid,
                                        setValues,
                                        isSubmitLoading,
                                      }}
                                    />
                                  ),
                                  [SHIPPING_METHOD_TO_SCHOOL]: (
                                    <ShipToSchool
                                      {...{
                                        errors,
                                        touched,
                                        values,
                                        handleChange,
                                        handleBlur,
                                        isValid,
                                        setValues,
                                        isSubmitLoading,
                                      }}
                                    />
                                  ),
                                }[checkout?.shippingMethodCode] || (
                                  <p className="text-lg font-medium text-gunmetal-black-500">
                                    {t(
                                      "Not Supported. Please contact our customer support."
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <Footer />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <CartModal />
      </AppLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(AddressScreen)));
