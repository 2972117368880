import * as React from "react";

function MultibancoLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 452" {...props}>
      <path d="M84.7 37.1c-10.6 2.5-21.8 12.3-25.5 22.2-1.4 3.6-2.5 11.1-3.6 23.5-1.4 16.1-1.4 18.7-.1 21.1 1.8 3.6 6.5 5.6 10.1 4.4 5.4-1.8 6.3-4.4 8-23.3.9-10.8 2.1-18.7 3.1-20.7 2-4.3 6.4-8 11.1-9.3 5.1-1.4 271.3-1.4 276.4 0 4.7 1.3 9.1 5 11.1 9.3 1 2 2.2 9.9 3.1 20.7 1.7 18.9 2.6 21.5 8 23.3 3.6 1.2 8.3-.8 10.1-4.4 1.3-2.4 1.3-5-.1-21.1-1.1-12.5-2.2-19.9-3.6-23.5-2.8-7.6-11.1-16.2-19-19.9l-6.3-2.9-139.5-.2c-79.1 0-141.1.3-143.3.8zM61 138.3c-6.6 4.3-6.1 2-16 89.5-5 44.7-8.8 81.8-8.4 82.5 3.5 6.2 10.4 7.7 14.9 3.2 1.4-1.3 2.5-3 2.5-3.7.1-.7 3.8-34.2 8.4-74.3 8-70.2 8.4-72.9 9.9-69.5.8 1.9 13.2 31.2 27.5 65.1 27.9 66 29 68.5 31.8 71 7.9 6.9 19.6 6.5 26.8-1 1.7-1.8 12.1-25.2 30.6-68.9 15.3-36.4 28.3-67 28.9-67.9.8-1.5 1-1.5 1.1-.3 0 3 16 143.5 16.5 145.7 1.5 6.4 10.5 8.6 15 3.7 1.4-1.5 2.5-3.9 2.5-5.3s-4-38.3-9-81.9c-7.2-64.2-9.3-80-10.8-82.5-5-8.7-16.3-10.4-23.8-3.6-1.1 1.1-15.6 34.2-33 75.4-17 40.4-31.3 73.3-31.8 73.2-.5-.2-14.8-33-31.7-73-16.8-40-31.5-73.7-32.5-74.8-4.8-5.4-13.7-6.5-19.4-2.6zM274.3 140.4l-2.8 2.4v166.4l2.8 2.4 2.8 2.4h48.2c43.7 0 48.9-.2 54.8-1.8 19.5-5.4 33.4-22 35.5-42.4 1.8-18.2-9.1-37.9-25.8-46.7l-5.7-2.9 3.1-3.9c8-9.9 11.9-24.7 9.8-37.1-3.2-19.1-16.6-34.3-34.9-39.4-5.8-1.6-10.8-1.8-45.8-1.8h-39.2l-2.8 2.4zm84.7 17.3c7 2.3 15 9.6 18.1 16.5 3.1 7.2 3.2 17.4 0 24.3-2.8 6.3-8.2 11.9-14.6 15.3-4.7 2.5-6.1 2.6-23.2 3.2-17.2.5-18.4.6-20.5 2.8-3.1 3.1-3.1 9.3 0 12.4 2.2 2.2 2.9 2.3 29.5 2.8 27.1.5 27.2.5 32.2 3.2 10.9 5.8 16.9 15.4 16.9 27.3 0 9.5-2.9 16-10.1 22.5-8.7 7.9-9.1 8-57 8H289V156h32.4c27.7 0 33.1.2 37.6 1.7zM36.5 343.9c-4.8 2.1-5.7 4.4-6.7 18.3-.5 7.3-.8 16.3-.5 20.1.8 13 9.1 24.7 21.5 30.5l5.7 2.7 166 .3c123.2.2 167.4 0 171.5-.8 15.9-3.4 27.7-16.8 28.7-32.9.3-3.6 0-12.6-.5-20-.9-12.4-1.2-13.5-3.6-15.9-3.5-3.5-8.7-3.5-12.2-.1-3 3.1-3.1 3.8-2.1 22.1.8 13.5.7 15-1.1 19.2-1.4 3-3.4 5.4-6.3 7.3l-4.3 2.8H59.4l-4.3-2.8c-2.9-1.9-4.9-4.3-6.3-7.3-1.8-4.2-1.9-5.7-1.1-19.2.4-8.1.7-15.8.6-17.1-.4-4.9-7.3-9.1-11.8-7.2z" />
    </svg>
  );
}

export default MultibancoLogo;
