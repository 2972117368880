import React, {useEffect, useState} from "react";
import useGlobal from "../../../state/useGlobal";
import {useTranslation} from "react-i18next";
import {ATTRIBUTE_CODE_COLOR, ATTRIBUTE_CODE_GRAVIUR, ATTRIBUTE_CODE_IS_GRAVIUR} from "../../../constants/common";
import Color from "./Options/Color";
import Gravur from "./Options/Gravur";
import config from "../../../config";
import {CheckCircleIcon} from "@heroicons/react/solid";

const Image = ({src, alt}) => {
    const [errored, setErrored] = useState(false);

    if (errored) {
        return (
            <svg
                className="w-full h-full text-gray-300 bg-white border border-gray-200 rounded-md"
                preserveAspectRatio="none"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 200 200"
                aria-hidden="true"
            >
                <path
                    vectorEffect="non-scaling-stroke"
                    strokeWidth="1"
                    d="M0 0l200 200M0 200L200 0"
                ></path>
            </svg>
        );
    } else {
        return (
            <img
                className="flex-shrink-0 object-contain w-full h-full mx-auto"
                src={`${config.cdn}${config.baseUrlMedia}${src}`}
                alt={alt}
                onError={setErrored}
            />
        );
    }
};

export default function PackageFakeCartItemConf({item}) {
    /* eslint-disable no-unused-vars */
    const [globalState] = useGlobal();
    const {t} = useTranslation();
    const {groupOrder} = globalState;
    const {calculator} = groupOrder;
    const {product, price, qty, options} = item;

    // Find Simple
    const {product_simples} = product;
    const [simpleProduct, setSimpleProduct] = useState(null);
    useEffect(() => {
        const getImage = (lastValues) => {
            const product = product_simples.find((product_simple) => {
                let find = true;
                for (
                    let index = 0;
                    index < product_simple.product_custom_attributes.length;
                    index++
                ) {
                    const element = product_simple.product_custom_attributes[index];
                    if (element.value !== lastValues[element.attribute_code]) {
                        return false;
                    }
                }

                return find;
            });

            setSimpleProduct(product);
        };

        getImage(options);
    }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <li className="flex py-2">
            <div className="flex-shrink-0 w-16 overflow-hidden md:w-12 md:h-12">
                <Image src={simpleProduct?.product_image} alt={simpleProduct?.name}/>
            </div>

            <div className="flex flex-col flex-1 ml-4 space-y-4 text-xs">
                <div>
                    <h3 className="font-medium text-gray-900 overflow-ellipsis ">
                        {product.name}
                    </h3>
                    {options &&
                        Object.entries(options).map(([key, value]) => {
                            const option = product?.configurable_product_options.find(
                                (option) => option.attribute_code === key
                            );
                            const attribute_option = option?.attribute_options.find(
                                (attribute_option) => attribute_option.value_index === value
                            );

                            switch (key) {
                                case ATTRIBUTE_CODE_COLOR:
                                    return value ? (
                                        <Color key={key} value={value} item={item}/>
                                    ) : (
                                        ""
                                    );
                                case ATTRIBUTE_CODE_GRAVIUR:
                                    return <Gravur key={key} value={value} item={item}/>;
                                case ATTRIBUTE_CODE_IS_GRAVIUR:
                                    return "";
                                default:
                                    return (
                                        <p key={key} className="mt-1 text-gunmetal-black-500">
                                            {attribute_option?.label}
                                        </p>
                                    );
                            }
                        })}
                </div>
            </div>
            <div className="text-xs"><CheckCircleIcon className="w-6 h-6 text-celadon-green-900 inline "/></div>
        </li>
    );
}
