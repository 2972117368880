import React, { useState } from "react";
import classNames from "classnames";
import { ViewGridIcon, ViewListIcon } from "@heroicons/react/outline";
import CalculatorTab from "./Tabs/CalculatorTab";
import SchoolbookTab from "./Tabs/SchoolbookTab";
import StationeryTab from "./Tabs/StationeryTab";
import UpSellTab from "./Tabs/UpSellTab";
import {
  PRODUCT_TAB_CALCULATOR,
  PRODUCT_TAB_SCHOOLBOOK,
  PRODUCT_TAB_STATIONERY,
  PRODUCT_TAB_UP_SELL,
} from "../../constants/productTabs";

const tabs = [
  {
    key: PRODUCT_TAB_CALCULATOR,
    name: "Taschenrechner",
    component: CalculatorTab,
  },
  {
    key: PRODUCT_TAB_SCHOOLBOOK,
    name: "Schulbücher",
    component: SchoolbookTab,
  },
  {
    key: PRODUCT_TAB_STATIONERY,
    name: "Schreibwaren",
    component: StationeryTab,
  },
  { key: PRODUCT_TAB_UP_SELL, name: "Up Sell", component: UpSellTab },
];

export default function ProductTabs({ item }) {
  const [viewGrid, setViewGrid] = useState(false);
  const [currentTab, setCurrentTab] = useState(PRODUCT_TAB_CALCULATOR);

  return (
    <div className="px-4 py-4 mx-auto sm:px-6 lg:px-8 xl:px-16">
      <div className="block">
        <div className="flex items-center justify-between border-b border-gray-200">
          <nav className="flex w-full -mb-px space-x-2 md:space-x-8">
            {tabs.map((tab) => (
              <div
                key={tab.key}
                onClick={() => setCurrentTab(tab.key)}
                className={classNames({
                  "border-gunmetal-black-500 text-gunmetal-black-600":
                    tab.key === currentTab,
                  "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300": !(
                    tab.key === currentTab
                  ),
                  "whitespace-nowrap truncate py-4 px-1 border-b-4 font-normal text-xs md:text-sm cursor-pointer": true,
                })}
              >
                {tab.name}
              </div>
            ))}
          </nav>
          <button
            type="button"
            onClick={() => {
              setViewGrid(!viewGrid);
            }}
            className="p-2 ml-5 -m-2 text-gunmetal-black-400 sm:ml-7 hover:text-gunmetal-black-500"
          >
            {!viewGrid ? (
              <ViewGridIcon className="w-6 h-6" aria-hidden="true" />
            ) : (
              <ViewListIcon className="w-6 h-6" aria-hidden="true" />
            )}
          </button>
        </div>
      </div>
      {tabs.map((tab) => {
        const TabComponent = tab.component;
        if (tab.key === currentTab)
          return <TabComponent key={tab.key} item={item} viewGrid={viewGrid} />;

        return "";
      })}
    </div>
  );
}
