import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import {connect} from "../../state/useGlobal";
import {Redirect, withRouter} from "react-router";
import {Helmet} from "react-helmet";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import Loading from "../../components/Loadingv2";
import ProductModal from "../../components/ProductModal";
import FakeCartModal from "../../components/FakeCartModal";
import CartSteps from "../../components/CartSteps";
import {STEP_UP_SELL_PRODUCT} from "../../constants/cartSteps";
import history from "../../routes/history";
import ProductsSections from "./ProductsSections";
import mixpanel from 'mixpanel-browser';
import {IS_NOT_GRAVIUR, PRODUCT_TYPE_CONFIGURABLE} from "../../constants/common";
import GravurModal from "../../components/GravurModal";

class AllInOneProductsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            schoolCode: this.props.match.params.id,
        };
    }

    componentDidMount() {
        const {globalActions, ga4} = this.props;
        globalActions.setCheckout({currentStep: STEP_UP_SELL_PRODUCT});
        this.loadGroupOrder();

        try {
            ga4.pageview('/calcuso-group-order-add-cart-product', '/calcuso-group-order-add-cart-product', 'Group Order | Add product to cart');
            mixpanel.track_pageview({
                "page": "Group Order | Add product to cart",
                "school_code": this.state.schoolCode,
            });
        } catch (e) {
            console.log(e);
        }
    }

    loadGroupOrder = async () => {
        const {globalActions, globalState} = this.props;
        const {schoolCode} = this.state;

        const {groupOrder, fakeCart} = globalState;
        const {school_code, calculator} = groupOrder;

        if (school_code && school_code !== schoolCode) {
            history.push(`/group-orders/${schoolCode.trim()}`);
        }

        await globalActions.loadGroupOrder(schoolCode);
        const isMultipleRelatedPackage = calculator?.related_package?.length > 1 && calculator?.related_package.some(p => p.package_type !== 'all');

        // If cart is empty and user has not selected related package, add initial products to cart
        if (globalActions.getSortCartItems().filter((item) => item?.product?.is_calculator === true).length === 0) {
            if (calculator?.pre_select) {
                this.addInitialProductsToCart(calculator);
            }
            if (isMultipleRelatedPackage) {
                if (fakeCart.userHasSelectRelatedPackage) {
                    globalActions.addRelatedBundlePackage(fakeCart.userHasSelectRelatedPackage)
                } else {
                    const mostPopularPackage = calculator?.related_package.find(p => p.is_most_popular);
                    globalActions.addRelatedBundlePackage(mostPopularPackage)
                }
            } else {
                this.addPreselectProductsToCart(calculator);
            }
        }
        this.setState({isLoading: false});
    };

    addInitialProductsToCart(calculator) {
        const lowestPrice = this.findLowestPrice(calculator.product_simples);
        const initOptions = this.getInitialOptions(calculator.configurable_product_options, lowestPrice);

        this.props.globalActions.addToCart({
            id: calculator.product_id,
            name: calculator.name,
            sku: calculator.product_sku,
            type: calculator.product_type,
            price: calculator.product_price,
            qty: 1,
            product: calculator,
            options: {
                ...initOptions,
                gravur: "",
                is_graviur: IS_NOT_GRAVIUR,
            },
            created_at: new Date().getTime() + 10,
        });
    }

    addPreselectProductsToCart(calculator) {
        const preselectProducts = calculator?.product_related.filter(p => p.pre_select) || [];
        preselectProducts.forEach((preselectProduct, index) => {
            if (preselectProduct.product_type === PRODUCT_TYPE_CONFIGURABLE) {
                this.addConfigurableProductToCart(preselectProduct, (index + 1) * 11);
            } else {
                this.props.globalActions.addToCart({
                    id: preselectProduct.product_id,
                    name: preselectProduct.name,
                    sku: preselectProduct.product_sku,
                    type: preselectProduct.product_type,
                    price: preselectProduct.product_price,
                    qty: 1,
                    product: preselectProduct,
                    options: {},
                    created_at: new Date().getTime() + (index + 1) * 11,
                });
            }
        });
    }

    findLowestPrice(products) {
        return products.reduce((a, b) => (a.product_price <= b.product_price ? a : b), {});
    }

    getInitialOptions(options, lowestPrice) {
        return options.reduce((obj, item) => {
            const lowestAttribute = lowestPrice.product_custom_attributes.find(attr => attr.attribute_code === item.attribute_code);
            return Object.assign(obj, {[item.attribute_code]: lowestAttribute?.value});
        }, {});
    }

    addConfigurableProductToCart(product, createdAtOffset = 10) {
        const lowestPrice = this.findLowestPrice(product.product_simples);
        const initOptions = this.getInitialOptions(product.configurable_product_options, lowestPrice);

        this.props.globalActions.addToCart({
            id: product.product_id,
            name: product.name,
            sku: product.product_sku,
            type: product.product_type,
            price: product.product_price,
            qty: 1,
            product: product,
            options: {
                ...initOptions,
                gravur: "",
                is_graviur: IS_NOT_GRAVIUR,
            },
            created_at: new Date().getTime() + createdAtOffset,
        });
    }



    render() {
        const {t, globalState} = this.props;
        const {isLoading, schoolCode} = this.state;
        const {groupOrder} = globalState;
        const {status} = groupOrder;

        return (
            <AppLayout contentBackgroundColor="bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6" textColor="text-gunmetal-black" border={false}>
                {isLoading ? (
                    <Loading/>
                ) : (
                    <>
                        {status === STATUS_NOT_FOUND ? (
                            <Redirect to="/"/>
                        ) : (
                            <>
                                <Helmet>
                                    <title>
                                        {schoolCode} | {groupOrder?.school?.name} | {t("Group Order")}
                                    </title>
                                </Helmet>
                                <Header title={t("Group Order")} subTitle={groupOrder?.school?.name}>
                                    <CartSteps/>
                                </Header>
                                <ProductsSections groupOrder={groupOrder}/>
                            </>
                        )}
                    </>
                )}
                <ProductModal/>
                <FakeCartModal/>
                <GravurModal/>
            </AppLayout>
        );
    }
}

export default withRouter(connect(withTranslation()(AllInOneProductsScreen)));
