import * as React from "react";

const FireIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      style={{
        fill: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      d="M843.357 446.283c4.914-2 9.313-8.039 9.313-15.5 0-8.203-6.22-18.351-20.594-28.101a.93.93 0 0 0-.578-.164h-.078a.88.88 0 0 0-.711.797c-.547 6.086-4.063 10.109-7.64 14.265-3.579 4.156-7.204 8.508-7.204 15.031.04 7.344 5.813 13.368 13.148 13.72"
      transform="matrix(.5 0 0 .5 -404.433 -200.169)"
    />
    <path
      style={{
        fill: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      d="M841.484 440.877c3.07-4.195 3.695-8.516 2.594-12.43-1.094-3.914-3.867-7.414-7.39-10.32a.874.874 0 0 0-1.423.82c.477 2.477.649 5.008.492 7.531a7.122 7.122 0 0 1-1.148 3.75 1.17 1.17 0 0 1-.758.508c-.304.063-.625 0-.89-.18a7.787 7.787 0 0 1-2.899-2.93.868.868 0 0 0-.695-.39.865.865 0 0 0-.727.336c-3.273 4.406-3.125 7.938-1.976 10.211a8.193 8.193 0 0 0 3.18 3.344"
      transform="matrix(.5 0 0 .5 -405.055 -201.122)"
    />
  </svg>
);

export default FireIcon;
