import React, {useEffect, useMemo} from "react";
import {RadioGroup} from "@headlessui/react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../state/useGlobal";
import BigNumber from "bignumber.js";
import {IS_GRAVIUR} from "../../../../constants/common";
import * as Sentry from "@sentry/react";

export default function PackageSelectStyleTag({option, formik, item}) {
    const [globalState, globalActions] = useGlobal();
    const {fakeCart} = globalState;
    const {items} = fakeCart;
    const {product_simples} = item;
    const selectedItem = items[item.product_id];
    const {attribute_options, attribute_code} = option;
    const {t} = useTranslation();
    const {values, setFieldValue} = formik;

    // Use useMemo to calculate prices only once and memoize the result
    const estimatedPrices = useMemo(() => {
        const prices = product_simples.reduce((acc, product_simple) => {
            product_simple.product_custom_attributes.forEach(({value}) => {
                const price = new BigNumber(product_simple.product_price);
                acc[value] = price.toFormat(2).toString();
            });
            return acc;
        }, []);

        // Find cheapest price in prices array
        const cheapestPrice = Math.min(...Object.values(prices).map((price) => parseFloat(price)));

        // Get the price of the selected attribute, which will be the reference for calculating differences alway base on cheast price
        return prices.map((price, index) => {
            const currentPrice = new BigNumber(price);
            const value = currentPrice.minus(cheapestPrice).toFormat(2).toString();
            if (value > 0) {
                return "+" + value;
            }
            return 0;
        });

    }, [product_simples, values[attribute_code]]); // eslint-disable-line react-hooks/exhaustive-deps


    const getFinalPrice = () => {
        const product = product_simples.find((product_simple) => {
            return product_simple.product_custom_attributes.every(
                ({value, attribute_code}) => value === values[attribute_code]
            );
        });

        if (!product) {
            Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
            Sentry.setUser({item, globalState});
            Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
            return false;
        }

        if (values.is_graviur === IS_GRAVIUR) {
            const gravurOption = item.product_custom_options.find(
                (custom_option) => custom_option.type === "field"
            );
            const price = new BigNumber(product.product_price).plus(
                new BigNumber(gravurOption.price)
            );
            return price.toFormat(2).toString();
        }

        const price = new BigNumber(product.product_price);
        return price.toFormat(2).toString();
    };

    useEffect(() => {
        if (selectedItem) {
            const price = getFinalPrice();
            globalActions.addOptionsFromCart(selectedItem?.id, price, {
                [attribute_code]: values[attribute_code],
            });
        }
    }, [values[attribute_code]]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex flex-col items-center justify-center space-y-2">
            <RadioGroup
                value={values[attribute_code]}
                onChange={(selectedColor) => {
                    setFieldValue(attribute_code, selectedColor);
                }}
                className="w-full"
            >
                <div className="flex flex-col items-center justify-center md:flex-wrap-reverse gap-2">
                    {attribute_options.map((size) => (
                        <RadioGroup.Option
                            // onClick={() => {globalActions.goToNextStepConfigurable(globalActions.getSortCartItemsInPackageOnly(), selectedItem.product);}}
                            key={size.value_index}
                            value={size.value_index}
                            className={({active, checked}) =>
                                classNames({
                                    "border-cal-primary-celadon-green-00B5B1": checked,
                                    "border-zinc-400": !checked,
                                    "border-gray-400 w-full items-center justify-center py-4 px-5 text-sm font-semibold sm:flex-1 cursor-pointer border rounded-2xl": true,
                                })
                            }
                        >
                            <RadioGroup.Label as="p">
                                <span className="flex justify-between">
                                    <span>{size.label ?? size.default_label}</span>
                                    <span>{estimatedPrices[size.value_index] ? estimatedPrices[size.value_index] + globalState.groupOrder.currency_symbol : t('free')}</span>
                                </span>
                            </RadioGroup.Label>
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
}
