import React from "react";
import { CART_STEPS } from "../constants/cartSteps";
import useGlobal from "../state/useGlobal";

export default function CartSteps() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { checkout } = globalState;
  const { currentStep } = checkout;

  const steps = CART_STEPS.map((cartStep, index) => {
    return {
      name: cartStep,
      status:
        CART_STEPS.indexOf(currentStep) > index
          ? "complete"
          : cartStep === currentStep
          ? "current"
          : "upcoming",
    };
  });

  return (
    <nav className="flex items-center justify-center">
      <ol className="flex items-center space-x-8">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === "complete" ? (
              <div className="block w-3 h-3 rounded-full bg-celadon-green-dark-500 hover:bg-celadon-green-dark-900">
                <span className="sr-only">{step.name}</span>
              </div>
            ) : step.status === "current" ? (
              <div
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
                  <span className="w-full h-full rounded-full bg-celadon-green-dark-200" />
                </span>
                <span
                  className="relative block w-3 h-3 rounded-full bg-celadon-green-dark-500"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </div>
            ) : (
              <div className="block w-3 h-3 bg-gray-200 rounded-full hover:bg-gray-400">
                <span className="sr-only">{step.name}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
