import React from "react";
import {useTranslation} from "react-i18next";
import {PencilIcon} from "@heroicons/react/solid";
import {toMoney} from "../../../../services/money";
import useGlobal from "../../../../state/useGlobal";

export default function Gravur({value, item}) {
    const [,globalActions] = useGlobal();
    const {t} = useTranslation();
    // Find product_custom_options sku = "option_gravur_sku"
    const product_custom_options = item.product.product_custom_options;
    const gravur = product_custom_options.find(
        (option) => option.sku === "option_gravur_sku"
    );

    if (!gravur) {
       return null;
    }

    if (value === "") {
        return (
            <p onClick={() => globalActions.openGravurModal(item.product, value, 'edit')} className="mt-1 text-xs text-gunmetal-black-500 cursor-pointer">
                <span className="italic">{t("⚡ Add engraving")}: +{toMoney(gravur.price)} </span>
                <span className="font-medium"></span>
            </p>
        )
    }

    return (
        <p onClick={() => globalActions.openGravurModal(item.product, value, 'edit')} className="mt-1 text-xs text-gunmetal-black-500 cursor-pointer">
            <span className="uppercase ">{t("Engraving")}: </span>{" "}
            <span className="font-medium mr-1">{value}</span><PencilIcon className="w-3 h-3 inline"/>
        </p>
    );
}
