import React from "react";
import ConfProduct from "../../../components/DetailListView/ConfProduct";
import SimpleProduct from "../../../components/DetailListView/SimpleProduct";
import { PRODUCT_TYPE_CONFIGURABLE } from "../../../constants/common";

export default function UpSellSection({ groupOrder, name, refSection }) {
  const { up_sell } = groupOrder;

  if (up_sell && up_sell?.items.length > 0)
    return (
      <div className="upsell-section" ref={refSection}>
        <h3 className="pt-6 text-lg font-medium md:text-xl text-gunmetal-black-500">{name}</h3>
        <div className="grid grid-cols-1 py-4 gap-y-4 md:gap-y-6 lg:gap-y-8">
          {up_sell?.items &&
            up_sell?.items.map((productUpSell) =>
              productUpSell.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                <ConfProduct
                  key={productUpSell.product_id}
                  item={productUpSell}
                />
              ) : (
                <SimpleProduct
                  key={productUpSell.product_id}
                  item={productUpSell}
                />
              )
            )}
        </div>
      </div>
    );

  return "";
}
