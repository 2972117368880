import React from "react";
import ConfProduct from "../../../components/DetailListView/ConfProduct";
import SimpleProduct from "../../../components/DetailListView/SimpleProduct";
import { PRODUCT_TYPE_CONFIGURABLE } from "../../../constants/common";

export default function SchoolbookSection({ groupOrder, name, refSection }) {
  const { items } = groupOrder;
  const schoolbookItems = items.filter((item) => item.is_school_book);

  if (schoolbookItems.length > 0)
    return (
      <div className="schoolbook-section" ref={refSection}>
        <h3 className="pt-6 text-lg font-medium md:text-xl text-gunmetal-black-500">{name}</h3>
        <div className="grid grid-cols-1 py-4 gap-y-4 md:gap-y-6 lg:gap-y-8">
          {schoolbookItems.map((productSchoolbook) =>
            productSchoolbook.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
              <ConfProduct
                key={productSchoolbook.product_id}
                item={productSchoolbook}
              />
            ) : (
              <SimpleProduct
                key={productSchoolbook.product_id}
                item={productSchoolbook}
              />
            )
          )}
        </div>
      </div>
    );

  return "";
}
