import { TrendingUpIcon, ThumbUpIcon } from "@heroicons/react/outline";
import CalcusoIcon from "../components/Icons/CalcusoIcon";
import EmptyIcon from "../components/Icons/EmptyIcon";
import SustainableIcon from "../components/Icons/SustainableIcon";
import ReuseIcon from "../components/Icons/ReuseIcon";
import FireIcon from "../components/Icons/FireIcon";
import StarIcon from "../components/Icons/StarIcon";

const prod = {
  icon: {
    174463: <FireIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // One in two buys this!
    174472: <EmptyIcon className="h-6"/>, // SALE %
    174464: <StarIcon className="-ml-1 mr-1.5 h-6 w-6" />, // Very popular among parents
    174470: <EmptyIcon className="h-6"/>, // Popular Product
    174465: <TrendingUpIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // X% of our customer bought this product
    174467: <EmptyIcon className="h-6"/>, // Best Seller
    174466: <ThumbUpIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // recommended
    174469: <EmptyIcon className="h-6"/>, // Teachers's Choice
    174468: <CalcusoIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // CALCUSO's Choice
    174462: <SustainableIcon className="-ml-1 mr-1.5 h-6 w-6" />, // Sustainable product
    174473: <ReuseIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // Reuse Product
  },
  color: {
    174463: "bg-fiery-rose-500", // One in two buys this!
    174472: "bg-fiery-rose-500", // SALE %
    174464: "bg-melon-500", // Very popular among parents
    174470: "bg-melon-500", // Popular Product
    174465: "bg-maccaroni-500", // X% of our customer bought this product
    174467: "bg-maccaroni-500", // Best Seller
    174466: "bg-crayola-500", // recommended
    174469: "bg-gunmetal-black-500", // Teachers's Choice
    174468: "bg-gunmetal-black-500", // CALCUSO's Choice
    174462: "bg-celadon-green-light-500", // Sustainable product
    174473: "bg-celadon-green-light-500", // Reuse Product
  },
  colorText: {
    174463: "text-white", // One in two buys this!
    174472: "text-white", // SALE %
    174464: "text-gunmetal-black-500", // Very popular among parents
    174470: "text-gunmetal-black-500", // Popular Product
    174465: "text-gunmetal-black-500", // X% of our customer bought this product
    174467: "text-gunmetal-black-500", // Best Seller
    174466: "text-gunmetal-black-500", // recommended
    174469: "text-white", // Teachers's Choice
    174468: "text-white", // CALCUSO's Choice
    174462: "text-gunmetal-black-500", // Sustainable product
    174473: "text-gunmetal-black-500", // Reuse Product
  },
};

const dev = {
  icon: {
    174414: <FireIcon className="-ml-1 mr-1.5 h-6 w-6" />, // Hot. Jeder 2. kauft dies
    174412: <SustainableIcon className="-ml-1 mr-1.5 h-6 w-6" />, // Sustainable product
    174413: <StarIcon className="-ml-1 mr-1.5 h-6 w-6" />, // Very popular among parents
    174415: <TrendingUpIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // 25% of our customer bought this product
    174416: <FireIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // One in two buys this!
    174417: <EmptyIcon className="h-6"/>, // SALE %
    174418: <EmptyIcon className="h-6"/>, // Popular Product
    174420: <EmptyIcon className="h-6"/>, // Best Seller
    174421: <ThumbUpIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // recommended
    174422: <EmptyIcon className="h-6"/>, // Teachers's Choice
    174423: <CalcusoIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // CALCUSO's Choice
    174424: <ReuseIcon className="-ml-1 mr-1.5 h-6 w-6"/>, // Reuse Product
  },
  color: {
    174414: "bg-fiery-rose-500", // Hot. Jeder 2. kauft dies
    174412: "bg-celadon-green-light-500", // Sustainable product
    174413: "bg-melon-500", // Very popular among parents
    174415: "bg-maccaroni-500", // 25% of our customer bought this product
    174416: "bg-fiery-rose-500", // One in two buys this!
    174417: "bg-fiery-rose-500", // SALE %
    174418: "bg-melon-500", // Popular Product
    174420: "bg-maccaroni-500", // Best Seller
    174421: "bg-crayola-500", // recommended
    174422: "bg-gunmetal-black-500", // Teachers's Choice
    174423: "bg-gunmetal-black-500", // CALCUSO's Choice
    174424: "bg-celadon-green-light-500", // Reuse Product
  },
  colorText: {
    174414: "text-white",
    174412: "text-gunmetal-black-500",
    174413: "text-gunmetal-black-500",
    174415: "text-gunmetal-black-500",
    174416: "text-white", // One in two buys this!
    174417: "text-white", // SALE %
    174418: "text-gunmetal-black-500", // Popular Product
    174420: "text-gunmetal-black-500", // Best Seller
    174421: "text-gunmetal-black-500", // recommended
    174422: "text-white", // Teachers's Choice
    174423: "text-white", // CALCUSO's Choice
    174424: "text-gunmetal-black-500", // Reuse Product
  },
};

export default process.env.REACT_APP_STAGE === "prod" ? prod : dev;
