export const DE = "de";
export const FR = "fr";
export const PT = "pt";
export const EN = "en";
export const NL = "nl";
export const IT = "it";

export const STORE = {
  1: DE,
  4: FR,
  2: PT,
  5: EN,
  6: NL,
  3: IT,
};
