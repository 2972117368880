import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";
import i18n from "./i18n";
import history from "./routes/history";

const cartErrorMessages = [
    "Kein Eintrag für %fieldName = %fieldValue",
    "Pas d’entité ayant %fieldName = %fieldValue",
    "No such entity with %fieldName = %fieldValue",
    "Nenhum entidade com %fieldName = %fieldValue"
];

const paymentErrorMessages = [
    "The payment is REFUSED.",
    "Die Zahlung wird VERWEIGERT.",
    "Le paiement est REFUSÉ.",
    "O pagamento é RECUSADO.",
    "Il pagamento è RIFIUTATO.",
    "Payment has been cancelled",
    "Payment details call failed"
];

const ERROR_EMAIL = 'Invalid email format';
const ERROR_ADDRESS = 'The address failed to save. Verify the address and try again.';

export const HandleAPICartError = (error, globalState) => {
    try {
        const errorMessage = error?.data?.message;

        if (errorMessage) {
            for (const cartErrorMessage of cartErrorMessages) {
                if (errorMessage.indexOf(cartErrorMessage) === 0) {
                    toast.error(i18n.t("Your cart has expired. Please restart the ordering process."));
                    return;
                }
            }

            for (const paymentErrorMessage of paymentErrorMessages) {
                if (errorMessage.indexOf(paymentErrorMessage) === 0) {
                    toast.error(i18n.t("The payment was refused. Please try again."));
                    return;
                }
            }

            // eslint-disable-next-line
            switch (errorMessage) {
                case ERROR_EMAIL:
                    toast.error(i18n.t("Invalid email format"));
                    return;
                case ERROR_ADDRESS:
                    toast.error(i18n.t("The address failed to save. Verify the address and try again."));
                    return;
            }

            toast.error(i18n.t("Something went wrong"));
            Sentry.setUser(globalState);
            Sentry.setExtra('error_message', error?.data?.message || '');
            Sentry.setTag('error_message', error?.data?.message || '');
            Sentry.captureException(new Error("Something went wrong with API call"));
        }
    } catch (e) {
        Sentry.captureException(new Error("Something went wrong with API call"));
    }
};

export const HandleError = (error) => {
    if (typeof error === 'object' && 'status' in error) {
        const {status} = error;

        if (status === 404) {
            history.push("/");
        }
    }
}
