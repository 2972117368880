'use client'

import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useGlobal from "../../../../state/useGlobal";
import {BadgeCheckIcon} from "@heroicons/react/solid";

export default function AllRelatedPackages({item, relatedPackage, style = false}) {
    const [globalState, globalActions] = useGlobal();
    const {fakeCart} = globalState;
    const {relatedPackageSelected} = fakeCart;
    const [select, setSelect] = useState(relatedPackageSelected !== null);
    const {t} = useTranslation();

    useEffect(() => {
        setSelect(relatedPackageSelected !== null);
    }, [relatedPackageSelected]);
    const handleSelect = () => {
        if (select === false) {
            globalActions.addRelatedBundlePackage(relatedPackage);
            setSelect(true);
        } else {
            globalActions.removeRelatedBundlePackage(relatedPackage);
            setSelect(false);
        }
    }
    return (
        <div
            className="cursor-pointer rounded-xl p-1 mx-auto relative"
            onClick={() => handleSelect()}
            style={{
                background: 'linear-gradient(90deg, rgb(255 33 33) 0%, rgb(52 225 137) 100%)',
            }}>
            {(select ? <BadgeCheckIcon
                className="w-8 h-8 -right-4 -top-4 absolute text-cal-secondary-fiery-rose-f74e6c"
                aria-hidden="true"
            /> : null)}
            <button
                className="inline-flex w-full justify-center rounded-lg bg-black px-3 py-2 text-sm font-semibold text-white sm:col-start-1 sm:mt-0"
            >
                {t("Select all products from the parent letter - Get {{discount}}% discount on accessories", {
                    discount: relatedPackage.percentage_discount
                  })
                }
            </button>
        </div>
    )
}
