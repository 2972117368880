import * as React from "react";

const EmptyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    width={0}
    height={24}
    {...props}
  ></svg>
);

export default EmptyIcon;
