export const initialState = {
    multiBancoResponse: {}
};

export const setResponseForMultiBanco = (store, response) => {
    store.setState(
        {
            multiBancoResponse: {
                response,
            },
        },
        () => localStorage.setItem("multiBancoResponse", JSON.stringify(store.state.multiBancoResponse))
    );
}