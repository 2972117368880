import React from "react";
import { useTranslation } from "react-i18next";
import useGlobal from "../state/useGlobal";
import Config from "../config";

export default function Footer() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { store } = globalState;
  const { t } = useTranslation();
  let links = Config.links[store] || {
    LEGAL_NOTICE: "https://www.calcuso.de/impressum/",
    PRIVACY: "https://www.calcuso.de/datenschutz",
    TERMS_OF_USE: "https://www.calcuso.de/agb",
  };

  return (
    <div className="flex items-center w-full px-4 py-8 mx-auto sm:px-6 lg:px-8">
      <div className="flex space-x-8 text-sm">
        <a
          href={links.LEGAL_NOTICE}
          target="_blank"
          rel="noreferrer"
          className="text-celadon-green-600"
        >
          {t("Legal notice")}
        </a>
        <a
          href={links.PRIVACY}
          target="_blank"
          rel="noreferrer"
          className="text-celadon-green-600"
        >
          {t("Privacy Notice")}
        </a>
        <a
          href={links.TERMS_OF_USE}
          target="_blank"
          rel="noreferrer"
          className="text-celadon-green-600"
        >
          {t("Terms of use")}
        </a>
      </div>
    </div>
  );
}
