import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import useGlobal from "../../state/useGlobal";
import {XIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import {IS_GRAVIUR, IS_NOT_GRAVIUR, REGEX_GRAVIUR, REGEX_GRAVIUR_REPLACE,} from "../../constants/common";
import {Formik} from "formik";
import * as Yup from "yup";
import Button from "../Button";
import SecondaryButton from "../SecondaryButton";
import BigNumber from "bignumber.js";
import * as Sentry from "@sentry/react";
import InputGravur from "./InputGravur"
import classNames from "classnames";
import {toMoney} from "../../services/money";


export default function GravurModal() {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const {gravurModal, fakeCart} = globalState;
    const {open, product, gravurText} = gravurModal;
    const {items} = fakeCart;
    const {product_simples, product_custom_options} = product;
    const selectedItem = items[product?.product_id];

    const gravur = product_custom_options?.find(
        (option) => option.sku === "option_gravur_sku"
    );
    const getFinalPrice = (isGravur) => {
        const {options} = selectedItem;
        const childProduct = product_simples.find((product_simple) => {
            let find = true;
            for (
                let index = 0;
                index < product_simple.product_custom_attributes.length;
                index++
            ) {
                const element = product_simple.product_custom_attributes[index];
                if (element.value !== options[element.attribute_code]) {
                    return false;
                }
            }

            return find;
        });

        if (!childProduct) {
            Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
            Sentry.setUser({product, globalState});
            Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
            return false;
        }

        if (isGravur === IS_GRAVIUR) {
            // Gravur
            const gravurOption = product_custom_options
                ? product.product_custom_options.find(
                    (custom_option) => custom_option.type === "field"
                )
                : undefined;

            const price = new BigNumber(childProduct.product_price).plus(
                new BigNumber(gravurOption?.price)
            );
            return price.toFormat(2).toString();
        }

        const price = new BigNumber(childProduct.product_price);
        return price.toFormat(2).toString();
    };

    function handleNoEngraving() {
        const price = getFinalPrice(IS_NOT_GRAVIUR);
        globalActions.addOptionsFromCart(
            selectedItem?.id,
            price,
            {
                gravur: '',
                is_graviur: IS_NOT_GRAVIUR,
            }
        );
        globalActions.setGravurTextGravurModal('');
        globalActions.closeGravurModal().then();
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 z-10 overflow-y-auto"
                open={open}
                onClose={() => {
                }}
            >
                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className={classNames({
                            "inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl bg-cal-primary-floral-white-FEF9EF sm:my-8 sm:align-middle sm:p-6 max-w-2xl": true,
                        })}>
                            <div className="absolute top-0 right-0 block pt-4 pr-4">
                                <button
                                    type="button"
                                    className="text-gray-400 rounded-md bg-cal-primary-floral-white-FEF9EF hover:text-gray-500"
                                    onClick={globalActions.closeGravurModal}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon
                                        className="w-8 h-8 text-cal-primary-gunmetal-black-202833"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            {(gravurText === '' || gravurModal.actionFrom !== 'auto') ? (
                                <div className="min-w-full px-2 py-8 m-auto space-y-8">
                                    <div className="text-center">
                                        <p className="text-base font-medium text-fiery-rose-500 md:text-lg">
                                            {product.name}
                                        </p>
                                        <p className="text-base font-medium text-gunmetal-black-500 md:text-lg">
                                            {t("Are you sure you don't want an engraving?")}
                                        </p>
                                        <p className="items-center text-base font-medium text-gunmetal-black-500 md:text-lg">
                                            {t("It protects against theft and looks great.")}
                                        </p>
                                    </div>
                                    <div className="max-w-lg px-4 mx-auto sm:px-6 lg:px-2">
                                        <Formik
                                            initialTouched={{
                                                gravur: gravurText ? true : false,
                                            }}
                                            validateOnMount
                                            initialValues={{
                                                gravur: gravurText,
                                            }}
                                            validationSchema={Yup.lazy((values) => {
                                                const val = values.gravur
                                                    ? values.gravur.replace(
                                                        new RegExp(REGEX_GRAVIUR_REPLACE),
                                                        ""
                                                    )
                                                    : "";

                                                return Yup.object().shape({
                                                    gravur: Yup.string()
                                                        .required(t("This is a mandatory field"))
                                                        .max(22)
                                                        .matches(
                                                            REGEX_GRAVIUR,
                                                            t(
                                                                "Please remove invalid characters {{invalid}} .",
                                                                {
                                                                    invalid: val,
                                                                }
                                                            )
                                                        ),
                                                });
                                            })}
                                            onSubmit={(values) => {
                                                const price = getFinalPrice(IS_GRAVIUR);

                                                globalActions.addOptionsFromCart(
                                                    selectedItem?.id,
                                                    price,
                                                    {
                                                        gravur: values.gravur,
                                                        is_graviur: values.gravur
                                                            ? IS_GRAVIUR
                                                            : IS_NOT_GRAVIUR,
                                                    }
                                                );
                                                globalActions.setGravurTextGravurModal(values.gravur);
                                                globalActions.closeGravurModal().then(() => {
                                                    // setTimeout(() => {
                                                    //     globalActions.goToNextStepConfigurable(globalActions.getSortCartItemsInPackageOnly(), selectedItem.product);
                                                    // }, 200);
                                                });
                                            }}
                                            validateOnChange
                                        >
                                            {({
                                                  errors,
                                                  touched,
                                                  handleSubmit,
                                                  values,
                                                  handleChange,
                                                  handleBlur,
                                                  isValid,
                                              }) => (
                                                <form
                                                    className="flex flex-col items-start w-full space-y-8"
                                                    onSubmit={handleSubmit}
                                                >
                                                    <InputGravur
                                                        name="gravur"
                                                        label={t("⚡ Add engraving") + " +" + toMoney(gravur?.price)}
                                                        placeholder={t("Enter desired text")}
                                                        size="lg"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.gravur}
                                                        required
                                                        error={touched.gravur && errors.gravur}
                                                        errorMessage={errors.gravur}
                                                    />

                                                    <div className="flex flex-col items-start w-full space-y-4">
                                                        <Button
                                                            id="submit-gravur-button"
                                                            type="submit"
                                                            color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                                            size="xl"
                                                            full={true}
                                                            disabled={!isValid}
                                                        >
                                                            {t("add")}
                                                        </Button>
                                                        <SecondaryButton
                                                            type="button"
                                                            color="text-gunmetal-black-700 bg-transparent border-gunmetal-black-300 hover:border-gunmetal-black-500 hover:text-gunmetal-black-900 focus:ring-gunmetal-black-500"
                                                            size="lg"
                                                            full={true}
                                                            onClick={() => handleNoEngraving()}
                                                        >
                                                            <span>{t("further without engraving")}</span>
                                                        </SecondaryButton>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
