import axios from "axios";
import qs from "qs";
import Config from "../config";

const instanceAdyen = axios.create({
  baseURL: Config.adyenURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  transformResponse: (data) => (data ? JSON.parse(data) : undefined),
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
});

const METHODS = ["get", "put", "post", "patch", "delete", "head", "options"];

const httpMethodAyden = METHODS.map(
    (method) => async (url, headers = {}, data, params) => {
      // const token = localStorage.getItem("token");

      return instanceAdyen.request({
        url: url,
        method,
        //   headers: { Authorization: `Bearer ${token}`, ...headers },
        headers,
        data,
        params,
      });
    }
);

export const [get, put, post, patch, del, head, options] = httpMethodAyden;

const apiAdyen = {
  get,
  put,
  post,
  patch,
  del,
  head,
  options,
};

export default apiAdyen;
