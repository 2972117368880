export const initialState = {
  open: false,
  cart: {},
  schoolCode: '',
};

export const setCartModal = (store, cartModal) => {
  store.setState({ cartModal });
};

export const setCartDataModal = (store, cart) => {
  store.setState({
    cartModal: {
      ...store.state.cartModal,
      cart
    },
  });
};

export const openCartModal = (store) => {
  store.setState({
    cartModal: {
      open: true,
    },
  });
};

export const closeCartModal = (store) => {
  store.setState({
    cartModal: {
      ...store.state.cartModal,
      open: false,
    },
  });
};
