import React, { useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import useGlobal from "../../../../state/useGlobal";
import BigNumber from "bignumber.js";
import { IS_GRAVIUR } from "../../../../constants/common";
import * as Sentry from "@sentry/react";

export default function SelectStyleTag({ option, formik, item }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { fakeCart } = globalState;
  const { items } = fakeCart;
  const { product_simples } = item;
  const selectedItem = items[item.product_id];
  const { attribute_options, attribute_code } = option;
  const { t } = useTranslation();
  const { values, setFieldValue } = formik;

  const getFinalPrice = () => {
    const product = product_simples.find((product_simple) => {
      let find = true;
      for (
        let index = 0;
        index < product_simple.product_custom_attributes.length;
        index++
      ) {
        const element = product_simple.product_custom_attributes[index];
        if (element.value !== values[element.attribute_code]) {
          return false;
        }
      }

      return find;
    });

    if (!product) {
      Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
      Sentry.setUser({ item, globalState });
      Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
      return false;
    }

    if (values.is_graviur === IS_GRAVIUR) {
      // Gravur
      const gravurOption = item.product_custom_options.find(
        (custom_option) => custom_option.type === "field"
      );
      const price = new BigNumber(product.product_price).plus(
        new BigNumber(gravurOption.price)
      );
      return price.toFormat(2).toString();
    }

    const price = new BigNumber(product.product_price);
    return price.toFormat(2).toString();
  };

  useEffect(() => {
    // Set To Cart If Exists
    if (selectedItem) {
      const price = getFinalPrice();
      globalActions.addOptionsFromCart(selectedItem?.id, price, {
        [attribute_code]: values[attribute_code],
      });
    }
  }, [values[attribute_code]]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col items-center justify-center space-y-2">
      <h4 className="text-sm font-medium text-gunmetal-black-500">
        {attribute_options.length} {t("Options available")}
      </h4>
      <RadioGroup
        value={values[attribute_code]}
        onChange={(selectedColor) => {
          // Set Formik data
          setFieldValue(attribute_code, selectedColor);
        }}
        className="w-full"
      >
        <div className="flex items-center justify-center w-full space-x-4 md:flex-wrap-reverse">
          {attribute_options.map((size) => {
            return (
              <RadioGroup.Option
                key={size.value_index}
                value={size.value_index}
                className={({ active, checked }) =>
                  classNames({
                    "border-teal-700": checked,
                    "border-zinc-400": !checked,
                    "flex items-center justify-center rounded-md py-2 px-2 text-sm font-semibold sm:flex-1 cursor-pointer border-2 rounded-[12px]": true,
                  })
                }
              >
                <RadioGroup.Label as="p">
                  {size.label ?? size.default_label}
                </RadioGroup.Label>
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>
    </div>
  );
}
