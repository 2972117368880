import * as React from "react";
import MBWay_Logo from "../images/mbway.png";

function MBWayLogo(props) {
  return (
    <img src={MBWay_Logo} alt="MBWay" {...props} />
  );
}

export default MBWayLogo;
