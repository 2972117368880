import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import useGlobal from "../../state/useGlobal";
import {
  SHIPPING_METHOD_DESCRIPTION,
  SHIPPING_METHOD_LABEL,
  SHIPPING_METHOD_TO_SCHOOL,
} from "../../constants/shippingMethod";
import * as dayjs from "dayjs";
import toast from "react-hot-toast";
import BigNumber from "bignumber.js";
import { STATUS_EXPIRED } from "../../state/groupOrder";
import Config from "../../config";

export default function ShippingMethods({ values }) {
  const { t } = useTranslation();
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder, checkout } = globalState;
  const {
    shipping_init,
    isEstimatedShipping,
    start_ship_out,
    school,
    teacher,
    status,
    store,
  } = groupOrder;
  const { shippingMethodCode } = checkout;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      !shippingMethodCode ||
      !shipping_init.some((e) => e.method_code === shippingMethodCode)
    ) {
      const defaultShippingMethods = shipping_init
        .sort((a, b) => a.amount - b.amount)
        .filter((s) => s.available !== false);
      const [firstShippingMethod] = defaultShippingMethods;

      if (firstShippingMethod?.method_code === SHIPPING_METHOD_TO_SCHOOL) {
        globalActions.setCheckout({
          is_same_shipping: true,
          shipping: {
            firstname: teacher?.first_name,
            lastname: teacher?.last_name,
            company: school?.name,
            street: school?.shipping_street,
            city: school?.shipping_city,
            postcode: school?.shipping_zip,
            country_id: school?.shipping_country_code,
            vat_id: "",
          },
          billing: {
            city: "",
            country_id: Config.defaultCountry.hasOwnProperty(store.id)
              ? Config.defaultCountry[store.id]
              : "DE",
            firstname: "",
            lastname: "",
            postcode: "",
            street: "",
            company: "",
            vat_id: "",
          },
          shippingMethodCode: firstShippingMethod?.method_code,
          shippingCarrierCode: firstShippingMethod?.carrier_code,
        });
      } else {
        globalActions.setCheckout({
          is_same_shipping: true,
          shipping: {
            firstname: "",
            lastname: "",
            street: "",
            city: "",
            postcode: "",
            country_id: Config.defaultCountry.hasOwnProperty(store.id)
              ? Config.defaultCountry[store.id]
              : "DE",
            company: "",
            vat_id: "",
          },
          billing: {
            firstname: "",
            lastname: "",
            street: "",
            city: "",
            postcode: "",
            country_id: Config.defaultCountry.hasOwnProperty(store.id)
              ? Config.defaultCountry[store.id]
              : "DE",
            company: "",
            vat_id: "",
          },
          shippingMethodCode: firstShippingMethod?.method_code,
          shippingCarrierCode: firstShippingMethod?.carrier_code,
        });
      }
    }
  }, [
    globalActions,
    shippingMethodCode,
    shipping_init,
    school,
    teacher,
    store.id,
  ]);

  useEffect(() => {
    if (isEstimatedShipping) {
      setLoading(false);
    }
  }, [isEstimatedShipping]);

  return (
    <>
      <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
        {t("Where should we send the order?")}
      </h3>
      <RadioGroup
        className="border-t-2 border-celadon-green-light-500"
        value={shippingMethodCode}
        onChange={(selected) => {
          if (school) {
            if (SHIPPING_METHOD_TO_SCHOOL === selected) {
              globalActions.setCheckout({
                email: values.email,
                is_same_shipping: false,
                shipping: {
                  firstname: teacher?.first_name,
                  lastname: teacher?.last_name,
                  company: school?.name,
                  street: school?.shipping_street,
                  city: school?.shipping_city,
                  postcode: school?.shipping_zip,
                  country_id: school?.shipping_country_code,
                  vat_id: "",
                },
                billing: {
                  city: "",
                  country_id: Config.defaultCountry.hasOwnProperty(store.id)
                    ? Config.defaultCountry[store.id]
                    : "DE",
                  firstname: "",
                  lastname: "",
                  postcode: "",
                  street: "",
                  company: "",
                  vat_id: "",
                },
                shippingMethodCode: selected,
              });
            } else {
              globalActions.setCheckout({
                email: values.email,
                is_same_shipping: true,
                shipping: {
                  firstname: "",
                  lastname: "",
                  street: "",
                  city: "",
                  postcode: "",
                  country_id: Config.defaultCountry.hasOwnProperty(store.id)
                    ? Config.defaultCountry[store.id]
                    : "DE",
                  company: "",
                  vat_id: "",
                },
                billing: {
                  firstname: "",
                  lastname: "",
                  street: "",
                  city: "",
                  postcode: "",
                  country_id: Config.defaultCountry.hasOwnProperty(store.id)
                    ? Config.defaultCountry[store.id]
                    : "DE",
                  company: "",
                  vat_id: "",
                },
                shippingMethodCode: selected,
              });
            }
          } else {
            toast.error("The school information was empty.");
          }
        }}
      >
        <div className="relative -space-y-px divide-y-2 divide-celadon-green-light-500">
          {shipping_init
            .sort((a, b) => a.amount - b.amount)
            .filter((s) => s.available !== false)
            .map((shippingMethod) => (
              <RadioGroup.Option
                key={shippingMethod.method_code}
                value={shippingMethod.method_code}
                className={({ checked }) =>
                  classNames({
                    "border-gray-200": !checked,
                    "relative p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 focus:outline-none": true,
                  })
                }
              >
                {({ active, checked }) => (
                  <div className="flex space-x-4">
                    <div>
                      <span
                        className={classNames({
                          " border-gunmetal-black-500": checked,
                          " border-gunmetal-black-500 ": !checked,
                          "ring-2 ring-offset-2 ring-celadon-green-500": active,
                          "h-8 w-8 bg-floral-white-500 rounded-full border-2 flex items-center justify-center": true,
                        })}
                        aria-hidden="true"
                      >
                        <span
                          className={classNames({
                            "bg-celadon-green-600": checked,
                            "w-5 h-5 rounded-full ": true,
                          })}
                        />
                      </span>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <RadioGroup.Label
                        as="span"
                        className={classNames({
                          "text-lg font-medium text-gunmetal-black-500": true,
                        })}
                      >
                        {t(SHIPPING_METHOD_LABEL[shippingMethod.method_code])}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        className={classNames({
                          "text-sm md:ml-0 md:pl-0  text-gunmetal-black-500": true,
                        })}
                      >
                        {t(
                          SHIPPING_METHOD_DESCRIPTION[
                            shippingMethod.method_code
                          ],
                          {
                            deadline: dayjs(start_ship_out).format(
                              "DD.MM.YYYY"
                            ),
                          }
                        )}
                      </RadioGroup.Description>
                      {status !== STATUS_EXPIRED ? (
                        <RadioGroup.Description as="div">
                          {loading ? (
                            <div className="flex space-x-4 animate-pulse">
                              <div className="flex-1 py-1 space-y-2">
                                <div className="h-4 rounded bg-floral-white-dark-500 "></div>
                              </div>
                            </div>
                          ) : (
                            <span
                              className={classNames({
                                "text-lg font-medium text-gunmetal-black-500": true,
                              })}
                            >
                              {shippingMethod.amount > 0
                                ? "+ " +
                                  new BigNumber(shippingMethod.amount)
                                    .toFormat(2)
                                    .toString() +
                                  " €"
                                : t("free")}
                            </span>
                          )}
                        </RadioGroup.Description>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
        </div>
      </RadioGroup>
    </>
  );
}
