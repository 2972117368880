import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useGlobal from "../../state/useGlobal";

export default function ShippingFee({ values }) {
  const { t } = useTranslation();
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { checkout, groupOrder } = globalState;
  const [loading, setLoading] = useState(true);
  const { shippingMethodCode } = checkout;
  const { shipping_init } = groupOrder;

  const shippingMethod = shipping_init.find(
    (shipping) => shipping.method_code === shippingMethodCode
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const payload = {
        address: {
          ...values.shipping,
          street: [values.shipping.street],
          same_as_billing: 1,
        },
      };
      await globalActions.loadShippingMethods(payload);
      setLoading(false);
    }
    fetchData();
  }, [values.shipping]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <div className="w-full pb-4 border-b-2 border-celadon-green-light-500">
          <div className="flex space-x-4 animate-pulse">
            <div className="flex-1 py-1 space-y-2">
              <div className="h-4 rounded bg-floral-white-dark-500 "></div>
              <div className="h-4 rounded bg-floral-white-dark-500"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between pb-4 border-b-2 border-celadon-green-light-500 ">
          <p className="font-medium ">{t("Shipping costs")}</p>
          <p className="font-medium text-right">
            {shippingMethod?.amount > 0
              ? "+ " +
                new BigNumber(shippingMethod.amount).toFormat(2).toString() +
                " €"
              : t("free")}
          </p>
        </div>
      )}
    </>
  );
}
