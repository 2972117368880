import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import logo from "./logo.svg";
import "../../tailwind.output.css";

const logoClasses = (size) =>
  classNames({
    "w-96": !size || size === "xl",
    "w-48": size === "lg",
    "w-32": size === "base",
    "w-24": size === "md",
    "w-16": size === "sm",
    "fill-current animate-spin-slow": true,
  });

const Logo = ({ size }) => {
  return (
    <img src={logo} className={logoClasses(size)} alt="Logo" />
  );
};

Logo.protoTypes = {
  size: PropTypes.string,
};
export default Logo;
