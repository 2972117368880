import Button from "../../../components/Button";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ShippingStep({
  school_code,
  isValid,
  isSubmitLoading,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-end space-x-8">
      <Link
        to={`/group-orders/${school_code.trim()}/preview`}
        className="font-medium cursor-pointer text-gunmetal-black-500"
      >
        {t("back")}
      </Link>
      <Button
        id="shipping-step-next-button"
        type="submit"
        color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
        size="xl"
        // disabled={!isValid}
        loading={isSubmitLoading}
      >
        <span>{t("next")}</span>
        <ArrowRightIcon className="w-5 h-5 ml-3 -mr-1 " aria-hidden="true" />
      </Button>
    </div>
  );
}
