import React from "react";

export default function Loading() {
  return (
    <div className="w-full p-4">
      <div className="flex space-x-4 animate-pulse">
        <div className="flex-1 py-1 space-y-2">
          <div className="h-4 rounded bg-floral-white-dark-500 "></div>
          <div className="h-4 rounded bg-floral-white-dark-500"></div>
          <div className="h-4 rounded bg-floral-white-dark-500"></div>
          <div className="h-4 rounded bg-floral-white-dark-500"></div>
        </div>
      </div>
    </div>
  );
}
