import BigNumber from "bignumber.js";
import React, {useEffect, useState} from "react";
import {IS_GRAVIUR, LIMIT_CART_CALCULATOR} from "../../../constants/common";
import useGlobal from "../../../state/useGlobal";
import {MinusIcon, PlusIcon, PlusSmIcon} from "@heroicons/react/outline";
import SecondaryButton from "../../SecondaryButton";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import * as Sentry from "@sentry/react";

export default function PriceContainer({ formik, item }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { fakeCart, groupOrder } = globalState;
  const { calculator } = groupOrder;
  const { values } = formik;
  const { product_simples } = item;
  // get From Cart
  const { items } = fakeCart;
  const selectedItem = items[item.product_id];

  const [price, setPrice] = useState("");
  const [unSupported, setUnSupported] = useState(false);
  const [simpleProduct, setSimpleProduct] = useState(null);

  useEffect(() => {
    const getFinalPrice = (lastValues) => {
      const product = product_simples.find((product_simple) => {
        let find = true;
        for (
          let index = 0;
          index < product_simple.product_custom_attributes.length;
          index++
        ) {
          const element = product_simple.product_custom_attributes[index];
          if (element.value !== lastValues[element.attribute_code]) {
            return false;
          }
        }

        return find;
      });

      if (!product) {
        // If Product In Cart
        if (selectedItem) {
          return new BigNumber(selectedItem.price).toFormat(2).toString();
        }

        setUnSupported(true);
        Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
        Sentry.setUser({ item, globalState });
        Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
        return;
      }

      setSimpleProduct(product);

      // If Product In Cart
      if (selectedItem) {
        return new BigNumber(selectedItem.price).toFormat(2).toString();
      }

      if (lastValues.is_graviur === IS_GRAVIUR) {
        // Gravur
        const gravurOption = item.product_custom_options.find(
          (custom_option) => custom_option.type === "field"
        );
        const price = new BigNumber(product.product_price).plus(
          new BigNumber(gravurOption.price)
        );
        return price.toFormat(2).toString();
      }

      const price = new BigNumber(product.product_price);
      return price.toFormat(2).toString();
    };

    setPrice(getFinalPrice(values));
  }, [values, selectedItem?.price]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnAdd = async () => {
    if (selectedItem?.id === calculator?.product_id) {
      if (selectedItem?.qty < LIMIT_CART_CALCULATOR) {
        globalActions.addToCart(selectedItem);
      }
    } else {
      globalActions.addToCart(selectedItem);
    }
  };

    const handleOnRemove = async () => {
        globalActions.minusFromCart(selectedItem)
    };
  return (
    <div className="w-full pt-4 lg:pt-0">
      {unSupported ? (
        <p>{t("Unsupported")}</p>
      ) : (
        <div className="flex items-center justify-between space-x-2 md:space-x-4 lg:justify-end">
          <div className="text-left lg:text-right ">
            <h2 className="text-lg font-medium md:text-xl text-cal-primary-gunmetal-black-202833">
              {new BigNumber(price).toFormat(2).toString()} €
            </h2>
            {simpleProduct &&
              simpleProduct.tier_prices.map((tier_price) => (
                <p
                  key={tier_price.price_id}
                  className="text-xs text-fiery-rose-500"
                >
                  {t("from {{qty}} piece {{price}} €", {
                    qty: Math.floor(tier_price.price_qty),
                    price: new BigNumber(tier_price.price).toString(),
                  })}
                </p>
              ))}
          </div>
          {selectedItem ? (
            <span className="relative z-0 inline-flex rounded-lg shadow-sm">
              <button
                type="button"
                disabled={selectedItem?.canMinus}
                onClick={handleOnRemove}
                className={classNames({
                  "relative disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center px-4 py-2 text-sm font-medium border rounded-l-lg border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none": true,
                })}
              >
                <MinusIcon className="w-4 h-4" />
              </button>
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none "
              >
                {selectedItem.qty}
              </button>
              <button
                type="button"
                disabled={(selectedItem?.id === calculator?.product_id && selectedItem?.qty >= LIMIT_CART_CALCULATOR) ? true : false }
                onClick={handleOnAdd}
                className={classNames({
                  "relative disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border rounded-r-lg border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none": true,
                })}
              >
                <PlusIcon className="w-4 h-4" />
              </button>
            </span>
          ) : (
            <SecondaryButton
              type="submit"
              color="text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500"
              size="lg"
            >
              <PlusSmIcon
                className="w-5 h-5 mr-3 -ml-1 text-celadon-green-700"
                aria-hidden="true"
              />
              <span>{t("add")}</span>
            </SecondaryButton>
          )}
        </div>
      )}
    </div>
  );
}
