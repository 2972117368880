import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../../tailwind.output.css";
import ConfirmModal from "../ConfirmModal";
import ConfirmModalRemoveItemIfItemInPackage from "../ConfirmModalRemoveItemIfItemInPackage";

const AppLayout = ({
  textColor,
  backgroundColor,
  mdBackgroundColor,
  contentBackgroundColor,
  border = true,
  children,
}) => {
  return (
    <>
      {border ? (
        <div
          className={classNames({
            "text-gunmetal-black": !textColor,
            [`${textColor}`]: textColor,
            "bg-cal-primary-floral-white-FEF9EF": !backgroundColor,
            [`${backgroundColor}`]: backgroundColor,
            "md:bg-cal-primary-celadon-green-008E8D": !mdBackgroundColor,
            [`md:${mdBackgroundColor}`]: mdBackgroundColor,
            "min-h-screen": true,
          })}
        >
          <main>
            <div className="px-4 py-4 mx-auto">
              <div
                className={classNames({
                  "md:bg-floral-white-500": !contentBackgroundColor,
                  [`md:${contentBackgroundColor}`]: contentBackgroundColor,
                  "px-3 py-4 md:px-5 md:py-6 lg:px-10 lg:py-8 md:rounded-xl md:shadow min-h-screen": true,
                })}
              >
                {children}
                <ConfirmModal />
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div
          className={classNames({
            "text-gunmetal-black": !textColor,
            [`${textColor}`]: textColor,
            "bg-cal-primary-floral-white-FEF9EF": !contentBackgroundColor,
            [`${contentBackgroundColor}`]: contentBackgroundColor,
            "min-h-screen": true,
          })}
        >
          {children}
            <ConfirmModal/>
            <ConfirmModalRemoveItemIfItemInPackage/>
        </div>
      )}
    </>
  );
};

AppLayout.protoTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  mdBackgroundColor: PropTypes.string,
  contentBackgroundColor: PropTypes.string,
  border: PropTypes.bool,
};
export default AppLayout;
