import * as React from "react";
import IDeal_Logo from "../images/ideal.png";

function iDeal(props) {
    return (
        <img src={IDeal_Logo} alt="iDeal" {...props} />
    );
}

export default iDeal;
