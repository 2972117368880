import React from "react";
import useGlobal from "../../state/useGlobal";
import {useTranslation} from "react-i18next";
import BigNumber from "bignumber.js";
import {hasPaymentFee, calculatePaymentFee} from "./functions/PaymentFeeHelper";

export default function PaymentFeeTitle(props) {
    const [globalState] = useGlobal();
    const {groupOrder, checkout} = globalState;
    const {t} = useTranslation();
    const {configs} = groupOrder;
    const {totals} = checkout;

    const totalFeePayment = () => {
        let total = totals?.base_grand_total;
        let paymentFee = calculatePaymentFee(configs, props.paymentCode, total);

        return new BigNumber(paymentFee)
            .toFormat(2)
            .toString() + '€';
    }

    return (
        <>
            {hasPaymentFee(configs, props.paymentCode) &&
                <div className="pl-0.5"> (+{totalFeePayment()} {t('fee')}) </div>
            }
        </>
    );

}
