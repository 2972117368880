import React from "react";
import {PRODUCT_TYPE_CONFIGURABLE} from "../../../constants/common";
import ConfProduct from "../../../components/DetailListView/ConfProduct";
import SimpleProduct from "../../../components/DetailListView/SimpleProduct";
import AllRelatedPackages from "./RelatedPackageType/AllRelatedPackages";
import ConfigRelatedPackages from "./RelatedPackageType/ConfigRelatedPackages";

export default function CalculatorSection({item, name, refSection}) {
    let relatedPackages = item?.related_package;
    const isAllRelatedPackage = relatedPackages?.length === 1 && relatedPackages[0].package_type === 'all';
    const isMultipleRelatedPackage = relatedPackages?.length > 1 && relatedPackages.filter((packed) => packed.package_type !== 'all').length > 1;

    if (item)
        return (
            <div className="calculator-section" ref={refSection}>
                <h3 className="pt-6 text-lg font-medium md:text-xl text-gunmetal-black-500">{name}</h3>
                <div className="grid grid-cols-1 py-4 gap-y-4 md:gap-y-6 lg:gap-y-8">
                    {!isMultipleRelatedPackage ? (
                        (<div className="space-y-8">
                            {isAllRelatedPackage ? <AllRelatedPackages item={item} relatedPackage={item?.related_package[0]}/> : ''}
                            {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                <ConfProduct item={item}/>
                            ) : (
                                ""
                            )}
                            {item?.product_related &&
                                item.product_related.map((productRelated) =>
                                    productRelated.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                        <ConfProduct
                                            key={productRelated.product_id}
                                            item={productRelated}
                                        />
                                    ) : (
                                        <SimpleProduct
                                            key={productRelated.product_id}
                                            item={productRelated}
                                        />
                                    )
                                )}
                        </div>)
                    ) : <ConfigRelatedPackages key="ConfigRelatedPackages" item={item} relatedPackages={relatedPackages}/>}
                </div>
            </div>
        );

    return "";
}
