import React, {useEffect, useState} from "react";
import FakeCartItemConf from "../../components/FakeCartModal/FakeCartItemConf";
import FakeCartItem from "../../components/FakeCartModal/FakeCartItem";
import {PRODUCT_TYPE_CONFIGURABLE,} from "../../constants/common";
import useGlobal from "../../state/useGlobal";
import {useTranslation} from "react-i18next";
import ProductPackageCartPreview from "../../components/ProductPackageCartPreview";

export default function PreviewPackage() {
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const [itemsNotPackage, setItemsNotPackage] = useState([]);
    useEffect(() => {
        setItemsNotPackage(globalActions.getSortCartItemsNotInPackageOnly());
    }, [globalActions, globalState.fakeCart]);
    const {fakeCart} = globalState;

    if (fakeCart?.relatedPackageSelected !== null && fakeCart?.relatedPackageSelected?.package_type !== 'all') {
        return (
            <div className="min-w-full m-auto">
                <div className="flow-root">
                    <ul className="-my-6 divide-y-2 divide-floral-white-500">
                        <ProductPackageCartPreview/>
                        {itemsNotPackage.length > 0 ? (
                            itemsNotPackage.map((item) =>
                                item.type === PRODUCT_TYPE_CONFIGURABLE ? (
                                    <FakeCartItemConf key={item.id} item={item}/>
                                ) : (
                                    <FakeCartItem key={item.id} item={item}/>
                                )
                            )
                        ) : ('')}
                        {globalActions.getSortCartItems().length === 0 ? (<li className="flex items-center justify-center py-6">
                            <p>{t("No items")}</p>
                        </li>) : ''}
                    </ul>
                </div>
            </div>
        );
    }

    return (<div></div>);

}
