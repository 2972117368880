export const SHIP_TO_SCHOOL_METHOD = "1";
export const SHIP_TO_PRIVATE_METHOD = "2";

export const SHIPPING_METHOD_TO_SCHOOL = "toSchool";
export const SHIPPING_METHOD_TO_PRIVATE = "toPrivate";

export const SHIPPING_METHOD_LABEL = {
  [SHIPPING_METHOD_TO_SCHOOL]: "Ship to school",
  [SHIPPING_METHOD_TO_PRIVATE]: "Ship to private",
};

export const SHIPPING_METHOD_DESCRIPTION = {
  [SHIPPING_METHOD_TO_SCHOOL]:
    "Your order will be sent to the school on {{deadline}} and distributed to the students by the teacher.",
  [SHIPPING_METHOD_TO_PRIVATE]:
    "We will send your order to your address immediately.",
};
