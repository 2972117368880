import React from "react";
import {toMoney} from "../../services/money";
import {useTranslation} from "react-i18next";
import {CheckCircleIcon} from "@heroicons/react/solid";

export default function CartPreviewFromQuote({totals}) {
    const {t} = useTranslation();
    const packageSelected = totals?.extension_attributes?.related_config;
    const havePackageInQuote = packageSelected && packageSelected.version === 'package';

    let totalItemsNotInPackage = totals?.items;
    let fakeProduct = {}

    if (havePackageInQuote) {
        // Build fulfillment items
        let fulfillmentItemId = packageSelected.related_ids.map(Number);
        fulfillmentItemId.push(packageSelected.product_id);
        fulfillmentItemId = fulfillmentItemId.map(Number);
        totalItemsNotInPackage = totals?.items.filter((item) => !fulfillmentItemId.includes(parseInt(item.extension_attributes.product_id)));

        // Build fake product for package
        let items = totals.items.filter((item) => fulfillmentItemId.includes(parseInt(item.extension_attributes.product_id)));
        let totalPriceOfItems = items.reduce((total, item) => total + item.row_total_incl_tax, 0);
        fakeProduct = {
            name: t(packageSelected.package_label),
            qty: items[0].qty,
            items: items,
            row_total_incl_tax: totalPriceOfItems,
        }
    }

    return (
        <>
            {fakeProduct?.name ? (<li
                key="package"
                className="py-4 text-sm"
            >
                <div className="flex flex-col space-y-2">
                    <p className="font-medium truncate">
                        {fakeProduct.name}
                    </p>
                    <div className="flex items-center justify-between">
                        <p>
                            {t("{{qty}} piece", {
                                qty: fakeProduct.qty,
                            })}
                        </p>
                        <p className="font-medium">
                            {toMoney(fakeProduct.row_total_incl_tax)}
                        </p>
                    </div>
                </div>
                <ul>
                    {fakeProduct.items.map((item) => (
                        <li
                            key={item.item_id}
                            className="py-2 text-sm"
                        >
                            <div className="flex flex-col space-y-2 justify-center">
                                <p className="font-medium truncate">
                                    <CheckCircleIcon className="w-4 h-4 text-celadon-green-900 inline mr-2"/>
                                    {item.name}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            </li>) : ('')}
            {totalItemsNotInPackage.map((item) => (
                <li
                    key={item.item_id}
                    className="py-4 text-sm"
                >
                    <div className="flex flex-col space-y-2">
                        <p className="font-medium truncate">
                            {item.name}
                        </p>
                        <div className="flex items-center justify-between">
                            <p>
                                {t("{{qty}} piece", {
                                    qty: item.qty,
                                })}
                            </p>
                            <p className="font-medium">
                                {toMoney(item.row_total_incl_tax)}
                            </p>
                        </div>
                    </div>
                </li>
            ))}
        </>
    );
}
