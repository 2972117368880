export const STEP_CHECKOUT_LOGIN = "STEP_CHECKOUT_LOGIN";
export const STEP_CHECKOUT_ADDRESS = "STEP_CHECKOUT_ADDRESS";
export const STEP_CHECKOUT_PAYMENT = "STEP_CHECKOUT_PAYMENT";
export const STEP_CHECKOUT_CONFIRM = "STEP_CHECKOUT_CONFIRM";
export const STEP_CHECKOUT_DONE = "STEP_CHECKOUT_DONE";

export const CHECKOUT_STEPS = [
  // STEP_CHECKOUT_LOGIN,
  STEP_CHECKOUT_ADDRESS,
  STEP_CHECKOUT_PAYMENT,
  // STEP_CHECKOUT_CONFIRM,
  STEP_CHECKOUT_DONE,
];

export const CHECKOUT_STEPS_LABEL = {
  STEP_CHECKOUT_LOGIN: "Login",
  STEP_CHECKOUT_ADDRESS: "Address",
  STEP_CHECKOUT_PAYMENT: "Check and Pay",
  // STEP_CHECKOUT_CONFIRM: "Confirm",
  STEP_CHECKOUT_DONE: "Done!",
};

export const CHECKOUT_STEPS_URL = {
  STEP_CHECKOUT_LOGIN: "login",
  STEP_CHECKOUT_ADDRESS: "address",
  STEP_CHECKOUT_PAYMENT: "payment",
  // STEP_CHECKOUT_CONFIRM: "confirm",
  STEP_CHECKOUT_DONE: "done",
};
