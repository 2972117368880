import React from "react";
import Input from "../../../components/Forms/Input";
import {COUNTRIES, COUNTRIES_NL, OPTIONAL_ZIP_COUNTRIES} from "../../../constants/common";
import Select from "../../../components/Forms/Select/Select";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import ShippingFee from "../ShippingFee";
import {NL, PT} from "../../../constants/storeCode";
import ShippingStep from "./ShippingStep";

export default function ShipToSchool({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  isValid,
  setValues,
  isSubmitLoading,
}) {
  const { t } = useTranslation();
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder } = globalState;
  const { school, school_code, store } = groupOrder;

  return (
    <>
      <div className="py-4 space-y-2 border-t-2 border-b-2 border-celadon-green-light-500">
        <p className="text-lg font-medium text-gunmetal-black-500">
          {t("Delivery address")}
        </p>
        <div className="flex flex-col ">
          <span className="text-sm text-gunmetal-black-500">
            {school?.name}
          </span>
          <span className="text-sm text-gunmetal-black-500">
            {school?.shipping_street}
          </span>
          <span className="text-sm text-gunmetal-black-500">
            {school?.shipping_zip} {school?.shipping_city}
          </span>
          <span className="text-sm text-gunmetal-black-500">
            {t(school?.shipping_country)}
          </span>
        </div>
        <div className="space-y-2 ">
          {errors?.shipping &&
            Object.keys(errors?.shipping).map((e) => (
              <p className="text-sm text-fiery-rose-500" key={e}>{errors?.shipping[e]}</p>
            ))}
        </div>
      </div>
      <div className="pb-6 space-y-4 border-b-2 border-celadon-green-light-500">
        <p className="text-lg font-medium text-gunmetal-black-500">
          {t("Billing address")}
        </p>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <Input
              name="billing.firstname"
              label={t("First Name")}
              placeholder={t("First Name")}
              size="lg"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.billing.firstname}
              required
              error={errors?.billing?.firstname}
              errorMessage={errors?.billing?.firstname}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <Input
              name="billing.lastname"
              label={t("Last Name")}
              placeholder={t("Last Name")}
              size="lg"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.billing.lastname}
              required
              error={errors?.billing?.lastname}
              errorMessage={errors?.billing?.lastname}
            />
          </div>

          <div className="col-span-6">
            <Input
              name="billing.street"
              label={t("Street Address")}
              placeholder={t("Street Address")}
              size="lg"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.billing.street}
              required
              error={errors?.billing?.street}
              errorMessage={errors?.billing?.street}
            />
          </div>

          <div className="col-span-6">
            <Input
              name="billing.company"
              label={t("Additional info")}
              placeholder={t("Additional info")}
              size="lg"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.billing.company}
              error={errors?.billing?.company}
              errorMessage={errors?.billing?.company}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              name="billing.postcode"
              label={t("ZIP / Postal")}
              placeholder={t("ZIP / Postal")}
              size="lg"
              onChange={e => {
                handleChange(e);
                setValues(
                    (prevValues) => ({
                      ...prevValues,
                      billing: {
                        ...values.billing,
                        postcode: e.target.value.trim(),
                      },
                    }),
                    true
                )
              }}
              onBlur={handleBlur}
              value={values.billing.postcode}
              required={
                !OPTIONAL_ZIP_COUNTRIES.includes(values.billing.country_id)
              }
              error={errors?.billing?.postcode}
              errorMessage={errors?.billing?.postcode}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              name="billing.city"
              label={t("City")}
              placeholder={t("City")}
              size="lg"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.billing.city}
              required
              error={errors?.billing?.city}
              errorMessage={errors?.billing?.city}
            />
          </div>


          <div className="col-span-6 sm:col-span-3">
            {store && store?.code === NL ? (
                <Select
                    name="billing.country_id"
                    label={t("Country")}
                    options={COUNTRIES_NL}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.billing.country_id}
                    required
                    error={errors?.billing?.country_id}
                    errorMessage={errors?.billing?.country_id}
                />
            ) : (
                <Select
                    name="billing.country_id"
                    label={t("Country")}
                    options={COUNTRIES}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.billing.country_id}
                    required
                    error={errors?.billing?.country_id}
                    errorMessage={errors?.billing?.country_id}
                />
            )}
          </div>
          {store && store?.code === PT ? (
            <div className="col-span-6 sm:col-span-3">
              <Input
                name="billing.vat_id"
                label={t("VAT")}
                placeholder={t("VAT")}
                size="lg"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billing.vat_id}
                error={errors?.billing?.vat_id}
                errorMessage={errors?.billing?.vat_id}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <ShippingFee values={values} />
      <ShippingStep
        school_code={school_code}
        isSubmitLoading={isSubmitLoading}
        isValid={isValid}
      />
    </>
  );
}
