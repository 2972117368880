export const initialState = {
  open: false,
  action: false,
};

export const setConfirmModal = (store, confirmModal) => {
  store.setState({ confirmModal });
};

export const openConfirmModal = (store, callback) => {
  store.setState({
    confirmModal: {
      open: true,
      action: callback,
    },
  });
};

export const closeConfirmModal = (store) => {
  store.setState({ confirmModal: initialState });
};
