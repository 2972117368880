import api from "../services/api";
import history from "../routes/history";
import {HandleAPICartError} from "../helpers";

export const initialState = {
  items: [],
  items_qty: 0,
  subtotal_incl_tax: 0,
};

export const setCart = (store, cart) => {
  store.setState({
    cart,
  });
};

export const loadCart = async (store) => {
  try {
    const { checkout } = store.state;
    const { quote_id } = checkout;
    // Load async data.
    let cartData = await api.get(`/guest-carts/${quote_id}/totals`);

    const { data } = cartData;

    store.setState({
      cart: data,
    });
  } catch (error) {
    HandleAPICartError(error, store.state);
    history.push("/");
  }
};
