import React from "react";
import useGlobal from "../state/useGlobal";
import {useTranslation} from "react-i18next";
import {PRODUCT_TYPE_CONFIGURABLE} from "../constants/common";
import PackageFakeCartItemConf from "./FakeCartModal/FakeCartItemConf/PackageFakeCartItemConf";
import PackageFakeCartItemSimple from "./FakeCartModal/PackageFakeCartItemSimple";
import CartActionPlusMinus from "./CartActionPlusMinus";
import {toMoney} from "../services/money";

export default function ProductPackageCartPreview() {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {items, relatedPackageSelected} = globalState.fakeCart;
    const {t} = useTranslation();
    // Calculate the total price of the package
    let total = 0;
    let totalRaw = 0;
    // Get calculated price of the package
    let groupOrder = globalState.groupOrder;
    let calculator = groupOrder.calculator;

    // Check existing item in cart
    let calculatorInCart = items[calculator.product_id];
    if (calculatorInCart) {
        total = calculatorInCart.price * calculatorInCart.qty;
        totalRaw = calculatorInCart.price * calculatorInCart.qty;
        // Get related items in the package
        globalActions.getSortCartItemsInPackageOnly().filter((i) => {
            return i.id !== calculator.product_id;
        }).forEach((item) => {
            total += item.price * item.qty * (1 - relatedPackageSelected.percentage_discount / 100);
            totalRaw += item.price * item.qty;
        })
    }


    return (
        <div className="border border-cal-primary-celadon-green-00B5B1 p-5 rounded-2xl mb-7">
            {relatedPackageSelected ? (<div className="flex mb-5">
                <div className="flex flex-shrink-0 w-16 overflow-hidden md:w-24 md:h-24 items-center justify-center">
                    <img alt={relatedPackageSelected?.name} src={relatedPackageSelected.imageUrl}/>
                </div>
                <div className="flex flex-col flex-1 ml-4 space-y-1">
                    <div className="font-bold">{t(relatedPackageSelected?.package_label)}</div>
                    <div className="text-xs text-cal-secondary-fiery-rose-f74e6c hidden md:block">{t("You save {{percentage_discount}}% on accessories", {
                        "percentage_discount": relatedPackageSelected?.percentage_discount
                    })}</div>
                    <div className="flex justify-between">
                        <div className="text-base font-medium flex flex-col md:flex-row justify-center items-center">
                            <span className="text-cal-secondary-fiery-rose-f74e6c">{toMoney(total)}</span>
                            <del className="ml-2 text-xs font-normal">{toMoney(totalRaw)}</del>
                        </div>
                        <div><CartActionPlusMinus item={relatedPackageSelected}/></div>
                    </div>
                </div>
            </div>) : null}
            <div>
                {globalActions.getSortCartItemsInPackageOnly().map((item) =>
                    item.type === PRODUCT_TYPE_CONFIGURABLE ? (
                        <PackageFakeCartItemConf key={item.id} item={item}/>
                    ) : (
                        <PackageFakeCartItemSimple key={item.id} item={item}/>
                    )
                )}
            </div>
        </div>
    );
}
