import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useGlobal from "../../state/useGlobal";
import { XIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import CartItem from "./CartItem";
import BigNumber from "bignumber.js";

export default function CartModal() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { cartModal, cart } = globalState;
  const { open } = cartModal;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10"
        open={open}
        onClose={globalActions.closeCartModal}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full h-screen px-4 pb-4 overflow-auto text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl max-w-prose sm:my-8 sm:align-middle sm:px-6">
              <div className="sticky top-0 z-50 block py-4 bg-white md:pt-6 md:pb-4">
                <div className="flex items-center justify-between">
                  <Dialog.Title className="flex-1 text-xl font-medium text-center ">
                    {t("Cart")}
                  </Dialog.Title>
                  <div className="flex justify-end ">
                    <button
                      type="button"
                      className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3"
                      onClick={globalActions.closeCartModal}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon
                        className="w-8 h-8 text-cal-primary-gunmetal-black-202833"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {cart ? (
                <>
                  <div className="min-w-full px-2 pb-8 m-auto md:p-8 md:pt-0">
                    <div className="mt-8">
                      <div className="flow-root">
                        <ul className="-my-6 divide-y divide-gray-200">
                          {cart?.items && cart?.items.length > 0 ? (
                            cart.items.map((product) => (
                              <CartItem
                                key={product.item_id}
                                product={product}
                              />
                            ))
                          ) : (
                            <li className="flex items-center justify-center py-6">
                              <p>{t("No items")}</p>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 pt-6 pb-12 border-t-2 border-gunmetal-black-500 sm:px-6">
                    <div className="flex justify-between text-xl font-medium text-gunmetal-black-500">
                      <p>{t("Total")}</p>
                      <p>
                        {new BigNumber(cart.subtotal_incl_tax)
                          .toFormat(2)
                          .toString()}{" "}
                        €
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
