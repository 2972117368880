import React from "react";
import ProductImage from "../../components/ProductImage";
import { InformationCircleIcon } from "@heroicons/react/outline";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import useGlobal from "../../state/useGlobal";
import {
  PRODUCT_TYPE_CONFIGURABLE,
} from "../../constants/common";
import history from "../../routes/history";
import BigNumber from "bignumber.js";
import Badge from "../../components/Badge";
import BADGE_CONSTANTS from "../../constants/badge";
import EmptyIcon from "../../components/Icons/EmptyIcon";

export default function Product({ product }) {
  const { t } = useTranslation();
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder } = globalState;
  const { school_code } = groupOrder;

  const handleSelectCalculator = () => {
    globalActions.setGroupOrder({
      calculator: product,
    });
    globalActions.initFakeCart()
    history.push(`/group-orders/${school_code.trim()}/products`);
  };

  return (
    <li className="relative flex flex-col col-span-1 text-center bg-white rounded-lg shadow">
      <span
        onClick={() => globalActions.openProductModal(product)}
        className="absolute block w-5 h-5 transform translate-x-1/2 -translate-y-1/2 rounded-full cursor-pointer right-8 top-8 md:w-8 md:h-8 ring-2 ring-white bg-cal-primary-floral-white-F5F0E1"
      >
        <InformationCircleIcon className="w-5 h-5 md:w-8 md:h-8 text-cal-primary-gunmetal-black-202833" />
      </span>
      <div className="flex flex-col flex-1 p-4 space-y-4 md:p-8 justify-center">
        <div
          className="w-full cursor-pointer flex justify-center"
          onClick={() => globalActions.openProductModal(product)}
        >
          <ProductImage
            size="h-32 w-32"
            src={product.product_image}
            alt={product.name}
          />
        </div>
        <div className="flex-1 space-x-2 space-y-2">
          {product.product_tags.map((product_tag) => (
            <Badge
              key={product_tag.tag_value}
              color={BADGE_CONSTANTS.color[product_tag.tag_value]}
              colorText={BADGE_CONSTANTS.colorText[product_tag.tag_value]}
            >
              {BADGE_CONSTANTS.icon[product_tag.tag_value] ? BADGE_CONSTANTS.icon[product_tag.tag_value] : <EmptyIcon className="h-6"/>}
              <span>{product_tag.tag_name}</span>
            </Badge>
          ))}
        </div>
        <div className="space-y-4 ">
          <div className="space-y-1 ">
            <h3 className="font-medium text-cal-primary-gunmetal-black-202833">
              {product.name}
            </h3>
            <dl className="flex flex-col justify-between flex-grow">
              {product.brand ? (
                <dd className="text-xs md:text-sm text-cal-primary-gunmetal-black-989898">
                  {t("from")}{" "}
                  <span className="uppercase ">{product.brand}</span>
                </dd>
              ) : (
                <dd className="text-xs md:text-sm text-cal-primary-gunmetal-black-989898"></dd>
              )}
            </dl>
            <h2 className="text-base font-medium md:text-lg text-cal-primary-gunmetal-black-202833">
              {new BigNumber(product.product_price).toFormat(2).toString()} €
            </h2>
          </div>
          <div>
            {product.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
              <Button
                type="button"
                color="bg-celadon-green-600 hover:bg-celadon-green-700 focus:ring-celadon-green-500 select-product"
                size="xl"
                fontSize="text-sm"
                font="font-normal"
                full={true}
                onClick={handleSelectCalculator}
              >
                {t("select")}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
