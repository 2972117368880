import React, { Component, Fragment } from "react";
import "@adyen/adyen-web/dist/adyen.css";
import AdyenCheckout from "@adyen/adyen-web";
import Button from "../../../../components/Button";
import toast from "react-hot-toast";
import api from "../../../../services/api";
import history from "../../../../routes/history";
import {withTranslation} from "react-i18next";
import {Dialog, Transition} from '@headlessui/react'
import {HandleAPICartError} from "../../../../helpers";
import {getDefaultConfigAdyen} from "../adyenHelper";
import PaymentFeeMessage from "../../PaymentFeeMessage";

class CreditCard extends Component {
    constructor(props) {
        super(props);
        this.paymentContainer = React.createRef();
        this._3dsContainer = React.createRef();

        this.state = {
            loading: false,
            adyenCC: null,
            orderId: null,
            open: false,
            checkoutAyden: null,
            action: null,
            adyenCheckoutComponent: null
        }
    }

     closeModal = () => {
        this.setState({ open: false});
    }

    handleTransitionEnd = () => {
        this.state.checkoutAyden.createFromAction(this.state.action).mount(this._3dsContainer.current);
    }

    handleAdditionalDetails = async (state) => {
        let self = this;
        const { globalState, globalActions } = this.props;
        const groupOrder = globalState.groupOrder;

        this.setState({loading: true});
        this.closeModal();

        let payloadStateData = {
            orderId: self.state.orderId,
            details: state.data.details
        }

        const payload = {
            payload: JSON.stringify(payloadStateData),
        };

        try {
            let response = await api.post(
                `/calcuso-group-order/adyen/orders/paymentDetails`,
                {},
                payload
            );

            if (response.status === 200) {
                const data = JSON.parse(response.data);

                if (data.isFinal) {
                    globalActions.initCheckout();
                    globalActions.initGroupOrder();
                    globalActions.initFakeCart();
                    history.push(`/group-orders/${groupOrder.school_code.trim()}/checkout/done`);
                } else {
                    console.log(response.data);
                }
            }
        } catch (error) {
            HandleAPICartError(error, globalState);
        }
        this.setState({loading: false});
    }

    componentDidMount() {
        const { globalState } = this.props;

        const createCheckout = async () => {
            const configCredit = getDefaultConfigAdyen(globalState, {
                showPayButton: false,
                onAdditionalDetails: this.handleAdditionalDetails.bind(this),
                onSubmit: this.placeOrder.bind(this),
                onChange: (state, component) => {
                    this.setState({adyenCC: state});
                },
            });

            const checkout = await new AdyenCheckout(configCredit);
            let adyenCheckoutComponent = checkout.create('card').mount(this.paymentContainer.current);
            this.setState({checkoutAyden: checkout})
            this.setState({adyenCheckoutComponent: adyenCheckoutComponent})
            this.setState({reload: false});
        }

        createCheckout();
    }

    placeOrder = async () => {
        const { t } = this.props;
        const { adyenCC } = this.state;
        const { globalState, globalActions } = this.props;
        const checkout = globalState.checkout;
        const groupOrder = globalState.groupOrder;

        this.setState({loading: true})

        try {
            if (!adyenCC.isValid) {
                this.state.adyenCheckoutComponent.showValidation();
                this.setState({loading: false})
                return;
            }

            const payload = {
                email: checkout.email,
                paymentMethod: {
                    additional_data: {
                        stateData: JSON.stringify(adyenCC.data),
                        guestEmail: checkout.email,
                        cc_type: adyenCC.data.paymentMethod.brand,
                        combo_card_type: 'credit',
                        is_active_payment_token_enabler: false,
                        number_of_installments: ""
                    },
                    method: "adyen_cc"
                }
            };

            let response = await api.post(
                `/guest-carts/${checkout.quote_id}/payment-information`,
                {},
                payload
            );

            let formData = new FormData();
            formData.append('order_id', response.data);
            this.setState({ orderId: response.data});

            if (response.status === 200) {
                let response = await api.post(
                    `/calcuso-group-order/adyen/orders/payment-status`,
                    {},
                    formData
                );
                let data = JSON.parse(response.data);

                if (data.isFinal) {
                    globalActions.initCheckout();
                    globalActions.initGroupOrder();
                    globalActions.initFakeCart();
                    history.push(`/group-orders/${groupOrder.school_code.trim()}/checkout/done`);
                } else {
                    if (data.action.type === "threeDS2") {
                        this.setState({ action: data.action});
                        this.setState({ open: true});
                    }
                }
            } else {
                toast.error(t(response.data.message));
            }
        } catch (error) {
            this.setState({reload: true})
            HandleAPICartError(error, globalState);
        }
        this.setState({loading: false})
    }

    render() {
        const { loading, open } = this.state;
        const { t } = this.props;

        return (
            <div>
                <div className="mt-8" >
                    <div ref={this.paymentContainer}> </div>
                    <div className="flex items-center justify-between pt-8 space-x-8">
                        <div>
                            <PaymentFeeMessage name={"Adyen"}/>
                        </div>
                        <Button
                            onClick={this.placeOrder}
                            type="button"
                            color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                            size="xl"
                            loading={loading}
                        >
                            <span>{t("Place your order")}</span>
                        </Button>
                    </div>
                </div>
                <>
                    <Transition appear show={open} onTransitionEnd={this.handleTransitionEnd} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={() => ({})}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex items-center justify-center min-h-full p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                            {/*<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">*/}
                                            {/*    <button*/}
                                            {/*        type="button"*/}
                                            {/*        className="text-gray-400 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3"*/}
                                            {/*        onClick={this.closeModal}*/}
                                            {/*    >*/}
                                            {/*        <span className="sr-only">Close</span>*/}
                                            {/*        <XIcon className="h-6 w-6" aria-hidden="true" />*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                            <div className="mt-2">
                                                <div ref={this._3dsContainer}> </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            </div>
        );
    }
}

export default (withTranslation()(CreditCard));

