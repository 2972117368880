import React from "react";
import useGlobal from "../../state/useGlobal";
import {useTranslation} from "react-i18next";
import {hasPaymentFee} from "./functions/PaymentFeeHelper";
export default function PaymentFeeMessage({name}) {
    const [globalState] = useGlobal();
    const {groupOrder, checkout} = globalState;
    const {t} = useTranslation();
    const {configs} = groupOrder;

    return (
        <>
            {hasPaymentFee(configs, checkout.payment) &&
                <div className="text-xs">
                    {t(`The fee is the fee incurred by {{name}}`, {
                        name: name
                    })}
                </div>
            }
        </>
    );

}
