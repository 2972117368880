import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";

import "./tailwind.output.css";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import Config from "./config";

Sentry.init({
    dsn: Config.sentryURl,
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'Object captured as exception with keys: config, data, headers, request, status'
    ]
});

ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred. Please restart page </p>}>
        <React.StrictMode>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </React.StrictMode>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
