import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import {Redirect, withRouter} from "react-router";
import {connect} from "../../state/useGlobal";
import Loading from "../../components/Loadingv2";
import {Helmet} from "react-helmet";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import CartSteps from "../../components/CartSteps";
import {PRODUCT_TYPE_CONFIGURABLE, REGEX_GRAVIUR,} from "../../constants/common";
import FakeCartModal from "../../components/FakeCartModal";
import {STEP_PREVIEW} from "../../constants/cartSteps";
import history from "../../routes/history";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";
import api from "../../services/api";
import GravurModal from "../../components/GravurModal";
import * as Yup from "yup";
import mixpanel from "mixpanel-browser";
import PreviewNormal from "./preview-normal";
import PreviewScreenStepNormal from "./step-normal";
import PreviewScreenStepPackage from "./step-package";
import PreviewPackage from "./preview-package";
import CartTotalSegment from "../../components/Cart/CartTotalSegment";
import ProductModal from "../../components/ProductModal";

class PreviewScreen extends Component {
    constructor(props) {
        super(props);
        const id = this.props.match.params.id;

        this.state = {
            isLoading: true,
            schoolCode: id,
            isCheckoutLoading: false,
        };
    }

    async componentDidMount() {
        const {globalState, globalActions, ga4} = this.props;
        const {groupOrder, fakeCart, gravurModal} = globalState;
        const {gravurText} = gravurModal;

        const {calculator} = groupOrder;
        const {schoolCode} = this.state;

        globalActions.setCheckout({
            currentStep: STEP_PREVIEW,
        });
        await this._loadGroupOrder();
        try {
            ga4.pageview('/calcuso-group-order-preview-cart', '/calcuso-group-order-preview-cart', 'Group Order | Cart preview');
            mixpanel.track_pageview({
                "page": "Group Order | Cart preview",
                "school_code": schoolCode,
            })
        } catch (e) {
            console.log(e);
        }
        // Check Graviur
        if (calculator) {
            const isValid = await Yup.string()
                .max(22)
                .matches(REGEX_GRAVIUR)
                .isValid(gravurText);

            const selectedCalculator = fakeCart.items[calculator.product_id];
            const isGravur =
                calculator?.product_custom_options &&
                calculator?.product_custom_options.some(
                    (custom_option) => custom_option.type === "field"
                );

            if ((selectedCalculator &&
                    isGravur &&
                    !selectedCalculator?.options?.gravur) ||
                (gravurText && !isValid)
            ) {
                setTimeout(() => {
                    globalActions.openGravurModal(calculator, selectedCalculator?.options?.gravur, 'auto');
                }, 1000)
            }
        }
    }

    _loadGroupOrder = async () => {
        const {globalActions, globalState} = this.props;
        const {schoolCode} = this.state;

        // Verify School Code
        const {groupOrder} = globalState;
        const {school_code} = groupOrder;
        if (school_code && school_code !== schoolCode) {
            history.push(`/group-orders/${schoolCode.trim()}`);
        }

        await globalActions.loadGroupOrder(schoolCode);
        this.setState({
            isLoading: false,
        });
    };

    handleCheckout = async () => {
        this.setState({
            isCheckoutLoading: true,
        });
        const {schoolCode} = this.state;
        const {globalState, t, globalActions} = this.props;
        const {fakeCart} = globalState;
        const {items, relatedPackageSelected} = fakeCart;
        try {
            const cartItems = Object.keys(items).map((key) => {
                const {type, product, options, qty} = items[key];
                if (type === PRODUCT_TYPE_CONFIGURABLE) {
                    const configurable_item_options = product.configurable_product_options.map(
                        (item) => {
                            return {
                                option_id: item.attribute_id,
                                option_value: options[item.attribute_code],
                            };
                        }
                    );

                    const custom_options = product.product_custom_options.map(
                        (product_custom_option) => {
                            return {
                                option_id: product_custom_option.option_id,
                                option_value: options?.gravur,
                            };
                        }
                    );

                    const payloadPost = {
                        sku: product.product_sku,
                        product_type: product.product_type,
                        qty: qty,
                        extension_attributes: {
                            code: schoolCode
                        },
                        product_option: {
                            extension_attributes: {
                                configurable_item_options,
                                custom_options,
                            },
                        },
                    };

                    return payloadPost;
                } else {
                    const payloadPost = {
                        sku: product.product_sku,
                        product_type: product.product_type,
                        qty: qty,
                        extension_attributes: {
                            code: schoolCode,
                        },
                    };

                    return payloadPost;
                }
            });

            let tokenData = await api.post(`/guest-carts/`);

            globalActions.setCheckout({
                quote_id: tokenData.data,
            });

            let relatedConfig = relatedPackageSelected
                ? (({relatedProducts, ...rest}) => rest)(relatedPackageSelected)
                : null;

            let cartData = await api.post(
                `/guest-carts/${tokenData.data}/items-group-order`,
                {},
                {
                    cartItems,
                    relatedPackageSelected: relatedConfig,
                }
            );

            const {data} = cartData;
            const {success, message} = data;
            if (!success) {
                toast.error(t(message));
                this.setState({
                    isCheckoutLoading: false,
                });
                return;
            }
            this.setState({
                isCheckoutLoading: false,
            });

            // Init Addresses
            globalActions.initAddresses();
            history.push(`/group-orders/${schoolCode.trim()}/checkout/address`);
        } catch (error) {
            toast.error(t("Something went wrong"));
            Sentry.setUser(globalState);
            Sentry.captureException(error);
            this.setState({
                isCheckoutLoading: false,
            });
        }
    };

    render() {
        const {t, globalState} = this.props;
        const {isLoading, schoolCode, isCheckoutLoading} = this.state;
        const {groupOrder} = globalState;
        const {status} = groupOrder;

        return (
            <AppLayout
                contentBackgroundColor="bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6"
                textColor="text-gunmetal-black"
                border={false}
            >
                {isLoading ? (
                    <Loading/>
                ) : (
                    <>
                        {status === STATUS_NOT_FOUND ? (
                            <Redirect to="/"/>
                        ) : (
                            <>
                                <Helmet>
                                    <title>
                                        {schoolCode} | {groupOrder?.school?.name} |{" "}
                                        {t("Group Order")}
                                    </title>
                                </Helmet>
                                <Header
                                    title={t("Group Order")}
                                    subTitle={groupOrder?.school?.name}
                                    withFakeCart
                                >
                                    <CartSteps/>
                                </Header>
                                <div className="max-w-2xl px-4 py-4 mx-auto space-y-4 md:py-8 sm:px-6 lg:max-w-3xl lg:px-8">
                                    <div className="w-full p-6 pb-20 space-y-8 bg-white rounded-lg relative">
                                        <h3 className="text-xl font-medium leading-6 text-center">
                                            {t("Cart")}
                                        </h3>
                                        <div>
                                            <PreviewPackage/>
                                            <PreviewNormal/>
                                        </div>
                                       <CartTotalSegment/>
                                    </div>
                                    <div className="fixed inset-x-0 bottom-0">
                                        <div className="h-24 border-t-2 bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6 border-floral-white-800">
                                            <div className="max-w-2xl px-4 py-4 mx-auto space-y-4 md:py-8 sm:px-6 lg:max-w-3xl lg:px-8">
                                                <PreviewScreenStepNormal schoolCode={groupOrder.school_code} handleCheckout={this.handleCheckout} isCheckoutLoading={isCheckoutLoading}/>
                                                <PreviewScreenStepPackage schoolCode={groupOrder.school_code} handleCheckout={this.handleCheckout} isCheckoutLoading={isCheckoutLoading}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                <FakeCartModal/>
                <GravurModal/>
                <ProductModal/>
            </AppLayout>
        );
    }
}

export default withRouter(connect(withTranslation()(PreviewScreen)));
