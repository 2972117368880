import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "../../tailwind.output.css";

const Badge = ({ color, colorText, children, ...props }) => {
  return (
    <span
      className={classNames({
        "bg-crayola-500": !color,
        [`${color}`]: color,
        "text-gunmetal-black-500": !colorText,
        [`${colorText}`]: colorText,
        "inline-flex items-center px-3 md:px-4 py-1 shadow-md rounded-full text-xs md:text-sm font-medium h-8 md:h-10": true,
      })}
    >
      {children}
    </span>
  );
};

Badge.protoTypes = {
  colorText: PropTypes.string,
  color: PropTypes.string,
};
export default Badge;
