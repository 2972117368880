import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import {Redirect, withRouter} from "react-router";
import {connect} from "../../state/useGlobal";
import Loading from "../../components/Loadingv2";
import Button from "../../components/Button";
import {Helmet} from "react-helmet";
import {STATUS_EXPIRED, STATUS_NOT_FOUND} from "../../state/groupOrder";
import history from "../../routes/history";
import CartSteps from "../../components/CartSteps";
import queryString from "query-string";
import {STORE as M2_STOTE} from "../../constants/storeCode";
import mixpanel from 'mixpanel-browser';

class GroupOrderScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      schoolCode: id,
    };
  }

  componentDidMount() {
    const { globalActions, i18n, ga4 } = this.props;
    const params = queryString.parse(this.props.location.search);

    if (M2_STOTE[params["store-id"]]) {
      globalActions.setStore(params["store-id"]);
      i18n.changeLanguage(M2_STOTE[params["store-id"]]);
    }

    this._loadGroupOrder();

    try {
      ga4.pageview('/calcuso-group-order-welcome-screen', '/calcuso-group-order-welcome-screen', 'Group Order | Welcome screen');
      const {schoolCode} = this.state;
      mixpanel.track_pageview({
        "page": "Group Order | Welcome screen",
        "school_code": schoolCode,
      })
    } catch (e) {
      console.log(e);
    }
  }

  _loadGroupOrder = async () => {
    const { globalActions } = this.props;
    const { schoolCode } = this.state;
    globalActions.initCheckout();
    globalActions.initGroupOrder();
    globalActions.initFakeCart();
    await globalActions.loadGroupOrder(schoolCode);
    this.setState({
      isLoading: false,
    });
  };

  handleNext = () => {
    const { schoolCode } = this.state;

    history.push(`/group-orders/${schoolCode.trim()}/student-information`);
  };

  render() {
    const { t, globalState } = this.props;
    const { isLoading, schoolCode } = this.state;
    const { groupOrder } = globalState;
    const { status } = groupOrder;

    return (
      <AppLayout
        contentBackgroundColor="bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6"
        textColor="text-gunmetal-black"
        border={false}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {status === STATUS_NOT_FOUND ? (
              <Redirect to="/" />
            ) : (
              <>
                <Helmet>
                  <title>
                    {schoolCode} | {groupOrder?.school?.name} |{" "}
                    {t("Group Order")}
                  </title>
                </Helmet>
                <div className="flex flex-col h-full min-h-screen">
                  <Header
                    title={t("Group Order")}
                    subTitle={groupOrder?.school?.name}
                  >
                    <CartSteps />
                  </Header>
                  <div className="flex flex-col justify-between flex-1 h-full">
                    <div className="flex items-center justify-center flex-1">
                      <div className="max-w-2xl px-4 mx-auto sm:px-6 lg:px-8">
                        <div className="px-6 lg:px-8">
                          <div className="flex flex-col items-center space-y-6 md:space-y-8 ">
                            <img
                              className="inline-block w-20 h-20 rounded-full md:w-24 md:h-24"
                              src={
                                groupOrder?.configs?.welcome_avatar
                                  ? groupOrder.configs.welcome_avatar
                                  : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                              }
                              alt=""
                            />
                            <h3 className="text-xl font-medium tracking-wide text-center md:text-2xl text-gunmetal-black-500">
                              {groupOrder?.configs?.welcome_message
                                ? groupOrder.configs.welcome_message
                                : t(
                                    "Hello, I am Edgar and I will guide you through the group order."
                                  )}
                            </h3>
                            {groupOrder.status === STATUS_EXPIRED ? (
                              <div className="flex flex-col space-y-2 ">
                                <h3 className="text-lg font-normal tracking-wide text-center md:text-xl text-fiery-rose-500">
                                  {t("The school code entered has expired.")}
                                </h3>
                                <h3 className="text-lg font-normal tracking-wide text-center md:text-xl text-gunmetal-black-500">
                                  {t(
                                    "You can still order! Your order will simply be delivered to your home."
                                  )}
                                </h3>
                              </div>
                            ) : (
                              ""
                            )}
                            <Button
                              type="button"
                              color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500 md:hidden"
                              size="xl"
                              full={true}
                              onClick={this.handleNext}
                            >
                              {t("next")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:flex md:flex-col md:items-center md:justify-center ">
                      <div className="w-full px-4 py-12 md:w-64 lg:w-64">
                        <Button
                          id="group-order-welcome-screen-next-button"
                          type="button"
                          color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                          size="xl"
                          full={true}
                          onClick={this.handleNext}
                        >
                          {t("next")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </AppLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(GroupOrderScreen)));
