import React from "react";
import Logo from "../../components/Logo";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "../../tailwind.output.css";
import FakeCart from "../FakeCart";
import Cart from "../Cart";
import useGlobal from "../../state/useGlobal";
import history from "../../routes/history";
import { useLocation } from "react-router";

const ConfirmLogo = ({ confirm, size }) => {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const location = useLocation();
  const { pathname } = location;

  const _handleConfirm = () => {
    const isCheckoutRoute = pathname.includes("/checkout/");
    if (isCheckoutRoute) {
      const { groupOrder } = globalState;
      const { school_code } = groupOrder;
      history.push(`/group-orders/${school_code}/preview`);
    } else {
      globalActions.openConfirmModal(() => {
        history.push(`/`);
        globalActions.closeConfirmModal();
      });
    }
  };

  return confirm ? (
    <div className="cursor-pointer " onClick={_handleConfirm}>
      <Logo size={size} />
    </div>
  ) : (
    <Link to="/">
      <Logo size={size} />
    </Link>
  );
};

const Header = ({
  title,
  subTitle,
  withClose,
  withFakeCart,
  withCart,
  schoolCode,
  children,
  confirm = true,
}) => {
  return (
    <div className="px-3 pt-4 pb-2 md:pt-8 md:py-6 lg:py-8 md:px-5 lg:px-10 md:shadow-lg">
      <div className="flex justify-center md:justify-start">
        <div>
          <div className="inline-block space-y-4 md:hidden">
            <div className="flex flex-col items-center space-y-2">
              <ConfirmLogo confirm={confirm} size="base" />
              {subTitle ? (
                <p className="text-sm font-normal leading-6 text-gunmetal-black-500">
                  {subTitle}
                </p>
              ) : (
                ""
              )}
            </div>
            <div>{children}</div>
          </div>
          <div className="hidden md:inline-block ">
            <ConfirmLogo confirm={confirm} size="lg" />
          </div>
        </div>
        <div className="hidden md:space-y-8 md:flex md:flex-col md:justify-center md:flex-grow">
          <div className="flex flex-col items-center space-y-2">
            <h3 className="text-2xl font-medium leading-6 text-celadon-green-dark-500">
              {title}
            </h3>
            {subTitle ? (
              <p className="font-normal leading-6 truncate text-gunmetal-black-500">
                {subTitle}
              </p>
            ) : (
              ""
            )}
          </div>
          <div>{children}</div>
        </div>
        {withClose || withFakeCart || withCart ? (
          <>
            {withClose ? (
              <div className="hidden md:w-48 md:flex md:justify-end">
                <Link
                  to="/"
                  className="relative inline-flex items-center text-sm font-medium text-cal-primary-celadon-green-008E8D hover:text-cal-primary-celadon-green-01A7A3 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8 lg:w-12 lg:h-12"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </Link>
              </div>
            ) : (
              ""
            )}
            {withFakeCart ? <FakeCart schoolCode={schoolCode} /> : ""}
            {withCart ? <Cart schoolCode={schoolCode} /> : ""}
          </>
        ) : (
          <div className="hidden md:w-48 md:flex"></div>
        )}
      </div>
    </div>
  );
};

Header.protoTypes = {
  title: PropTypes.string,
  schoolCode: PropTypes.string,
  subTitle: PropTypes.string,
  withClose: PropTypes.bool,
  withCart: PropTypes.bool,
  withFakeCart: PropTypes.bool,
};
export default Header;
