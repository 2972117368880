import React from "react";
import useGlobal from "../../../state/useGlobal";
import { useTranslation } from "react-i18next";
import { PRODUCT_TYPE_CONFIGURABLE } from "../../../constants/common";
import ConfProduct from "../../../components/DetailListView/ConfProduct";
import SimpleProduct from "../../../components/DetailListView/SimpleProduct";

export default function DetailListView({ item }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { checkout } = globalState;
  const { student } = checkout;
  const { t } = useTranslation();
  return (
    <>
      <div className="max-w-4xl px-4 py-4 mx-auto sm:px-6 lg:px-8">
        <div className="px-6 pt-4 pb-3 lg:px-8">
          <div className="flex flex-col items-center space-y-8 ">
            <h3 className="text-xl font-medium tracking-wide text-center text-gunmetal-black-500">
              {t(
                "Great! Now add the products {{first_name}} needs for the lesson.",
                {
                  first_name: student.first_name,
                }
              )}
            </h3>
          </div>
        </div>
      </div>
      <div className="max-w-2xl px-4 py-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-y-8">
          {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
            <ConfProduct item={item} />
          ) : (
            ""
          )}
          {item?.product_related &&
            item.product_related.map((productRelated) =>
              productRelated.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                <ConfProduct
                  key={productRelated.product_id}
                  item={productRelated}
                />
              ) : (
                <SimpleProduct
                  key={productRelated.product_id}
                  item={productRelated}
                />
              )
            )}
        </div>
      </div>
    </>
  );
}
