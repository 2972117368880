import PayPalLogo from "../components/PayPalLogo";
import SofortLogo from "../components/SofortLogo";
import MultibancoLogo from "../components/MultibancoLogo";
import CardLogo from "../components/CardLogo";
import MBWayLogo from "../components/MBWayLogo";
import EpsLogo from "../components/EpsLogo";
import IDealLogo from "../components/iDealLogo";

export const PAYMENT_METHOD_ICON = {
    paypal: <PayPalLogo className="h-5 "/>,
    paypal_express: <PayPalLogo className="h-5 "/>,
    stripe_payments: <CardLogo className="h-6 "/>,
    iways_paypalplus_payment: <PayPalLogo className="h-5 "/>,
    stripe_payments_sofort: <SofortLogo className=" h-9"/>,
    adyen_multibanco: <MultibancoLogo className=" h-9"/>,
    adyen_directEbanking: <SofortLogo className=" h-9"/>,
    adyen_cc: <CardLogo className="h-6 "/>,
    adyen_klarna_account: <SofortLogo className=" h-9"/>,
    adyen_klarna: <SofortLogo className=" h-9"/>,
    adyen_mbway: <MBWayLogo className=" h-9"/>,
    adyen_eps: <EpsLogo className=" h-8"/>,
    adyen_ideal: <IDealLogo className=" h-9"/>,
};
