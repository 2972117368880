import React, { useState } from "react";
import api from "../../services/api";
import * as Sentry from "@sentry/react";
import toast from "react-hot-toast";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
// import { TrashIcon } from "@heroicons/react/outline";
import useGlobal from "../../state/useGlobal";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  IGNORE_OPTION_WITH_LABEL,
  LIMIT_CART_CALCULATOR,
} from "../../constants/common";
import BigNumber from "bignumber.js";
import config from "../../config";
const Image = ({ src, alt }) => {
  const [errored, setErrored] = useState(false);

  if (errored) {
    return (
      <svg
        className="w-full h-full text-gray-300 bg-white border border-gray-200 rounded-md"
        preserveAspectRatio="none"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 200 200"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeWidth="1"
          d="M0 0l200 200M0 200L200 0"
        ></path>
      </svg>
    );
  } else {
    return (
      <img
        className="flex-shrink-0 object-contain w-full h-full mx-auto"
        src={`${config.cdn}${config.baseUrlMedia}${src}`}
        alt={alt}
        onError={setErrored}
      />
    );
  }
};

export default function CartItem({ product }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingRemoveAll, setLoadingRemoveAll] = useState(false);
  const { groupOrder } = globalState;
  const { calculator } = groupOrder;

  const handleOnRemove = async (item) => {
    setLoadingRemove(true);
    const { checkout } = globalState;
    const { quote_id } = checkout;
    const { item_id, qty } = item;
    if (qty === 1) {
      try {
        await api.del(`/guest-carts/${quote_id}/items/${item_id}`);

        const data = await globalActions.loadCart();
        globalActions.setCartDataModal(data);
        setLoadingRemove(false);
      } catch (error) {
        toast.error(
          t(
            "Etwas ist schief gelaufen. Bitte wenden Sie sich an den technischen Support."
          )
        );
        Sentry.setUser(globalState);
        Sentry.captureException(error);
        setLoadingRemove(false);
      }
    } else {
      try {
        const payloadPost = {
          cartItem: {
            item_id,
            qty: qty - 1,
            quote_id,
          },
        };

        await api.put(
          `/guest-carts/${quote_id}/items/${item_id}`,
          {},
          payloadPost
        );

        const data = await globalActions.loadCart();
        globalActions.setCartDataModal(data);
        setLoadingRemove(false);
      } catch (error) {
        toast.error(
          t(
            "Etwas ist schief gelaufen. Bitte wenden Sie sich an den technischen Support."
          )
        );
        Sentry.setUser(globalState);
        Sentry.captureException(error);
        setLoadingRemove(false);
      }
    }
  };

  // const handleOnRemoveAllItem = async (item) => {
  //   setLoadingRemoveAll(true);
  //   const { checkout } = globalState;
  //   const { quote_id } = checkout;
  //   const { item_id } = item;
  //   try {
  //     await api.del(`/guest-carts/${quote_id}/items/${item_id}`);

  //     const data = await globalActions.loadCart();
  //     globalActions.setCartDataModal(data);
  //     setLoadingRemoveAll(false);
  //   } catch (error) {
  //     toast.error(
  //       t(
  //         "Etwas ist schief gelaufen. Bitte wenden Sie sich an den technischen Support."
  //       )
  //     );
  //     Sentry.setUser(globalState);
  //     Sentry.captureException(error);
  //     setLoadingRemoveAll(false);
  //   }
  // };

  const handleOnAdd = async (item) => {
    // LIMIT_CART_CALCULATOR
    if (item?.extension_attributes?.product_id === calculator?.product_id) {
      if (item?.qty < LIMIT_CART_CALCULATOR) {
        setLoadingAdd(true);
        const { checkout } = globalState;
        const { quote_id } = checkout;
        const { item_id, qty } = item;
        try {
          const payloadPost = {
            cartItem: {
              item_id,
              qty: qty + 1,
              quote_id,
            },
          };

          await api.put(
            `/guest-carts/${quote_id}/items/${item_id}`,
            {},
            payloadPost
          );

          const data = await globalActions.loadCart();
          globalActions.setCartDataModal(data);
          setLoadingAdd(false);
        } catch (error) {
          toast.error(
            t(
              "Etwas ist schief gelaufen. Bitte wenden Sie sich an den technischen Support."
            )
          );
          Sentry.setUser(globalState);
          Sentry.captureException(error);
          setLoadingAdd(false);
        }
      }
    } else {
      setLoadingAdd(true);
      const { checkout } = globalState;
      const { quote_id } = checkout;
      const { item_id, qty } = item;
      try {
        const payloadPost = {
          cartItem: {
            item_id,
            qty: qty + 1,
            quote_id,
          },
        };

        await api.put(
          `/guest-carts/${quote_id}/items/${item_id}`,
          {},
          payloadPost
        );

        const data = await globalActions.loadCart();
        globalActions.setCartDataModal(data);
        setLoadingAdd(false);
      } catch (error) {
        toast.error(
            t(
            "Something went wrong"
          )
        );
        Sentry.setUser(globalState);
        Sentry.captureException(error);
        setLoadingAdd(false);
      }
    }
  };

  return (
    <li className="flex py-6">
      <div className="flex-shrink-0 w-24 h-24 overflow-hidden">
        <Image
          src={product.extension_attributes.product_image}
          alt={product.name}
        />
      </div>

      <div className="flex flex-col flex-1 ml-4 space-y-4">
        <div>
          <h3 className="text-base font-medium text-gray-900 overflow-ellipsis">
            {product.name}
          </h3>
          {product?.options &&
            JSON.parse(product.options)
              .filter((x) => !IGNORE_OPTION_WITH_LABEL.includes(x.label))
              .map((option) => (
                <p
                  key={option.label}
                  className="mt-1 text-xs text-gunmetal-black-500"
                >
                  <span className="uppercase ">{t(option.label)}:</span>{" "}
                  <span className="font-medium">{option.value}</span>
                </p>
              ))}
        </div>
        <div className="flex items-end justify-between flex-1 text-sm">
          <div className="flex items-end space-x-4 ">
            <span className="relative z-0 inline-flex rounded-lg shadow-sm">
              <button
                type="button"
                onClick={() => handleOnRemove(product)}
                disabled={loadingRemove}
                className={classNames({
                  "relative inline-flex items-center px-3 py-2 text-sm font-medium border-2 rounded-l-lg border-celadon-green-500 text-celadon-green-500 hover:text-celadon-green-dark-500 focus:z-10 focus:outline-none ": true,
                  "disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed": loadingRemove,
                })}
              >
                {loadingRemove ? (
                  <svg
                    className="w-3 h-3 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <MinusIcon className="w-3 h-3" />
                )}
              </button>
              <button
                type="button"
                className="relative inline-flex items-center px-3 py-2 -ml-px text-xs font-medium border-t-2 border-b-2 border-1 border-celadon-green-500 text-gunmetal-black-500 focus:z-10 focus:outline-none "
              >
                {product.qty}
              </button>
              <button
                type="button"
                onClick={() => handleOnAdd(product)}
                disabled={loadingAdd || (product?.extension_attributes?.product_id === calculator?.product_id && product?.qty >= LIMIT_CART_CALCULATOR)}
                className={classNames({
                  "relative inline-flex disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed items-center px-3 py-2 -ml-px text-sm font-medium border-2 rounded-r-lg border-celadon-green-500 text-celadon-green-500  hover:text-celadon-green-dark-500 focus:z-10 focus:outline-none ": true,
                })}
              >
                {loadingAdd ? (
                  <svg
                    className="w-3 h-3 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <PlusIcon className="w-3 h-3" />
                )}
              </button>
            </span>
            <p className="text-base text-gray-900">
              x {new BigNumber(product.price_incl_tax).toFormat(2).toString()} €
            </p>
            {/* <button
              type="button"
              className="inline-flex items-center p-1 text-fiery-rose-600 hover:text-fiery-rose-700"
              onClick={() => handleOnRemoveAllItem(product)}
              disabled={loadingRemoveAll}
            >
              {loadingRemoveAll ? (
                <svg
                  className="w-6 h-6 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <TrashIcon className="w-6 h-6" />
              )}
            </button> */}
          </div>

          <p className="text-base font-medium text-gray-900">
            {new BigNumber(product.row_total_incl_tax).toFormat(2).toString()} €
          </p>
        </div>
      </div>
    </li>
  );
}
