export const initialState = {
    open: false,
    action: false,
};

export const openConfirmModalRemoveItemCart = (store, callback) => {
    store.setState({
        confirmRemoveItemModal: {
            open: true,
            action: callback,
        },
    });
};

export const closeConfirmModalRemoveItemCart = (store) => {
    store.setState({confirmRemoveItemModal: initialState});
};
