// const prod = {
//   apiURL: "https://www.calcuso.de",
//   stores: {
//     1: "https://www.calcuso.de",
//     4: "https://www.calcuso.com/fr",
//     2: "https://www.calcuso.com/pt",
//   },
// };

const prod = {
  adyenURL: "https://checkoutshopper-live.adyen.com",
  apiURL: "https://www.calcuso.de",
  sentryURl: "https://2638712ea8ee4e2f9332339bf93ec18b@o94011.ingest.sentry.io/6152264",
  aiURL: "https://www.calcuso.de/schulsachen-kaufen-mit-kuenstlicher-intelligenz",
  cdn: "https://image.calcuso.com/webp/",
  baseUrlMedia: "https://www.calcuso.de/media/catalog/product",
  gt4: "G-RLPYVEMLP4",
  mixpanel: "ef71b2c87c1a825ad32caa88cf307fed",
  stores: {
    1: "https://www.calcuso.de",
    4: "https://www.calcuso.com/fr",
    2: "https://www.calcuso.com/pt",
    3: "https://www.calcuso.com/it",
    6: "https://www.calcuso.com/nl",
  },
  defaultCountry: {
    1: "DE",
    4: "FR",
    2: "PT",
    6: "NL",
    3: "IT"
  },
  paypalMode: "live",
  links: {
    // DE
    1: {
      LEGAL_NOTICE: "https://www.calcuso.de/legal-notice",
      PRIVACY: "https://www.calcuso.de/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.de/terms",
      CANCEL_POLICY: "https://www.calcuso.de/terms",
    },
    // FR
    4: {
      LEGAL_NOTICE: "https://www.calcuso.com/fr/legal-notice",
      PRIVACY: "https://www.calcuso.com/fr/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.com/fr/terms",
      CANCEL_POLICY: "https://www.calcuso.com/fr/terms",
    },
    // PT
    2: {
      LEGAL_NOTICE: "https://www.calcuso.com/pt/legal-notice",
      PRIVACY: "https://www.calcuso.com/pt/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.com/pt/terms",
      CANCEL_POLICY: "https://www.calcuso.com/pt/terms",
    },
    // IT
    3: {
      LEGAL_NOTICE: "https://www.calcuso.com/it/legal-notice",
      PRIVACY: "https://www.calcuso.com/it/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.com/it/terms",
      CANCEL_POLICY: "https://www.calcuso.com/it/terms",
    },
    6: {
      LEGAL_NOTICE: "https://www.calcuso.com/nl/legal-notice",
      PRIVACY: "https://www.calcuso.com/nl/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.com/nl/terms",
      CANCEL_POLICY: "https://www.calcuso.com/nl/terms",
    },
  },
};

const dev = {
  adyenURL: "https://checkoutshopper-test.adyen.com",
  apiURL: "https://staging.calcuso.com",
  sentryURl: "https://8f06769b6d074cf89a6e62189b021834@o94011.ingest.sentry.io/4504806368673792",
  aiURL: "https://www.calcuso.de/schulsachen-kaufen-mit-kuenstlicher-intelligenz",
  gt4: "G-RLPYVEMLP4444",
  mixpanel: "ddd51c74d89ce432e2b11c48a5ebb587",
  cdn: "",
  baseUrlMedia: "https://staging.calcuso.com/media/catalog/product",
  stores: {
    1: "https://staging.calcuso.com",
    4: "https://staging.calcuso.com/fr",
    2: "https://staging.calcuso.com/pt",
    3: "https://staging.calcuso.com/it",
    6: "https://staging.calcuso.com/nl",
  },
  defaultCountry: {
    1: "DE",
    4: "FR",
    2: "PT",
    6: "NL",
    3: "IT",
  },
  paypalMode: "sandbox",
  links: {
    // DE
    1: {
      LEGAL_NOTICE: "https://www.calcuso.de/impressum/",
      PRIVACY: "https://www.calcuso.de/datenschutz",
      TERMS_OF_USE: "https://www.calcuso.de/agb",
      CANCEL_POLICY: "https://www.calcuso.de/widerruf",
    },
    // FR
    4: {
      LEGAL_NOTICE: "https://www.calcuso.com/fr/calcuso-mentions-legales",
      PRIVACY: "https://www.calcuso.com/fr/calcuso-protection-donnees",
      TERMS_OF_USE: "https://www.calcuso.com/fr/calcuso-cgv",
      CANCEL_POLICY: "https://www.calcuso.com/fr/retour-and-remboursement",
    },
    // PT
    2: {
      LEGAL_NOTICE: "https://www.calcuso.com/pt/aviso-legal",
      PRIVACY: "https://www.calcuso.com/pt/protecao-dados",
      TERMS_OF_USE: "https://www.calcuso.com/pt/tcg",
      CANCEL_POLICY: "https://www.calcuso.com/pt/revogacao",
    },
    // IT
    3: {
      LEGAL_NOTICE: "https://www.calcuso.com/it/chi-siamo",
      PRIVACY: "https://www.calcuso.com/it/informativa-sulla-privacy",
      TERMS_OF_USE: "https://www.calcuso.com/it/condizioni-generali-di-vendita",
      CANCEL_POLICY: "https://www.calcuso.com/it/diritto-di-recesso",
    },
    6: {
      LEGAL_NOTICE: "https://www.calcuso.com/nl/chi-siamo",
      PRIVACY: "https://www.calcuso.com/nl/informativa-sulla-privacy",
      TERMS_OF_USE: "https://www.calcuso.com/nl/condizioni-generali-di-vendita",
      CANCEL_POLICY: "https://www.calcuso.com/nl/diritto-di-recesso",
    },
  },
};

export default process.env.REACT_APP_STAGE === "prod" ? prod : dev;
