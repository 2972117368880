import React from "react";
import Button from "../../components/Button";
import {ArrowRightIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useGlobal from "../../state/useGlobal";

export default function PreviewScreenStepNormal({schoolCode, handleCheckout, isCheckoutLoading}) {
    const {t} = useTranslation();
    const [globalState] = useGlobal();
    const {groupOrder} = globalState;
    const {calculator} = groupOrder;

    if (calculator?.related_package === undefined || calculator?.related_package?.filter((item) => item.version === 'package').length === 0) {
        return (
            <div className="flex items-center justify-end space-x-4">
                <Link
                    to={`/group-orders/${schoolCode}/products`}
                    className="font-medium cursor-pointer text-gunmetal-black-500"
                >
                    {t("back")}
                </Link>
                <Button
                    id="preview-screen-next-button"
                    type="button"
                    onClick={handleCheckout}
                    color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                    size="xl"
                    loading={isCheckoutLoading}
                >
                    <span>{t("checkout")}</span>
                    <ArrowRightIcon
                        className="w-5 h-5 ml-3 -mr-1 "
                        aria-hidden="true"
                    />
                </Button>
            </div>
        );
    }

    return null;

}
