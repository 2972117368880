import BigNumber from "bignumber.js";
import {IS_GRAVIUR, IS_NOT_GRAVIUR, PRODUCT_TYPE_CONFIGURABLE} from "../constants/common";

const state = {
    items: {},
    relatedProductAddMore: {},
    fulfillProductDiscountIds: [],
    discount: 0,
    relatedPackageSelected: null,
    previousItemRelated: {},
    cartHasItemRelatedChange: null,
    total: 0,
    totalWithDiscount: 0,
    userHasSelectRelatedPackage: null,
};

export const initialState = localStorage.getItem("fakeCart")
    ? JSON.parse(localStorage.getItem("fakeCart"))
    : state;

export const setFakeCart = (store, fakeCart) => {
    store.setState(
        {
            fakeCart: {
                ...store.state.fakeCart,
                ...fakeCart,
            },
        },
        () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
    );
};

export const initFakeCart = (store) => {
    store.setState(
        {
            fakeCart: {
                items: {},
                relatedProductAddMore: {},
                fulfillProductDiscountIds: [],
                discount: 0,
                relatedPackageSelected: null,
                previousItemRelated: {},
                cartHasItemRelatedChange: null,
                total: 0,
                totalWithDiscount: 0,
                userHasSelectRelatedPackage: null,
            },
        },
        () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
    );
};

function calculatePriceTier(qty, tier_prices) {
    let selectedTier;
    for (let index = 0; index < tier_prices.length; index++) {
        const tier_price = tier_prices[index];
        if (qty >= Math.floor(tier_price.price_qty)) {
            selectedTier = tier_price;
        }
    }
    return selectedTier;
}

const getFinalPrice = (item, values, qty) => {
    const product = item.product_simples.find((product_simple) => {
        let find = true;
        for (
            let index = 0;
            index < product_simple.product_custom_attributes.length;
            index++
        ) {
            const element = product_simple.product_custom_attributes[index];
            if (element.value !== values[element.attribute_code]) {
                return false;
            }
        }

        return find;
    });

    if (!product) {
        return false;
    }

    // Handle Tier Prices
    const tierPrice = calculatePriceTier(qty, product.tier_prices);
    if (tierPrice) {
        if (values.is_graviur === IS_GRAVIUR) {
            // Gravur
            const gravurOption = item.product_custom_options.find(
                (custom_option) => custom_option.type === "field"
            );
            const price = new BigNumber(tierPrice.price).plus(
                new BigNumber(gravurOption.price)
            );
            return price.toFormat(2).toString();
        }

        const price = new BigNumber(tierPrice.price);
        return price.toFormat(2).toString();
    }

    if (values.is_graviur === IS_GRAVIUR) {
        // Gravur
        const gravurOption = item.product_custom_options.find(
            (custom_option) => custom_option.type === "field"
        );
        const price = new BigNumber(product.product_price).plus(
            new BigNumber(gravurOption.price)
        );
        return price.toFormat(2).toString();
    }

    const price = new BigNumber(product.product_price);
    return price.toFormat(2).toString();
};


export const addToCart = (store, item) => {
    const {fakeCart} = store.state;
    const {groupOrder} = store.state;
    const {items} = fakeCart;

    if (items[item.id]) {
        items[item.id].qty = items[item.id].qty + 1;
        if (items[item.id].type === PRODUCT_TYPE_CONFIGURABLE) {
            const finalPrice = getFinalPrice(
                item.product,
                items[item.id].options,
                items[item.id].qty
            );
            if (finalPrice) {
                items[item.id].price = finalPrice;
            }
        } else {
            // Handle Tier Price
            const tierPrice = calculatePriceTier(
                items[item.id].qty,
                item.product.tier_prices
            );
            if (tierPrice) {
                items[item.id].price = new BigNumber(tierPrice.price).toFormat(2).toString();
            } else {
                items[item.id].price = new BigNumber(
                    item.product.product_price
                ).toFormat(2).toString();
            }
        }
    } else {
        items[item.id] = {...item};
    }

    // Set main product to first item in cart
    if (groupOrder?.calculator?.product_id && groupOrder?.calculator?.product_id === item?.id) {
        items[groupOrder.calculator.product_id].created_at = new Date().getTime() - 99999999;
    }

    store.setState(
        {
            fakeCart: {
                ...store.state.fakeCart,
                items,
                previousItemRelated: item.id === store.state?.groupOrder?.calculator?.product_id ? store.state.fakeCart.previousItemRelated : item,
            },
        },
        () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
    );
    autoSelectPackage(store);
    fulfillPackageDiscount(store);

    calculateTotal(store);
};

export const addOptionsFromCart = (store, itemId, price, selectedOptions) => {
    const {fakeCart} = store.state;
    const {items} = fakeCart;

    if (items[itemId]) {
        if (items[itemId].type === PRODUCT_TYPE_CONFIGURABLE) {
            const {options} = items[itemId];
            items[itemId].options = {
                ...options,
                ...selectedOptions,
            };
            const finalPrice = getFinalPrice(
                items[itemId].product,
                items[itemId].options,
                items[itemId].qty
            );
            if (finalPrice) {
                items[itemId].price = finalPrice;
            } else {
                items[itemId].price = price;
            }
        }
    }
    fulfillPackageDiscount(store);
    calculateTotal(store);
    store.setState(
        {
            fakeCart: {
                ...store.state.fakeCart,
                items,
            },
        },
        () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
    );

};

export const changeOptionsFromCart = (store, itemId, selectedOptions) => {
    const {fakeCart} = store.state;
    const {items} = fakeCart;

    if (items[itemId]) {
        if (items[itemId].type === PRODUCT_TYPE_CONFIGURABLE) {
            const {options} = items[itemId];
            items[itemId].options = {
                ...options,
                ...selectedOptions,
            };
        }
    }

    store.setState(
        {
            fakeCart: {
                ...store.state.fakeCart,
                items,
            },
        },
        () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
    );
};

export const minusFromCart = (store, item, confirm = false) => {
    // Check item in package selected show pop up to confirm remove item
    const {fakeCart, confirmRemoveItemModal} = store.state;
    const {items} = fakeCart;

    if (confirm === false &&
        fakeCart?.relatedPackageSelected &&
        fakeCart?.fulfillProductDiscountIds?.includes(parseInt(item.id)) &&
        fakeCart?.discount > 0 &&
        item.qty === 1
    ) {
        store.setState(
            {
                confirmRemoveItemModal: {
                    ...confirmRemoveItemModal,
                    open: true,
                    action: () => minusFromCart(store, item, true),
                },
            }
        );
        return;
    }
    if (items[item.id]) {
        if (items[item.id].qty <= 1) {
            delete items[item.id];
            // If user remove item and item is in package selected then remove package selected
            autoUnSelectPackageAfterDeleteItem(store, item);
        } else {
            // Keep previous items in cart before user select related package
            // For user select/Unselect related package
            store.setState(
                {
                    fakeCart: {
                        ...store.state.fakeCart,
                        previousItemRelated: item.id === store.state?.groupOrder?.calculator?.product_id ? store.state.fakeCart.previousItemRelated : item,
                    }
                }
            );
            items[item.id].qty = items[item.id].qty - 1;
            if (items[item.id].type === PRODUCT_TYPE_CONFIGURABLE) {
                const finalPrice = getFinalPrice(
                    item.product,
                    items[item.id].options,
                    items[item.id].qty
                );
                if (finalPrice) {
                    items[item.id].price = finalPrice;
                }
            } else {
                // Handle Tier Price
                const tierPrice = calculatePriceTier(
                    items[item.id].qty,
                    item.product.tier_prices
                );
                if (tierPrice) {
                    items[item.id].price = new BigNumber(tierPrice.price).toFormat(2).toString();
                } else {
                    items[item.id].price = new BigNumber(
                        item.product.product_price
                    ).toFormat(2).toString();
                }
            }
        }
    }

    store.setState(
        {
            fakeCart: {
                ...store.state.fakeCart,
                cartHasItemRelatedChange: (new Date()).toTimeString(),
                items,
            },
            confirmRemoveItemModal: {
                ...store.state.confirmRemoveItemModal,
                open: false
            }
        },
        () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
    );
    fulfillPackageDiscount(store);
    shouldDisableMinusFromCart(store);
    calculateTotal(store);
}

export const removeFromCart = (store, item) => {
    const {fakeCart} = store.state;
    const {items} = fakeCart;

    delete items[item.id];

    store.setState(
        {
            fakeCart: {
                ...store.state.fakeCart,
                items,
            },
        },
        () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
    );
};

export const addRelatedBundlePackage = (store, packageSelect) => {
    const {fakeCart, groupOrder} = store.state;
    const {items} = fakeCart;
    const cartItemIds = new Set(Object.values(items).map(item => parseInt(item.id)));

    const getProductRelatedIds = (relatedItems) => relatedItems.map(productRelated => parseInt(productRelated.product_id));
    const getFilteredItems = (relatedItems, relatedIds) => relatedItems?.filter(productRelated =>
        !cartItemIds.has(parseInt(productRelated.product_id)) && (!relatedIds || relatedIds.map(Number).includes(parseInt(productRelated.product_id)))
    );

    const fulfillProductDiscountIds = packageSelect?.related_ids.length === 0
        ? getProductRelatedIds(groupOrder.calculator.product_related)
        : packageSelect?.related_ids.map(id => parseInt(id));

    const addMore = packageSelect?.related_ids.length === 0
        ? getFilteredItems(groupOrder?.calculator?.product_related)
        : getFilteredItems(groupOrder?.calculator?.product_related, packageSelect?.related_ids);

    //add main product to fulfillProductDiscountIds
    if (!fulfillProductDiscountIds?.includes(parseInt(groupOrder.calculator.product_id))) {
        fulfillProductDiscountIds?.push(parseInt(groupOrder.calculator.product_id));
    }

    //add main product to addMore if it's not in cart
    if (!cartItemIds.has(parseInt(groupOrder.calculator.product_id))) {
        addMore.push(groupOrder.calculator);
    }

    store.setState({
        fakeCart: {
            ...fakeCart,
            relatedProductAddMore: addMore,
            relatedPackageSelected: packageSelect,
            fulfillProductDiscountIds,
        },
    }, () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart)));

    addMore.forEach(productRelated => {
        const options = productRelated.product_type === PRODUCT_TYPE_CONFIGURABLE
            ? productRelated.configurable_product_options.reduce((acc, option) => {
                const lowestPricePreselect = productRelated.product_simples.reduce((a, b) => a.product_price <= b.product_price ? a : b, {});
                const lowestAttribute = lowestPricePreselect.product_custom_attributes.find(attr => attr.attribute_code === option.attribute_code);
                return {...acc, [option.attribute_code]: lowestAttribute?.value};
            }, {})
            : {};

        addToCart(store, {
            id: productRelated.product_id,
            name: productRelated.name,
            sku: productRelated.product_sku,
            type: productRelated.product_type,
            price: productRelated.product_price,
            qty: 1,
            product: productRelated,
            options: {
                ...options,
                gravur: "",
                is_graviur: IS_NOT_GRAVIUR,
            },
            created_at: new Date().getTime(),
        });
    });

    fulfillPackageDiscount(store);
    shouldDisableMinusFromCart(store);
};

export const removeRelatedBundlePackage = (store, packageSelected, userSelect = true) => {
    const {fakeCart} = store.state;
    if (packageSelected) {
        const {items} = fakeCart;
        // Keep previous items in cart before user select related package
        const {relatedProductAddMore} = fakeCart;

        if (userSelect) {
            relatedProductAddMore.forEach((productRelated) => {
                delete items[productRelated.product_id];
            });
            if (relatedProductAddMore.length === 0) {
                delete items[fakeCart.previousItemRelated.id];
            }
        } else {
            if (relatedProductAddMore.length === 0) {
                delete items[fakeCart.previousItemRelated.id];
            }
        }

        store.setState(
            {
                fakeCart: {
                    ...store.state.fakeCart,
                    items,
                    relatedProductAddMore: [],
                    fulfillProductDiscountIds: [],
                    relatedPackageSelected: null,
                    discount: 0,
                },
            },
            () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
        );
    }
    calculateTotal(store);
    shouldDisableMinusFromCart(store);
};

export const fulfillPackageDiscount = (store) => {
    const {fakeCart, groupOrder} = store.state;
    const {items, relatedPackageSelected} = fakeCart;
    if (!relatedPackageSelected?.percentage_discount) return;

    const cartItems = Object.values(items);
    const cartItemIds = new Set(cartItems.map(item => parseInt(item.id)));

    const getRelatedProductIds = () => {
        return relatedPackageSelected.related_ids.length === 0
            ? groupOrder.calculator.product_related.map(productRelated => parseInt(productRelated.product_id))
            : relatedPackageSelected.related_ids.map(id => parseInt(id));
    };

    const relatedProductIds = getRelatedProductIds();

    const isMatched = relatedProductIds.every(relatedProductId => cartItemIds.has(relatedProductId));

    let discount = 0;

    if (isMatched) {
        const calculateDiscount = (relatedIds) => {
            return relatedIds.reduce((total, relatedId) => {
                const cartItem = items[relatedId];
                return total + (cartItem.price * cartItem.qty * (relatedPackageSelected.percentage_discount / 100));
            }, 0);
        };

        discount = relatedPackageSelected.related_ids.length === 0
            ? calculateDiscount(relatedProductIds)
            : calculateDiscount(groupOrder.calculator.product_related
                .filter(productRelated => cartItemIds.has(parseInt(productRelated.product_id)) && relatedPackageSelected.related_ids.includes(parseInt(productRelated.product_id)))
                .map(productRelated => parseInt(productRelated.product_id))
            );
    }
    discount = new BigNumber(discount).toFormat(2);

    store.setState({
        fakeCart: {
            ...fakeCart,
            discount: discount,
        },
    }, () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart)));
};

export const shouldDisableMinusFromCart = (store) => {
    return false;
    // const {fakeCart} = store.state;
    // Object.values(fakeCart.items).forEach(item => {
    //     item.canMinus = fakeCart.relatedPackageSelected &&
    //         fakeCart.fulfillProductDiscountIds.includes(parseInt(item.id)) &&
    //         fakeCart.discount > 0 &&
    //         item.qty === 1;
    // });
    // store.setState({
    //     fakeCart: {
    //         ...fakeCart,
    //         items: {
    //             ...fakeCart.items,
    //         },
    //     },
    // });
}

export const autoSelectPackage = (store) => {
    const {fakeCart, groupOrder} = store.state;
    const {items, relatedPackageSelected} = fakeCart;
    const cartItemIds = new Set(Object.values(items).map(item => parseInt(item.id)));

    const relatedProductIds = groupOrder?.calculator?.product_related.map(productRelated => parseInt(productRelated.product_id)) ?? [];
    const isMatched = relatedProductIds.every(relatedProductId => cartItemIds.has(relatedProductId));
    const findRelatedPackedAll = groupOrder?.calculator?.related_package?.filter(relatedPackage => relatedPackage.package_type === 'all') ?? [];

    if (relatedPackageSelected === null && isMatched && findRelatedPackedAll && findRelatedPackedAll.length > 0) {
        addRelatedBundlePackage(store, findRelatedPackedAll[0]);
    }
}

export const autoUnSelectPackageAfterDeleteItem = (store, item) => {
    const {fakeCart} = store.state;
    const {fulfillProductDiscountIds, relatedPackageSelected} = fakeCart;
    if (relatedPackageSelected &&
        fulfillProductDiscountIds.includes(parseInt(item.id))
    ) {
        removeRelatedBundlePackage(store, relatedPackageSelected, false);
    }
}
export const calculateTotal = (store) => {
    const {fakeCart} = store.state;
    const {items} = fakeCart;
    const total = Object.values(items).reduce((total, item) => {
        return total + new BigNumber(item.price) * item.qty;
    }, 0);
    const totalWithDiscount = new BigNumber(total - (fakeCart?.discount ?? 0));

    store.setState({
        fakeCart: {
            ...fakeCart,
            total: total,
            totalWithDiscount: totalWithDiscount.toFormat(2),
        },
    }, () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart)));
}

export const getSortCartItems = (store) => {
    const {fakeCart} = store.state;
    const {items} = fakeCart;
    return Object.keys(items)
        .map((key) => {
            return {
                ...items[key],
            };
        })
        .sort(function (x, y) {
            return x.created_at - y.created_at;
        })
}
export const getSortCartItemsInPackageOnly = (store) => {
    return getSortCartItems(store).filter(item => store.state.fakeCart.fulfillProductDiscountIds.includes(parseInt(item.id)));
}

export const getSortCartItemsNotInPackageOnly = (store) => {
    return getSortCartItems(store).filter(item => !store.state.fakeCart.fulfillProductDiscountIds.includes(parseInt(item.id)));
}

export const removeRelatedPackageSelected = (store) => {
    const {fakeCart} = store.state;
    store.setState(
        {
            fakeCart: {
                ...fakeCart,
                relatedProductAddMore: [],
                fulfillProductDiscountIds: [],
                relatedPackageSelected: null,
                discount: 0,
            },
        },
        () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
    );

}

export const removeAllRelatedProductIncartBaseOnProductSelected = (store, selectPackage) => {
    const {fakeCart, groupOrder} = store.state;
    const {items} = fakeCart;

    const relatedProductIds = groupOrder?.calculator?.product_related.map(productRelated => parseInt(productRelated.product_id)) ?? [];
    relatedProductIds.forEach(relatedProductId => {
        if (items[relatedProductId] && selectPackage && selectPackage?.related_ids && selectPackage?.related_ids.map(Number).includes(parseInt(relatedProductId))) {
            // Remove item in cart in related package selected. Keep item in cart not in related package selected
            delete items[relatedProductId];
        }
    });

    // Set main product qty to 1
    if (items[groupOrder.calculator.product_id]) {
        items[groupOrder.calculator.product_id].qty = 1;
    }

    store.setState({
        fakeCart: {
            ...fakeCart,
            items,
            relatedProductAddMore: [],
            fulfillProductDiscountIds: [],
            relatedPackageSelected: null,
            discount: 0,
        },
    }, () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart)));
}

export const setUserHasSelectRelatedPackage = (store, value = null) => {
    store.setState({
        fakeCart: {
            ...store.state.fakeCart,
            userHasSelectRelatedPackage: value,
        }
    }, () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart)));
}
