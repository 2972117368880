import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGlobal from "../../../../state/useGlobal";
import * as Yup from "yup";
import {
  IS_GRAVIUR,
  IS_NOT_GRAVIUR,
  REGEX_GRAVIUR,
} from "../../../../constants/common";
import BigNumber from "bignumber.js";
import * as Sentry from "@sentry/react";
import InputGravur from "./InputGravur";

export default function Gravur({ option, formik, item }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { fakeCart } = globalState;
  // get From Cart
  const { items } = fakeCart;
  const selectedItem = items[item.product_id];
  const { product_simples } = item;
  const { price } = option;
  const { t } = useTranslation();
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
  } = formik;

  const getFinalPrice = () => {
    const product = product_simples.find((product_simple) => {
      let find = true;
      for (
        let index = 0;
        index < product_simple.product_custom_attributes.length;
        index++
      ) {
        const element = product_simple.product_custom_attributes[index];
        if (element.value !== values[element.attribute_code]) {
          return false;
        }
      }

      return find;
    });

    if (!product) {
      Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
      Sentry.setUser({ item, globalState });
      Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
      return false;
    }

    if (values.is_graviur === IS_GRAVIUR) {
      // Gravur
      const gravurOption = item.product_custom_options.find(
        (custom_option) => custom_option.type === "field"
      );
      const price = new BigNumber(product.product_price).plus(
        new BigNumber(gravurOption.price)
      );
      return price.toFormat(2).toString();
    }

    const price = new BigNumber(product.product_price);
    return price.toFormat(2).toString();
  };

  useEffect(() => {
    async function processingData() {
      // Set To Cart If Exists
      if (selectedItem) {
        const price = getFinalPrice();
        const isValid = await Yup.string()
          .max(22)
          .matches(REGEX_GRAVIUR)
          .isValid(values.gravur);
        if (isValid) {
          globalActions.addOptionsFromCart(selectedItem?.id, price, {
            gravur: values.gravur,
            is_graviur: values.is_graviur,
          });
        } else {
          if (!values.gravur) {
            globalActions.addOptionsFromCart(selectedItem?.id, price, {
              gravur: values.gravur,
              is_graviur: values.is_graviur,
            });
          }
        }
      }
    }
    processingData();
  }, [values.gravur, values.is_graviur]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col justify-center pt-4 space-y-2 text-center lg:text-left lg:pt-0">
      <h4 className="text-sm font-medium text-gunmetal-black-500">
        {t("Laser engraving")} + {new BigNumber(price).toFormat(2).toString()} €
      </h4>
      <p className="text-xs font-normal text-gunmetal-black-500">
        {t("Personalise your item. Protects against theft and confusion!")}
      </p>
      <InputGravur
        name="gravur"
        placeholder={t("Enter desired text")}
        size="sm"
        value={values["gravur"]}
        onChange={handleChange}
        onBlur={async (e) => {
          handleBlur(e);
          const { value } = e.target;
          const isValid = await Yup.string()
            .max(22)
            .matches(REGEX_GRAVIUR)
            .isValid(value);

          if (isValid) {
            setFieldValue("is_graviur", IS_GRAVIUR);
          } else {
            setFieldValue("is_graviur", IS_NOT_GRAVIUR);
          }
          globalActions.setGravurTextGravurModal(value);
        }}
        error={touched.gravur && errors.gravur}
        errorMessage={errors.gravur}
      />
    </div>
  );
}
