import React from "react";
import { useTranslation } from "react-i18next";
import { ATTRIBUTE_CODE_COLOR } from "../../../../constants/common";

export default function Color({ value, item }) {
  const { t } = useTranslation();
  const { product } = item;

  const option = product?.configurable_product_options.find(
    (option) =>
      option.attribute_type === "visual" &&
      option.attribute_code === ATTRIBUTE_CODE_COLOR
  );
  const color = option?.attribute_options.find(
    (color) => color.value_index === value
  );

  return (
    <p className="mt-1 text-xs text-gunmetal-black-500">
      <span className="uppercase ">{t("Color")}:</span>{" "}
      <span className="font-medium uppercase">{color?.label}</span>
    </p>
  );
}
