import React from "react";
import { Redirect, Route } from "react-router-dom";
import useGlobal from "../state/useGlobal";

export default function PrivateRoute({ children, ...rest }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { checkout } = globalState;
  const { student } = checkout;
  const { first_name, last_name, class_name } = student;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const isAuthenticated = first_name && last_name && class_name;

        
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
