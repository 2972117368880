import React, {useEffect, useMemo} from "react";
import {RadioGroup} from "@headlessui/react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../state/useGlobal";
import BigNumber from "bignumber.js";
import {IS_GRAVIUR} from "../../../../constants/common";
import * as Sentry from "@sentry/react";

export default function PackageColor({option, formik, item}) {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {fakeCart} = globalState;
    const {items} = fakeCart;
    const {product_simples} = item;
    const selectedItem = items[item.product_id];
    const {attribute_options, attribute_code} = option;
    const {t} = useTranslation();
    const {values, setFieldValue} = formik;

    const getFinalPrice = () => {
        const product = product_simples.find((product_simple) => {
            let find = true;
            for (
                let index = 0;
                index < product_simple.product_custom_attributes.length;
                index++
            ) {
                const element = product_simple.product_custom_attributes[index];
                if (element.value !== values[element.attribute_code]) {
                    return false;
                }
            }

            return find;
        });

        if (!product) {
            Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
            Sentry.setUser({item, globalState});
            Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
            return false;
        }

        if (values.is_graviur === IS_GRAVIUR) {
            // Gravur
            const gravurOption = item.product_custom_options.find(
                (custom_option) => custom_option.type === "field"
            );
            const price = new BigNumber(product.product_price).plus(
                new BigNumber(gravurOption.price)
            );
            return price.toFormat(2).toString();
        }

        const price = new BigNumber(product.product_price);
        return price.toFormat(2).toString();
    };

    useEffect(() => {
        // Set To Cart If Exists
        if (selectedItem) {
            const price = getFinalPrice();
            globalActions.addOptionsFromCart(selectedItem?.id, price, {
                [attribute_code]: values[attribute_code],
            });
        }
    }, [values[attribute_code]]); // eslint-disable-line react-hooks/exhaustive-deps

        const estimatedPrices = useMemo(() => {
        const prices = product_simples.reduce((acc, product_simple) => {
            product_simple.product_custom_attributes.forEach(({value}) => {
                const price = new BigNumber(product_simple.product_price);
                acc[value] = price.toFormat(2).toString();
            });
            return acc;
        }, []);

        // Find cheapest price in prices array
        const cheapestPrice = Math.min(...Object.values(prices).map((price) => parseFloat(price)));

        // Get the price of the selected attribute, which will be the reference for calculating differences alway base on cheast price
        return prices.map((price, index) => {
            const currentPrice = new BigNumber(price);
            const value = currentPrice.minus(cheapestPrice).toFormat(2).toString();
            if (value > 0) {
                return "+" + value;
            }
            return 0;
        });

    }, [product_simples, values[attribute_code]]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex flex-col items-center justify-center space-y-4">
            <RadioGroup
                value={values[attribute_code]}
                onChange={(selectedColor) => {
                    // Set Formik data
                    setFieldValue(attribute_code, selectedColor);
                }}
                className="w-full"
            >
                <div className="flex items-center justify-center w-full space-x-4 md:space-x-6 md:flex-wrap-reverse">
                    {attribute_options.map((color) => (
                        <RadioGroup.Option
                            key={color.value_index}
                            // onClick={() => {globalActions.goToNextStepConfigurable(globalActions.getSortCartItemsInPackageOnly(), item)}}
                            value={color.value_index}
                            style={{backgroundColor: color.color_hex}}
                            className={({active, checked}) =>
                                classNames({
                                    " ring-celadon-green-dark ring-2 ring-offset-4":
                                        active && checked,
                                    "ring-2  ring-offset-4 ring-celadon-green-dark ":
                                        !active && checked,
                                    "-m-0.5 relative p-1 my-2 rounded-full flex items-center justify-center cursor-pointer focus:outline-none": true,
                                })
                            }
                        >
                            <RadioGroup.Label as="p" className="sr-only">
                                {color.name}
                            </RadioGroup.Label>
                            <span
                                aria-hidden="true"
                                className={classNames({
                                    [`${color.class}`]: color.class,
                                    "h-5 w-5 rounded-full": true,
                                })}
                            />
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
            <div className="text-left bg-gray-100 w-full items-center justify-center py-4 px-5 text-sm font-semibold sm:flex-1 cursor-pointer rounded-2xl"
                 onClick={() => {globalActions.goToNextStepConfigurable(globalActions.getSortCartItemsInPackageOnly(), item)}}
            >
                <div className="flex justify-between items-center">
                    <span>{t("Color")} : {attribute_options.reduce((acc, curr) => { return curr.value_index === values[attribute_code] ? curr.label : acc }, "")}</span>
                    <span>{estimatedPrices[values[attribute_code]] ? estimatedPrices[values[attribute_code]] + globalState.groupOrder.currency_symbol : t('free')}</span>
                </div>
            </div>
        </div>
    );
}
