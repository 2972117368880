import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import ListProductSelection from "./ListProductSelection";
import {connect} from "../../state/useGlobal";
import {Redirect, withRouter} from "react-router";
import {Helmet} from "react-helmet";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import Loading from "../../components/Loadingv2";
import ProductModal from "../../components/ProductModal";
import FakeCartModal from "../../components/FakeCartModal";
import CartSteps from "../../components/CartSteps";
import {Link} from "react-router-dom";
import {STEP_SELECT_PRODUCT} from "../../constants/cartSteps";
import history from "../../routes/history";
import mixpanel from 'mixpanel-browser';

class SelectProductionScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      schoolCode: id,
    };
  }

  componentDidMount() {
    const { globalActions, ga4 } = this.props;
    globalActions.setCheckout({
      currentStep: STEP_SELECT_PRODUCT,
    });
    this._loadGroupOrder();

    try {
      ga4.pageview('/calcuso-group-order-select-product', '/calcuso-group-order-select-product', 'Group Order | Select products');
      const {schoolCode} = this.state;
      mixpanel.track_pageview({
        "page": "Group Order | Select products",
        "school_code": schoolCode,
      })
    } catch (e) {
      console.log(e);
    }
  }

  _loadGroupOrder = async () => {
    const { globalActions, globalState } = this.props;
    const { schoolCode } = this.state;

    // Verify School Code
    const { groupOrder } = globalState;
    const { school_code } = groupOrder;
    if (school_code && school_code !== schoolCode) {
      history.push(`/group-orders/${schoolCode.trim()}`);
    }

    await globalActions.loadGroupOrder(schoolCode);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { t, globalState } = this.props;
    const { isLoading, schoolCode } = this.state;
    const { groupOrder } = globalState;
    const { status, items } = groupOrder;

    return (
      <AppLayout
        contentBackgroundColor="bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6"
        textColor="text-gunmetal-black"
        border={false}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {status === STATUS_NOT_FOUND ? (
              <Redirect to="/" />
            ) : (
              <>
                <Helmet>
                  <title>
                    {schoolCode} | {groupOrder?.school?.name} |{" "}
                    {t("Group Order")}
                  </title>
                </Helmet>
                <div className="flex flex-col h-full min-h-screen">
                  <Header
                    title={t("Group Order")}
                    subTitle={groupOrder?.school?.name}
                    withFakeCart
                  >
                    <CartSteps />
                  </Header>
                  <div className="flex flex-col justify-between flex-1 h-full">
                    <div className="flex flex-col items-center flex-1">
                      <div className="max-w-2xl px-4 py-4 mx-auto sm:px-6 lg:px-8">
                        <div className="px-6 pt-4 pb-3 lg:px-8">
                          <div className="flex flex-col items-center space-y-8 ">
                            <h3 className="text-xl font-medium tracking-wide text-center text-gunmetal-black-500">
                              {t(
                                "Your school has provided several calculators to choose from. Please choose one."
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="w-full px-4 py-8 md:max-w-4xl sm:px-6 lg:px-8">
                        <ListProductSelection items={items} />
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-full px-4 py-8 md:w-full lg:w-1/2 ">
                        <div className="flex flex-col-reverse items-center justify-between w-full space-y-4 space-y-reverse md:space-y-0 md:space-x-6 md:flex-row md:justify-center">
                          <Link
                            to={`/group-orders/${schoolCode.trim()}/student-information`}
                            className="font-medium text-gunmetal-black-500 hover:text-gunmetal-black-700"
                          >
                            <span>{t("back")}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <ProductModal />
        <FakeCartModal />
      </AppLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(SelectProductionScreen)));
