export const hasPaymentFee = (configs, paymentCode) => {
    return findPaymentFee(configs, paymentCode) !== undefined;
}

export const calculatePaymentFee = (configs, paymentCode, total) => {
    let paymentFeeData = findPaymentFee(configs, paymentCode);

    if (total && paymentFeeData) {
        return calculatePercentage(total, paymentFeeData.value_percent, paymentFeeData.value_fixed);
    }

    return 0;
}

const findPaymentFee = (configs, paymentCode) => {
    let paymentFees = configs?.payment_fees || [];
    let paymentFeeData = paymentFees.find((paymentFee) => paymentFee.payment_method === paymentCode);

    return paymentFeeData && (paymentFeeData.value_percent || paymentFeeData.value_fixed) ? paymentFeeData : undefined;
}

const calculatePercentage = (total, percent, fixed) => {
    let result = total * (percent / 100) + parseFloat(fixed);

    // Round down to 2 decimal places
    return Math.floor(result * 100) / 100;
}
