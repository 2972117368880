import {elementInViewport} from "../services/helper";

export const initialState = {
    totalStep: [],
    currentStep: null,
    canSeeLastItem: false,
    seeRelated : false,
};

export const resetConfigurableStep = (store, configurableStep) => {
    store.setState({
        configurableStep: initialState
    });
}
export const goToNextStepConfigurable = (store, cartItems, item = null) => {
    const {configurableStep} = store.state;

    if (item === null) {
        item = configurableStep.currentStep ? configurableStep.currentStep.product : cartItems[0];
    }

    const index = cartItems.findIndex(cartItem => cartItem.id === item.product_id);
    const nextItem = cartItems[index + 1];
    const latestItem = cartItems[cartItems.length - 1];

    const canSeeLastItem = latestItem && elementInViewport(document.getElementById(`config-${latestItem.id}`));
    store.setState({
        configurableStep: {
            ...configurableStep,
            canSeeLastItem: canSeeLastItem,
        }
    });

    if (nextItem) {
        const nextItemElement = document.getElementById(`config-${nextItem.id}`);
        if (nextItemElement) {
            store.setState({
                configurableStep: {
                    ...configurableStep,
                    currentStep: nextItem,
                }
            });
            window.scrollTo({
                top: nextItemElement.offsetTop + 120,
                behavior: 'smooth'
            });
        }
    } else {
        // No next item in package list and use select only calculate
        const nextItemElement = document.getElementById(`config-relateds`);
        if (nextItemElement && configurableStep.seeRelated === false) {
            store.setState({
                configurableStep: {
                    ...configurableStep,
                    seeRelated: true,
                }
            })
            window.scrollTo({
                top: nextItemElement.offsetTop + 120,
                behavior: 'smooth'
            });
        } else {
            store.setState({
                configurableStep: {
                    ...configurableStep,
                    canSeeLastItem: true,
                }
            });
            // Scroll to bottom
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }
    }
};
