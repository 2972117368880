import React from "react";
import {ArrowRightIcon} from "@heroicons/react/outline";
import Button from "../../components/Button";
import {useTranslation} from "react-i18next";
import useGlobal from "../../state/useGlobal";
import history from "../../routes/history";
import FakeCart from "../../components/FakeCart";

export default function NavigationTabs({tabs, currentTab, isAllProductInViewport}) {
    const {t} = useTranslation();
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {groupOrder, fakeCart} = globalState;
    const {items, school_code, calculator} = groupOrder;

    const scrollDown = (ref) => {
        try {
            window.scrollTo({
                top: ref.current.offsetTop - 70,
                behavior: "smooth",
            });
        } catch (e) {
            // Catch error and move to preview page
            history.push(`/group-orders/${school_code.trim()}/preview`);
        }
    };

    const handleBack = () => {
        const index = tabs.findIndex((tab) => tab.key === currentTab);
        if (tabs[index - 1]) {
            scrollDown(tabs[index - 1].refSection);
        } else {
            const countCalculator = items.filter((item) => item.is_calculator).length;

            switch (countCalculator) {
                case 0:
                    history.push(
                        `/group-orders/${school_code.trim()}/student-information`
                    );
                    break;
                case 1:
                    const calculator = items.find((item) => item.is_calculator);
                    globalActions.setGroupOrder({
                        calculator,
                    });
                    history.push(
                        `/group-orders/${school_code.trim()}/student-information`
                    );
                    break;

                default:
                    history.push(`/group-orders/${school_code.trim()}/select-calculator`);
                    break;
            }
        }
    };

    const handleNext = async () => {
        const index = tabs.findIndex((tab) => tab.key === currentTab);

        if (tabs[index + 1] && !isAllProductInViewport) {
            scrollDown(tabs[index + 1].refSection);
        } else {
            if (calculator?.related_package?.filter((item) => item.version === 'package').length) {
                history.push(`/group-orders/${school_code.trim()}/configurable`);
            } else {
                history.push(`/group-orders/${school_code.trim()}/preview`);
            }
        }
    };

    return (
        <div className="fixed inset-x-0 bottom-0">
            <div className="h-24 border-t-2 bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6 border-floral-white-800">
                <div className="max-w-2xl px-4 py-4 mx-auto lg:max-w-7xl md:px-5 md:py-6 lg:px-8 lg:py-8">
                    <div className="flex items-center justify-between space-x-4">

                        <FakeCart schoolCode={school_code} showMobile/>

                        <div className="flex items-center justify-end space-x-4">
                            <p
                                onClick={handleBack}
                                className="font-medium cursor-pointer text-gunmetal-black-500"
                            >
                                {t("back")}
                            </p>
                            <Button
                                id="add-product-button-scroll"
                                type="button"
                                color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                size="xl"
                                onClick={handleNext}
                            >
                                <span>{t("next")}</span>
                                <ArrowRightIcon
                                    className="w-5 h-5 ml-3 -mr-1 "
                                    aria-hidden="true"
                                />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
