import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

import "../../../tailwind.output.css";

const Input = ({
  name,
  label,
  placeholder,
  error,
  errorMessage,
  required,
  size,
  alignCenter,
  ...props
}) => {
  return (
    <div className="w-full ">
      {label ? (
        <div className="flex items-center justify-between">
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gunmetal-black"
          >
            {label}
          </label>
          {required ? <span className="text-xl text-red-500 ">*</span> : <span className="text-xl ">&nbsp;</span>}
        </div>
      ) : (
        ""
      )}
      <div
        className={classNames({
          "mt-1": true,
          "relative rounded-md shadow-sm": error,
        })}
      >
        <input
          type="text"
          className={classNames({
            "focus:ring-celadon-green-500 focus:border-celadon-green-500 border-gray-300": !error,
            "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": error,
            "shadow-sm block w-full border-2 rounded-md": true,
            "sm:text-base": !size,
            "sm:text-lg": size === "lg",
            "sm:text-xl": size === "xl",
            "sm:text-sm": size === "sm",
            "text-center": alignCenter
          })}
          name={name}
          placeholder={placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          required={props.required}
          autoComplete={props.autoComplete}
        />
        {error ? (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon
              className="w-5 h-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {error ? (
        <p className="mt-2 text-sm text-fiery-rose-500 ">{errorMessage}</p>
      ) : (
        ""
      )}
    </div>
  );
};

Input.protoTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  alignCenter: PropTypes.bool,
};
export default Input;
