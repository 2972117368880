import * as React from "react";
import VISA_LOGO from "../images/visa.png";
import MASTER_LOGO from "../images/mastercard.png";
import AMEX_LOGO from "../images/amex.png";

function CardLogo(props) {
  return (
    <div className="flex space-x-2 ">
      <img src={VISA_LOGO} alt="Visa" {...props} />
      <img src={MASTER_LOGO} alt="Master" {...props} />
      <img src={AMEX_LOGO} alt="Amex" {...props} />
    </div>
  );
}

export default CardLogo;
