export const STEP_WELCOME = "STEP_WELCOME";
export const STEP_STUDENT_NAME = "STEP_STUDENT_NAME";
export const STEP_SELECT_PRODUCT = "STEP_SELECT_PRODUCT";
export const STEP_UP_SELL_PRODUCT = "STEP_UP_SELL_PRODUCT";
export const STEP_PREVIEW = "STEP_PREVIEW";

export const CART_STEPS = [
  STEP_WELCOME,
  STEP_STUDENT_NAME,
  STEP_SELECT_PRODUCT,
  STEP_UP_SELL_PRODUCT,
  STEP_PREVIEW,
];

export const CART_STEPS_LABEL = [
  (STEP_WELCOME) => STEP_WELCOME,
  (STEP_STUDENT_NAME) => STEP_STUDENT_NAME,
  (STEP_SELECT_PRODUCT) => STEP_SELECT_PRODUCT,
  (STEP_UP_SELL_PRODUCT) => STEP_UP_SELL_PRODUCT,
  (STEP_PREVIEW) => STEP_PREVIEW,
];
