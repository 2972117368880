import React from "react";
import ConfProduct from "../../../components/DetailListView/ConfProduct";
import SimpleProduct from "../../../components/DetailListView/SimpleProduct";
import { PRODUCT_TYPE_CONFIGURABLE } from "../../../constants/common";

export default function StationerySection({ groupOrder, name, refSection}) {
  const { items } = groupOrder;
  const stationeryItems = items.filter(
    (item) => !item.is_school_book && !item.is_calculator
  );

  if (stationeryItems.length > 0)
    return (
      <div className="stationery-section" ref={refSection}>
        <h3 className="pt-6 text-lg font-medium md:text-xl text-gunmetal-black-500">{name}</h3>
        <div className="grid grid-cols-1 py-4 gap-y-4 md:gap-y-6 lg:gap-y-8">
          {stationeryItems.map((productStationery) =>
            productStationery.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
              <ConfProduct
                key={productStationery.product_id}
                item={productStationery}
              />
            ) : (
              <SimpleProduct
                key={productStationery.product_id}
                item={productStationery}
              />
            )
          )}
        </div>
      </div>
    );

  return "";
}
