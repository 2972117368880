import React, {useState} from "react";
import useGlobal from "../../state/useGlobal";
import {useTranslation} from "react-i18next";
import config from "../../config";

import {CheckCircleIcon} from "@heroicons/react/solid";
const Image = ({src, alt}) => {
    const [errored, setErrored] = useState(false);
    if (errored) {
        return (
            <svg
                className="w-full h-full text-gray-300 bg-white border border-gray-200 rounded-md"
                preserveAspectRatio="none"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 200 200"
                aria-hidden="true"
            >
                <path
                    vectorEffect="non-scaling-stroke"
                    strokeWidth="1"
                    d="M0 0l200 200M0 200L200 0"
                ></path>
            </svg>
        );
    } else {
        return (
            <img
                className="flex-shrink-0 object-contain w-full h-full mx-auto"
                src={`${config.cdn}${config.baseUrlMedia}${src}`}
                alt={alt}
                onError={setErrored}
            />
        );
    }
};
export default function PackageFakeCartItemSimple({item}) {
    /* eslint-disable no-unused-vars */
    const [globalState] = useGlobal();
    const {t} = useTranslation();
    const {product} = item;
    return (
        <li className="flex py-6 text-xs">
            <div className="flex-shrink-0 w-16 overflow-hidden md:w-12 md:h-12">
                <Image src={product.product_image} alt={product.name} key={product.product_image}/>
            </div>
            <div className="flex flex-col flex-1 ml-4 space-y-4 ">
                <div>
                    <h3 className="font-medium text-gray-900 overflow-ellipsis">
                        {product.name}
                    </h3>
                </div>
            </div>
            <div className="text-xs"><CheckCircleIcon className="w-6 h-6 text-celadon-green-900 inline "/></div>
        </li>
    );
}
