import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../tailwind.output.css";

const buttonClasses = (size, color, font, fontSize, disabled, loading, full) =>
  classNames({
    "px-2.5 py-1.5 rounded": size === "xs",
    "px-3 py-2 leading-4 rounded-md": size === "sm",
    "px-4 py-2 rounded-md": size === "lg" || !size || size === "base",
    "px-6 py-2 rounded-lg": size === "xl",
    "text-xs": size === "xs" && !fontSize,
    "text-sm":
      ((!size || size === "base") && !fontSize) || (size === "sm" && !fontSize),
    "text-base": size === "lg" && !fontSize,
    "text-lg": size === "xl" && !fontSize,
    [`${fontSize}`]: fontSize,
    "bg-teal-600 hover:bg-teal-700 focus:ring-teal-500": !color,
    [`${color}`]: color,
    "font-medium": !font,
    [`${font}`]: font,
    "disabled:opacity-50 cursor-not-allowed": disabled || loading,
    "w-full justify-center": full,
    "inline-flex items-center border border-transparent shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2": true,
  });

const Button = ({
  size,
  color,
  font,
  children,
  full,
  type = "button",
  disabled = false,
  loading = false,
  fontSize,
  ...props
}) => {
  const { t } = useTranslation();
  return (() => {
    switch (type) {
      case "link":
        return (
          <Link
            to={props.href}
            className={buttonClasses(
              size,
              color,
              font,
              fontSize,
              disabled,
              loading,
              full
            )}
            disabled={disabled}
          >
            {children}
          </Link>
        );
      case "a":
        return (
          <a
            href={props.href}
            className={buttonClasses(
              size,
              color,
              font,
              fontSize,
              disabled,
              loading,
              full
            )}
          >
            {children}
          </a>
        );
      default:
        return (
          <button
            id={props.id ? props.id : ""}
            type={type}
            className={buttonClasses(
              size,
              color,
              font,
              fontSize,
              disabled,
              loading,
              full
            )}
            disabled={disabled || loading}
            onClick={props.onClick}
          >
            {loading ? (
              <>
                <svg
                  className="w-5 h-5 text-white md:mr-3 md:-ml-1 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <span className="hidden truncate md:inline-block md:text-base">
                  {t("in progress")}
                </span>
              </>
            ) : (
              children
            )}
          </button>
        );
    }
  })();
};

Button.protoTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  font: PropTypes.string,
  fontSize: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  full: PropTypes.bool,
};
export default Button;
