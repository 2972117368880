import React from "react";
import useGlobal from "../../../state/useGlobal";
import {useTranslation} from "react-i18next";
import ProductImage from "../../ProductImage";
import {CheckCircleIcon} from "@heroicons/react/solid";

export default function SimpleProductConfigurable({item}) {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const {product_tags} = item;
    const {fakeCart} = globalState;
    const {items} = fakeCart;
    const selectedItem = items[item.product_id];

    const handleAddToCart = () => {
        const payload = {
            id: item.product_id,
            name: item.name,
            sku: item.product_sku,
            type: item.product_type,
            price: item.product_price,
            qty: 1,
            product: item,
            options: {},
            created_at: new Date().getTime(),
        };
        globalActions.addToCart(payload);
    };

    const handleOnRemove = (removeItem) => {
        globalActions.minusFromCart(removeItem)
    };

    const handleAdd = (addItem) => {
        globalActions.addToCart(addItem);
    };

    return (
        <div id={'config-' + item.product_id}>

            <div className="w-full p-4 bg-white rounded-lg relative">
                <div className="flex gap-2 items-center mb-3">
                    <h3 className="text-lg font-bold md:text-xl text-gunmetal-black-500 truncate">
                        <CheckCircleIcon className="w-6 h-6 text-celadon-green-900 inline mr-2"/>{item.name}
                    </h3>
                </div>
                <div className="grid grid-cols-3 gap-4 lg:grid-cols-6">
                    <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-2 md:flex-row">
                        <div
                            className="flex items-center flex-1 cursor-pointer"
                            onClick={() => globalActions.openProductModal(item)}
                        >
                            <ProductImage
                                src={item.product_image}
                                alt={item.name}
                                size="h-24 w-24 md:h-28 md:w-28 lg:h-32 lg:w-32"
                            />
                        </div>
                    </div>
                    <div className="flex-1 col-span-2 lg:col-span-4 lg:space-y-4">
                        <div className="flex flex-col items-start space-y-2">
                            <h3 className="text-lg font-medium md:text-xl text-gunmetal-black-500">
                                {t("Included in the package")}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
