import React from "react";
import LOADING from "../images/loading.svg";
export default function Loadingv2() {
  return (
    <div className="w-full">
      <div className="grid place-items-center h-screen">
        <img src={LOADING} alt="Calcuso" />
      </div>
    </div>
  );
}
