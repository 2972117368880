import * as React from "react";

const StarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      style={{
        fill: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      d="m55.089 619.563 5.43 16.898h17.57l-14.21 10.29 5.429 16.82-14.219-10.485-14.21 10.422 5.429-16.75-14.219-10.297h17.57Zm0 0"
      transform="matrix(.5 0 0 .5 -15.544 -308.781)"
    />
  </svg>
);

export default StarIcon;
