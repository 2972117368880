import * as React from "react";

function EpsLogo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 26"   {...props}>
            <defs>
                <mask id="a" width="6.44" height="10.01" x="17.73" y="10.57" maskUnits="userSpaceOnUse">
                    <path fill="#fff" d="M17.73 20.58h6.44v-10h-6.44v10z"/>
                </mask>
            </defs>
            <path fill="#c81b71" d="M0 0h40v26H0z"/>
            <g mask="url(#a)">
                <path fill="#fff"
                      d="M19.33 17.1v3.43a11.39 11.39 0 0 1-1.5.05.74.74 0 0 1-.1-.53v-6.2a3.22 3.22 0 1 1 6.44-.07 3.24 3.24 0 0 1-3.25 3.31h-1.59"/>
            </g>
            <path fill="#fff"
                  d="M23.1 17.08a3.14 3.14 0 0 0 1.3-1.4c.11-.24.3-.23.5-.23h3.66a3.53 3.53 0 0 0 .4 0c.25-.04.43-.17.43-.43a.39.39 0 0 0-.45-.39h-1.98a2.05 2.05 0 0 1-2.05-1.65 1.98 1.98 0 0 1 1.56-2.36 29.8 29.8 0 0 1 3.61-.04 8.09 8.09 0 0 1 0 1.55h-2.84c-.34 0-.72 0-.73.43-.02.46.37.45.7.45h1.83a2.03 2.03 0 0 1 .18 4.06c-2 .06-3.99.01-6.11.01m-6.21-1.64c-1.53 0-2.98.03-4.44 0a1.46 1.46 0 0 1-1.33-.86h4.66a3.3 3.3 0 0 0-2.58-3.98 3.26 3.26 0 1 0-.74 6.48c1.26.04 2.54 0 3.8.01h.56a1.1 1.1 0 0 1-1.11 1.02q-3.1.02-6.2 0a1.21 1.21 0 0 1-1.2-1.21q-.03-3.08 0-6.15a1.18 1.18 0 0 1 1.25-1.2q3.05-.02 6.1 0a1.16 1.16 0 0 1 1.22 1.12c.03 1.57 0 3.14 0 4.77"/>
            <path fill="#fff"
                  d="M14.12 13h-3a1.59 1.59 0 0 1 1.17-.88 1.71 1.71 0 0 1 1.83.87m1.02-4.49h-.71a.3.3 0 0 1-.3-.3v-.6a1.56 1.56 0 0 0-1.46-1.57 1.53 1.53 0 0 0-1.6 1.52v.64a.3.3 0 0 1-.3.3h-.72a.3.3 0 0 1-.3-.3V7.6a2.89 2.89 0 0 1 2.76-2.9 2.85 2.85 0 0 1 2.93 2.84v.65a.3.3 0 0 1-.3.3"/>
            <path fill="#c81b71"
                  d="M19.35 15.45c0-.63-.02-1.2 0-1.75a1.58 1.58 0 0 1 1.54-1.53 1.61 1.61 0 0 1 1.65 1.36 1.58 1.58 0 0 1-1.2 1.85 9.44 9.44 0 0 1-1.99.07"/>
        </svg>
    );
}

export default EpsLogo;
