import BigNumber from "bignumber.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IS_GRAVIUR } from "../../../../constants/common";
import useGlobal from "../../../../state/useGlobal";
import Select from "../../../Forms/Select/Select";
import * as Sentry from "@sentry/react";

export default function SelectOption({
  option,
  formik,
  item,
  disabled = false,
}) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { fakeCart } = globalState;
  const { attribute_code, attribute_options, label } = option;
  const { t } = useTranslation();
  const { values, handleChange } = formik;
  const { product_simples } = item;

  // get From Cart
  const { items } = fakeCart;
  const selectedItem = items[item.product_id];

  const getFinalPrice = () => {
    const product = product_simples.find((product_simple) => {
      let find = true;
      for (
        let index = 0;
        index < product_simple.product_custom_attributes.length;
        index++
      ) {
        const element = product_simple.product_custom_attributes[index];
        if (element.value !== values[element.attribute_code]) {
          return false;
        }
      }

      return find;
    });

    if (!product) {
      Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
      Sentry.setUser({ item, globalState });
      Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
      return false;
    }

    if (values.is_graviur === IS_GRAVIUR) {
      // Gravur
      const gravurOption = item.product_custom_options.find(
        (custom_option) => custom_option.type === "field"
      );
      const price = new BigNumber(product.product_price).plus(
        new BigNumber(gravurOption.price)
      );
      return price.toFormat(2).toString();
    }

    const price = new BigNumber(product.product_price);
    return price.toFormat(2).toString();
  };

  useEffect(() => {
    // Set To Cart If Exists
    if (selectedItem) {
      const price = getFinalPrice();
      globalActions.addOptionsFromCart(selectedItem?.id, price, {
        [attribute_code]: values[attribute_code],
      });
    }
  }, [values[attribute_code]]); // eslint-disable-line react-hooks/exhaustive-deps

  const options = attribute_options
    .map((attribute_code) => {
      return { key: attribute_code.value_index, value: attribute_code.label };
    })
    .sort((a, b) => a.key - b.key);

  return (
    <div className="w-full mt-4">
      <Select
        name={attribute_code}
        label={t(label)}
        options={options}
        onChange={handleChange}
        value={values[attribute_code]}
        required
        disabled={disabled}
      />
    </div>
  );
}
