import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import {Redirect, withRouter} from "react-router";
import {connect} from "../../state/useGlobal";
import Loading from "../../components/Loadingv2";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import {Helmet} from "react-helmet";
import CartModal from "../../components/CartModal";
import Footer from "../../components/Footer";
import CheckoutSteps from "../../components/CheckoutSteps";
import {STEP_CHECKOUT_DONE} from "../../constants/checkoutSteps";
import {CheckIcon, ClipboardIcon, XIcon} from "@heroicons/react/outline";
import Button from "../../components/Button";
import * as dayjs from "dayjs";
import {SHIPPING_METHOD_TO_SCHOOL} from "../../constants/shippingMethod";
import Config from "../../config";
import {RESULT_CODE} from "../../constants/adyenPayment";
import toast from "react-hot-toast";
import MULTIBANCO_LOGO from "../../images/multibanco.png";
import {Dialog, Transition} from "@headlessui/react";
import mixpanel from 'mixpanel-browser';

class DoneScreen extends Component {
    constructor(props) {
        super(props);
        const id = this.props.match.params.id;

        this.state = {
            schoolCode: id,
            isLoading: true,
            showPopup: false,
        };
    }

    componentDidMount() {
        const {globalActions, ga4, globalState} = this.props;
        globalActions.initCheckout();
        globalActions.initGroupOrder();
        globalActions.initFakeCart();
        globalActions.setCheckout({
            currentCheckoutStep: STEP_CHECKOUT_DONE,
        });
        this._loadGroupOrder();

        if (globalState.store === 1) { // Only display for DE
            setTimeout(() => {
                this.setState({
                    showPopup: true
                })
            }, 2500);
        }

        try {
            ga4.pageview('/calcuso-group-order-finish', '/calcuso-group-order-finish', 'Group Order | Payment done finish');
            const {schoolCode} = this.state;
            mixpanel.track_pageview({
                "page": "Group Order | Payment done finish",
                "school_code": schoolCode,
            })

        } catch (e) {
            console.log(e);
        }
    }

    _loadGroupOrder = async () => {
        const {globalActions} = this.props;
        const {schoolCode} = this.state;

        await globalActions.loadGroupOrder(schoolCode);
        this.setState({
            isLoading: false,
        });
    };

    copyCode = (code) => {
        const {t} = this.props;

        navigator.clipboard.writeText(code);
        toast.success(t("Copied to clip board"));
    }

    closePopup = () => {
        const {schoolCode} = this.state;
        mixpanel.track('[GO] Close Popup AI',  {"school_code": schoolCode});
        this.setState({
            showPopup: false
        })
    }

    openUrl() {
        this.setState({
            showPopup: false
        })
        const {schoolCode} = this.state;
        mixpanel.track('[GO] Open Popup AI', {"school_code": schoolCode});

        setTimeout(() => {
            window.open(Config.aiURL, '_blank');
        }, 100);
    }

    render() {
        const {t, globalState} = this.props;
        const {isLoading} = this.state;
        const {groupOrder, checkout, store} = globalState;
        const {status, start_ship_out} = groupOrder;
        const {shippingMethodCode} = checkout;
        const {multiBancoResponse} = globalState;

        return (
            <AppLayout
                contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
                textColor="text-gunmetal-black"
                border={false}
            >
                {isLoading ? (
                    <Loading/>
                ) : (
                    <>
                        {status === STATUS_NOT_FOUND ? (
                            <Redirect to="/"/>
                        ) : (
                            <>
                                <Helmet>
                                    <title>
                                        {t("Done")} | {t("Group Order")}
                                    </title>
                                </Helmet>
                                <div className="flex flex-col h-full min-h-screen">
                                    <Header title={t("Checkout")}>
                                        <CheckoutSteps/>
                                    </Header>

                                    <div className="flex flex-col justify-between flex-1 h-full">
                                        <div className="flex items-center justify-center flex-1">
                                            <div className="w-full px-4 lg:w-3/4 xl:w-1/2 sm:px-6 lg:px-8">
                                                <div className="px-6 pt-6 lg:px-8 lg:pt-12">
                                                    <div className="flex flex-col space-y-4 ">
                                                        <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full bg-celadon-green-dark-500">
                                                            <CheckIcon
                                                                className="w-6 h-6 text-white"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-5">
                                                            <h3 className="text-lg font-medium leading-6 text-gunmetal-black-500">
                                                                {t("Payment successful")}
                                                            </h3>
                                                            <div className="mt-2">
                                                                {shippingMethodCode ===
                                                                SHIPPING_METHOD_TO_SCHOOL ? (
                                                                    <p className="text-lg text-gunmetal-black-500">
                                                                        {t(
                                                                            "Thank you for your order. It is expected to leave our warehouse on {{start_ship_out}}.",
                                                                            {
                                                                                start_ship_out: dayjs(
                                                                                    start_ship_out
                                                                                ).format("DD.MM.YYYY"),
                                                                            }
                                                                        )}
                                                                    </p>
                                                                ) : (
                                                                    <p className="text-lg text-gunmetal-black-500">
                                                                        {t("Thank you for your order.")}
                                                                    </p>
                                                                )}

                                                            </div>
                                                            <div className="mt-2">
                                                                {multiBancoResponse?.response?.resultCode ===
                                                                    RESULT_CODE && (
                                                                        <div className="flex flex-col gap-y-1">
                                      <span className="font-medium text-gray-500">
                                            {t('Reference')}
                                      </span>
                                                                            <div className="flex justify-center mx-auto">
                                        <span className="font-bold text-2xl">
                                            {multiBancoResponse?.response?.action?.reference}
                                        </span>
                                                                                <ClipboardIcon
                                                                                    onClick={() => this.copyCode(multiBancoResponse?.response?.action?.reference)}
                                                                                    className="w-5 h-5 mt-0.5 text-celadon-green-dark-500 cursor-pointer"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </div>
                                                                            <span className="my-4 font-bold text-lg">
                                        {t('Information about the order')}:
                                      </span>
                                                                            <span>
                                          <span className="font-bold mr-1">
                                              {t('Entity')}:
                                          </span>
                                          <span>
                                            {multiBancoResponse?.response?.action?.entity}
                                          </span>
                                      </span>
                                                                            <span>
                                          <span className="font-bold mr-1">
                                              {t('Data Expiration')}:
                                          </span>
                                          <span>
                                            {
                                                dayjs(
                                                    multiBancoResponse?.response?.action?.expiresAt
                                                ).format("DD.MM.YYYY h:mm A")
                                            }
                                          </span>
                                      </span>
                                                                            <span>
                                          <span className="font-bold mr-1">
                                              {t('Amount/Value')}:
                                          </span>
                                          <span>
                                               {
                                                   Number(multiBancoResponse?.response?.action?.totalAmount.value / 100).toFixed(2) + ' ' + multiBancoResponse?.response?.action?.totalAmount.currency
                                               }
                                          </span>
                                      </span>
                                                                            <span className="font-bold"> {t('To pay a MultiBanco Reference at your e-bank or at an ATM')}</span>
                                                                            <span> {t('Select "payments and other services" and then "services/purchase payments".')}</span>
                                                                            <img className="w-20 mx-auto" src={MULTIBANCO_LOGO} alt="multibanco"/>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>

                                                        <div className="flex items-center justify-center pt-8 space-x-8">
                                                            <Button
                                                                type="a"
                                                                href={Config.stores[store]}
                                                                color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                                                size="xl"
                                                            >
                                                                <span>{t("back to the shop")}</span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <Footer/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                <CartModal/>

                <Transition show={this.state.showPopup}>
                    <Dialog className="relative z-50" onClose={() => console.log('close')}>
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                        <div className="absolute top-7 right-7 -mr-4 -mt-4 cursor-pointer" onClick={() => this.closePopup()}>
                                            <XIcon className="w-8 h-8 text-gray-300" aria-hidden="true"/>
                                        </div>
                                        <div>
                                            <div className="mt-3 sm:mt-5">
                                                <Dialog.Title as="h3" className="text-3xl font-semibold leading-6 text-celadon-green-dark-500 leading-10	">
                                                    {t('Are you still missing school supplies and school books?')}
                                                </Dialog.Title>
                                                <div className="mt-8 text-sm">
                                                    <div>
                                                        {t('Do you still have a letter to parents?')}
                                                    </div>
                                                    <div className="mt-4" dangerouslySetInnerHTML={{
                                                        __html: t('Order the rest of your school supplies directly to your home within 5 minutes.')
                                                    }}>
                                                    </div>
                                                    <div className="mt-4" dangerouslySetInnerHTML={{
                                                        __html: t('Simply upload the parent letter to our CALCUSO AI!')
                                                    }}>
                                                    </div>
                                                    <div className="mt-4" dangerouslySetInnerHTML={{
                                                        __html: t('Our artificial intelligence automatically recognizes all required items. ✨')
                                                    }}>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <div className="flex justify-center items-center space-x-2">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <div
                                                className={"cursor-pointer rounded-xl p-1 w-full mx-auto"}
                                                style={{
                                                    // 90 deg
                                                    background: 'linear-gradient(90deg, rgb(255 33 33) 0%, rgb(52 225 137) 100%)',
                                                }}>
                                                <button
                                                    onClick={() => this.openUrl()}
                                                    className="inline-flex w-full justify-center rounded-lg bg-black px-3 py-2 text-sm font-semibold text-white sm:col-start-1 sm:mt-0"
                                                >
                                                    {t('Take a photo of your parent letter now!')}
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </AppLayout>
        );
    }
}

export default withRouter(connect(withTranslation()(DoneScreen)));
